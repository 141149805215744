import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { Alert } from "@mui/material";
import AccountsReceivableSection from "../views/AccountsReceivable/AccountsReceivable";
import InvoiceSearch from "../views/AccountsReceivable/InvoiceSearch";

function AccountsReceivable(props) {
  return (
    <>
      {props.groups.includes(props.accountsReceivableGroup) ? (
        <Container className="">
          <Routes>
            <Route path={""} element={<AccountsReceivableSection />} />
            <Route
              path={"/:inv/:cust"}
              element={<AccountsReceivableSection />}
            />
            <Route path={"/invoice-search"} element={<InvoiceSearch />} />
          </Routes>
        </Container>
      ) : (
        <Container>
          {props.groups.length > 0 && (
            <span>
              <Alert severity="error" variant="outlined" className="mt-5">
                You are not allowed to view this page. If you believe you are
                seeing this in error, please contact a system administrator
              </Alert>
              <a href="/">Back to home</a>
            </span>
          )}
        </Container>
      )}
      <div style={{ float: "right", marginRight: "1%" }}>
        <b>v0.1.2</b>
      </div>
    </>
  );
}

export default AccountsReceivable;

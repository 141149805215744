import { memo } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ConfirmSendInvoicePopup.scss";

function ConfirmSendInvoicePopup({
  show,
  count,
  lowerDate,
  upperDate,
  customerNum,
  transNum,
  transType,
  deliveryMethod,
  lowerRange,
  upperRange,
  emailAddress,
  fax,
  faxRecipient,
  handleCancelEvent,
  handleConfirmEvent,
}) {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>Are you sure you want to continue?</div>
      </Modal.Header>
      <Modal.Body>
        <div>Number of Invoices: {count}</div>
        {customerNum.length >= 3 && <div>Customer Number: {customerNum}</div>}
        {lowerDate.length === 10 && upperDate.length === 10 && (
          <div>Date Range: {lowerDate + " to " + upperDate}</div>
        )}
        {transNum >= 4 && (
          <div>
            {transType} number: {transNum}
          </div>
        )}
        {lowerRange.length >= 4 && upperRange.length >= 4 && (
          <div>
            Invoice Range: {lowerRange} to {upperRange}
          </div>
        )}
        {deliveryMethod === "file" ? (
          <div>Download Zip File</div>
        ) : deliveryMethod === "print" ? (
          <div>Print to user printer</div>
        ) : (
          <div>Deliver by Email/Fax</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(ConfirmSendInvoicePopup);

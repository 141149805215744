import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DATE_ORDER_HOLD_STATUS,
  OPEN_ORDER_STATUS,
  ORDER_HOLD_STATUS,
  ORDER_STATUS,
  PENDING_ORDER_STATUS,
} from "../../../helpers/const";
import {
  setOrderHoldStatus,
  setOrderStatus,
} from "../../../store/slices/subOrderSlice";
import HoldDate from "../HoldDate/HoldDate";

const StatusSelector = () => {
  const {
    subOrder,
    subOrder: { status: orderStatus, hold_status: hold_status },
    subOrderId,
    subOrdersLocked,
  } = useSelector((state) => state.subOrder);

  const dispatch = useDispatch();

  const [statusLabel, setStatusLabel] = useState("");
  const [holdStatusLabel, setHoldStatusLabel] = useState("");

  useEffect(() => {
    var status = ORDER_STATUS.find((os) => os.id === orderStatus);

    setStatusLabel(status.label);
  }, [orderStatus]);

  useEffect(() => {
    var holdStatus = ORDER_HOLD_STATUS.find((os) => os.id === hold_status);

    setHoldStatusLabel(holdStatus.label);
  }, [hold_status]);
  return (
    <>
      <div className="subh" style={{ float: "right" }}>
        <div className="d-flex">
          <div className="wraps">
            <Dropdown>
              <Dropdown.Toggle
                disabled={subOrdersLocked}
                id={`drop-button-${statusLabel}`}
                className="drop-status-button"
              >
                <label>{statusLabel}</label>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {ORDER_STATUS.filter((s) => s.id !== OPEN_ORDER_STATUS.id).map(
                  (status, index) => (
                    <Dropdown.Item
                      key={status.id}
                      eventKey={index}
                      className="dropdown-draft"
                      onClick={() => dispatch(setOrderStatus(status.id))}
                      disabled={
                        subOrdersLocked ||
                        (status.id === OPEN_ORDER_STATUS.id && subOrderId)
                      }
                    >
                      {status.label}
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {orderStatus === PENDING_ORDER_STATUS.id && (
            <div className="ms-2">
              <Dropdown>
                <Dropdown.Toggle
                  id={`drop-button-${holdStatusLabel}`}
                  className="drop-status-button"
                >
                  <label style={{ minWidth: 100 }}>{holdStatusLabel}</label>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {ORDER_HOLD_STATUS.map((status, index) => (
                    <Dropdown.Item
                      key={status.id}
                      eventKey={index}
                      className="dropdown-draft"
                      onClick={() => dispatch(setOrderHoldStatus(status.id))}
                    >
                      {status.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        {subOrder.hold_status === DATE_ORDER_HOLD_STATUS.id &&
          subOrder.status === PENDING_ORDER_STATUS.id && (
            <div className="mt-5">
              <HoldDate holdDate={subOrder.hold_date} />
            </div>
          )}
      </div>
    </>
  );
};

export default StatusSelector;

import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  TextField,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formStyle } from "../../../theming/formStyle.js";
import useFocusEvent from "../../../hooks/useFocusEvent";
import { endpoints } from "../../../api/endpoints";
import {
  checkDuplicatedPO,
  setCheckDuplicatedDisabled,
  setOrderDetails,
  setSubOrder,
  setShippingRules,
} from "../../../store/slices/subOrderSlice";
import { setFieldRef, setSectionRef } from "../../../store/slices/refsSlice";
import {
  DETAILS_SECTION_REF,
  ITEMS_SECTION_REF,
  OPEN_ORDER_STATUS,
  PENDING_ORDER_STATUS,
  SUB_ORDER_PO,
} from "../../../helpers/const";
import Table from "../../atoms/Table/Table.js";
import InputNumber from "../../atoms/InputNumber/index.js";
import "./OrderDetails.scss";
import {
  setAutoSaveTriggerChanged,
  setAutoSaveFocusOuttedTrue,
} from "../../../store/slices/eventHandlerSlice.js";
import MostPopularItems from "./MostPopularItems.js";
import { loadPopularItems } from "../../../store/slices/customer/customerSlice.js";

const OrderDetails = () => {
  // Redux
  const dispatch = useDispatch();
  const { savedAsQuote } = useSelector((state) => state.header);
  const { subOrder, subOrdersLocked, subOrderId, checkDuplicatedDisabled } =
    useSelector((state) => state.subOrder);
  const [allPopular, setAllPopular] = useState([]);
  const [custPopular, setCustPopular] = useState([]);
  const [showCust, setShowCust] = useState(false);
  const {
    orderDetails: {
      orderedByHasError,
      customerPoHasError,
      customerPoIsRequired,
    },
  } = useSelector((state) => state.validations);

  const {
    autoSaveOrder: { triggerChanged },
  } = useSelector((state) => state.eventHandler);

  const { customerId } = useSelector((state) => state.customer);

  // Refs
  const poInputRef = useRef(null);
  const billOnlyInputRef = useRef(null);
  const orderedByInputRef = useRef(null);
  const orderCommentInputRef = useRef(null);
  const orderComment2InputRef = useRef(null);
  const specialInstrInputRef = useRef(null);

  // custom hooks
  const { onClickHandler } = useFocusEvent(
    DETAILS_SECTION_REF,
    SUB_ORDER_PO,
    poInputRef
  );

  const [openWarning, setOpenWarning] = useState(false);
  const [duplicatedSubOrders, setDuplicatedSubOrders] = useState([]);

  useEffect(() => {
    setShowCust(false);
    dispatch(loadPopularItems()).then((response) => {
      if (!response?.error) {
        if (customerId > 0) setCustPopular(response.payload);
        else setAllPopular(response.payload);
      }
    });
  }, [customerId]);

  useEffect(() => {
    let timeOutId;

    if (!checkDuplicatedDisabled) {
      if (
        (subOrder.status === OPEN_ORDER_STATUS.id ||
          subOrder.status === PENDING_ORDER_STATUS.id) &&
        subOrder.customer_id &&
        subOrder.customer_po
      ) {
        timeOutId = setTimeout(() => {
          dispatch(
            checkDuplicatedPO({
              subOrderId,
              customerNum: subOrder.customer_id,
              customerPo: subOrder.customer_po,
            })
          );
        }, 500);
      }

      const clear = () => {
        clearTimeout(timeOutId);
      };

      return clear;
    }
  }, [
    subOrder.status,
    subOrder.customer_id,
    subOrder.customer_po,
    checkDuplicatedDisabled,
  ]);

  useEffect(() => {
    if (!checkDuplicatedDisabled) {
      if (subOrder.poDuplicatedSubOrders?.length) {
        setDuplicatedSubOrders(
          subOrder.poDuplicatedSubOrders.map((item) => ({
            date: item.date_of_order,
            orderId: (
              <Link
                to={endpoints.ORDER_ENTRY + `/order/${item.customer_order_num}`}
                target="_blank"
              >
                {item.customer_order_num}
              </Link>
            ),
            invoiceId: item.customer_invoice_num,
          }))
        );
        setOpenWarning(true);
      }
    }
  }, [subOrder.poDuplicatedSubOrders, checkDuplicatedDisabled]);

  /**
   * function to handle the onChange method of the fields
   */
  const handleChange = (event) => {
    dispatch(setCheckDuplicatedDisabled(false));

    if (subOrderId) {
      dispatch(setAutoSaveTriggerChanged());
    }

    if (event.name === "comments1") {
      let tempArray = [...subOrder.freight.comments];
      tempArray[0] = event.value;
      dispatch(
        setOrderDetails({
          freight: { ...subOrder.freight, comments: tempArray },
        })
      );
    } else if (event.name === "comments2") {
      let tempArray = [...subOrder.freight.comments];
      tempArray[1] = event.value;
      dispatch(
        setOrderDetails({
          freight: { ...subOrder.freight, comments: tempArray },
        })
      );
    } else {
      dispatch(setOrderDetails({ [event.name]: event.value }));
    }
  };

  const handleAutoSaveTriggerTrue = () => {
    if (subOrderId) {
      dispatch(setAutoSaveFocusOuttedTrue());
    }
  };

  const handleBlur = () => {
    if (triggerChanged) {
      handleAutoSaveTriggerTrue();
    }
  };

  return (
    <Col className="OrderDetails">
      <Card.Title className="ps-3">Details</Card.Title>
      <Row className="ps-3 mb-4">
        <Col md={6}>
          <TextField
            id="filled-basic"
            label={
              <span>
                PO#{" "}
                {customerPoIsRequired && <span className="text-danger">*</span>}
              </span>
            }
            name="customer_po"
            disabled={savedAsQuote || subOrdersLocked ? true : false}
            style={formStyle.inputStyle}
            value={subOrder.customer_po}
            onChange={(e) => handleChange(e.target)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                billOnlyInputRef.current.focus();
              }
            }}
            inputProps={{
              maxLength: 20,
            }}
            error={customerPoHasError}
            helperText={
              customerPoIsRequired ? (
                <span className="text-danger">*REQUIRED</span>
              ) : (
                ""
              )
            }
            InputLabelProps={formStyle.inputLabelProps}
            inputRef={poInputRef}
            onClick={onClickHandler}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Order Date"
              className="muiDatePicker"
              inputFormat="MM/DD/YYYY"
              name="date_of_order"
              disabled={true}
              value={subOrder.date_of_order}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                />
              )}
            />
          </LocalizationProvider>
        </Col>
      </Row>
      <Row className="ps-3 mb-4">
        <Col md={6}>
          <InputNumber
            id="filled-basic"
            label="INVOICE#"
            readOnly={true}
            // disabled
            name="customer_invoice_num"
            style={formStyle.inputStyle}
            onChange={(e) => handleChange(e.target)}
            value={subOrder.customer_invoice_num}
            InputLabelProps={formStyle.inputLabelProps}
            onClick={onClickHandler}
            disabled={savedAsQuote || subOrdersLocked ? true : false}
          />
        </Col>
        <Col md={6}>
          <Autocomplete
            label="Bill Only"
            name="is_bill_only"
            disabled={savedAsQuote || subOrdersLocked ? true : false}
            options={["Yes", "No"]}
            value={subOrder.is_bill_only ? "Yes" : "No"}
            onChange={(_e, value) => {
              handleChange({
                name: "is_bill_only",
                value: value === "Yes" ? true : false,
              });
              handleAutoSaveTriggerTrue();
              orderedByInputRef.current.focus();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Bill Only"
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                inputRef={(ref) => (billOnlyInputRef.current = ref)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    orderedByInputRef.current.focus();
                  }
                }}
                onClick={onClickHandler}
              />
            )}
          />
        </Col>
      </Row>

      <Row className="ps-3 mb-4">
        <Col>
          <TextField
            id="filled-basic"
            fullWidth
            label={
              <span>
                Ordered By <span className="text-danger">*</span>
              </span>
            }
            name="placed_by"
            disabled={savedAsQuote || subOrdersLocked ? true : false}
            onChange={(e) => handleChange(e.target)}
            style={formStyle.inputStyle}
            value={subOrder.placed_by}
            error={orderedByHasError}
            helperText={<span className="text-danger">*REQUIRED</span>}
            InputLabelProps={formStyle.inputLabelProps}
            inputRef={orderedByInputRef}
            inputProps={{
              maxLength: 15,
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                orderCommentInputRef.current.focus();
              }
            }}
            onClick={onClickHandler}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row className="ps-3 mb-4">
        <Col md={6}>
          <TextField
            id="filled-basic"
            value={subOrder.freight.comments[0]}
            fullWidth
            label="Order Comments"
            name="comments1"
            disabled={savedAsQuote || subOrdersLocked ? true : false}
            onChange={(e) => handleChange(e.target)}
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            inputProps={{ maxLength: 35 }}
            inputRef={orderCommentInputRef}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                orderComment2InputRef.current.focus();
              }
            }}
            onClick={onClickHandler}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6}>
          <TextField
            id="filled-basic"
            value={subOrder.freight.comments[1]}
            fullWidth
            label="Order Comments 2"
            name="comments2"
            disabled={savedAsQuote || subOrdersLocked ? true : false}
            onChange={(e) => handleChange(e.target)}
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            inputProps={{ maxLength: 35 }}
            inputRef={orderComment2InputRef}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                specialInstrInputRef.current.focus();
              }
            }}
            onClick={onClickHandler}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row className="ps-3 mt-4">
        <Col>
          <TextField
            id="filled-basic"
            value={subOrder.special_instructions}
            fullWidth
            label="Special Instructions"
            name="special_instructions"
            disabled={savedAsQuote || subOrdersLocked ? true : false}
            onChange={(e) => handleChange(e.target)}
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            inputProps={{ maxLength: 40 }}
            inputRef={specialInstrInputRef}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                dispatch(setFieldRef("item-1"));
                dispatch(setSectionRef(ITEMS_SECTION_REF));
              }
            }}
            onClick={onClickHandler}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row className="ps-3">
        <Col>
          <FormGroup>
            <FormControlLabel
              label="Stock Transfer"
              control={
                <Checkbox
                  checked={
                    subOrder.is_stock_transfer === "Y" ||
                    subOrder.is_stock_transfer === true
                  }
                  disabled
                />
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ padding: 10, marginLeft: 10 }}>
        <Col>
          <b>
            Most Popular Items in the last year (
            {!showCust ? "for all" : `For Cust#: ${customerId}`})
          </b>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="f-right ">
            <FormControlLabel
              control={
                <Switch
                  checked={showCust}
                  disabled={customerId === 0}
                  onChange={(e) => {
                    setShowCust(!showCust);
                  }}
                />
              }
              label="By Customer"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ padding: 10 }}>
        {!showCust ? (
          <Col>
            <MostPopularItems listData={allPopular} />
          </Col>
        ) : (
          <Col>
            <MostPopularItems listData={custPopular} />
          </Col>
        )}
      </Row>
      <Modal
        show={openWarning}
        onHide={() => setOpenWarning(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="p-5">
          <Modal.Title>PO#({subOrder.customer_po}) is duplicated.</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5 po-duplicated-suborders-popup__table">
          <Table
            headerAttributes={[
              {
                id: "date",
                label: "Date",
                width: "20%",
              },
              {
                id: "orderId",
                label: "Order#",
                align: "right",
              },
              {
                id: "invoiceId",
                label: "Invoice#",
                align: "right",
              },
            ]}
            dataList={duplicatedSubOrders}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setOpenWarning(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default OrderDetails;

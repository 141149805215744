import { memo, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./DuplicateCreditPopup.scss";
import { endpoints } from "../../../api/endpoints";
import {
  CREDIT_APPROVAL_STATUS,
  CREDIT_HOLD_STATUS,
  CREDIT_TYPE,
  KEY_CODES,
} from "../../../helpers/const";

function DuplicateCreditPopup({
  content,
  show,
  handleCancelEvent,
  handleConfirmEvent,
}) {
  useEffect(() => {
    const handleEnter = (e) => {
      if (e.keyCode === KEY_CODES.ENTER) {
        handleConfirmEvent();
      }
    };
    if (show) {
      window.addEventListener("keydown", handleEnter);
    }

    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [show, handleConfirmEvent]);

  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          The following credits were found for this invoice, do you want to
          continue?
        </div>
      </Modal.Header>

      <Modal.Body>
        <table className="subs-table">
          <thead>
            <tr
              className="subs-table-head"
              style={{ borderBottom: "solid black 2px" }}
            >
              <th>Type</th>
              <th>Credit #</th>
              <th>Date Entered</th>
              <th>Entered By</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {content.map((item, index) => (
              <tr key={index} style={{ fontSize: "140%" }}>
                <td>
                  {
                    CREDIT_TYPE.filter(
                      (type) => type.id === parseInt(item.credit_type)
                    )[0].label
                  }
                </td>
                <td> {item.credit_memo_num} </td>
                <td> {item.date_entered}</td>
                <td> {item.entered_by}</td>
                <td>
                  {" "}
                  {
                    CREDIT_APPROVAL_STATUS.filter(
                      (stat) => stat.id === parseInt(item.status.split(" ")[0])
                    )[0].label
                  }{" "}
                  {item.status.length > 1 &&
                    CREDIT_HOLD_STATUS.filter(
                      (stat) => stat.id === parseInt(item.status.split(" ")[1])
                    )[0].label}
                </td>
                <td>
                  <a
                    style={{ fontSize: "110%", color: "black" }}
                    href={`${endpoints.CREDIT_ENTRY}/credit/${item.credit_memo_num}`}
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(DuplicateCreditPopup);

import { memo } from "react";
import { Modal, Button } from "react-bootstrap";

function ConfirmationAlert({
  content,
  show,
  handledCancelEvent,
  handleConfirmEvent,
  confirmationLabel,
}) {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header className="p-5">
        <Modal.Title>
          <h3 className="h5">Confirm</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">{content}</Modal.Body>
      <Modal.Footer>
        <Button
          variant={confirmationLabel === "Delete" ? "primary" : "danger"}
          onClick={handledCancelEvent}
        >
          Cancel
        </Button>
        <Button
          variant={confirmationLabel === "Delete" ? "danger" : "primary"}
          onClick={handleConfirmEvent}
        >
          {confirmationLabel ? confirmationLabel : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(ConfirmationAlert);

import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Button, Accordion } from "react-bootstrap";
import {
  TextField,
  Autocomplete,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  IconButton,
  Tooltip,
  Alert,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { formStyle } from "../../../theming/formStyle.js";
import DropShipPopup from "./DropShipPopup";
import ThirdPartyBillingPopup from "./ThirdPartyBillingPopup";
import DirectShipPopup from "./DirectShipPopup";
import FreightRaterPopup from "./FreightRaterPopup.js";
import useFocusEvent from "../../../hooks/useFocusEvent";
import { AlertMessageContext } from "../../atoms/AlertMessage/AlertMessage.js";
import {
  setFreight,
  setFreightLine,
  setPrepaid,
  deleteDropShip,
  toggleDropShipPopupState,
  verifyFreightDetails,
  getFreightLineList,
  setThirdPartyBilling,
  toggleFreightRaterPopupState,
  setFreightRater,
  getBolAppendable,
  getBolDetail,
} from "../../../store/slices/freight/freightSlice.js";
import {
  setAutoSaveTriggerChanged,
  setAutoSaveFocusOuttedTrue,
  setVerifyFreightDetailsTriggerChanged,
} from "../../../store/slices/eventHandlerSlice.js";

import { FREIGHT_SECTION_REF, SUB_ORDER_BOL } from "../../../helpers/const";
import {
  validateNumber,
  isDigitsString,
} from "../../../helpers/helperFunctions";

import "./FreightDetails.scss";
import InputNumber from "../../atoms/InputNumber/index.js";
import { BolAppendDialog } from "./BolAppendDialog.js";
import { BolDetailDialog } from "./BolDetailDialog.js";

const FreightDetails = () => {
  const customAlert = useContext(AlertMessageContext);
  const [openBolAppendModal, setOpenBolAppendModal] = useState(false);
  const [openBolDetailModal, setOpenBolDetailModal] = useState(false);
  const [collapse, setCollapse] = useState(false);

  // Redux
  const dispatch = useDispatch();

  const { savedAsQuote } = useSelector((state) => state.header);

  const {
    subOrder: {
      tax,
      surcharge,
      discount,
      warehouse_id,
      freight: { has_shipping_rules },
    },
    subOrderId,
    subOrdersLocked,
  } = useSelector((state) => state.subOrder);

  const { items: orderItems } = useSelector((state) => state.items);

  const {
    customerId,
    customerData: { shippingZipCode },
  } = useSelector((state) => state.customer);

  const { quoteId } = useSelector((state) => state.quote);
  const {
    freight: { freightAmountHasError, handlingChargeHasError },
  } = useSelector((state) => state.validations);

  const [showTrackWarning, setShowTrackWarning] = useState("none");

  const {
    billOfLading,
    billAppendable,
    bolDetail,
    freightAmount,
    actualCost,
    weight,
    isDirectShip,
    deliveryTarget,
    freightLine,
    handlingCharge,
    prepaid,
    blindShip,
    dropShipAddress,
    triggerDropShipFocus,
    availableFreightLines,
    thirdPartyBilling,
    prepaidWeight,
    loadingFreightDetails,
    freightRater: { rated, ratedWeight },
  } = useSelector((state) => state.freight);

  // Show freight rater popup warning
  useEffect(() => {
    if (rated && weight !== ratedWeight) {
      customAlert.handleOpen(
        "Please re-rate the freight",
        "danger",
        "The weight of the order has changed. Please re-rate the freight.",
        4000
      );

      dispatch(
        setFreightRater({
          rateLTLOptions: [],
          packageRaterOptions: [],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rated, weight, ratedWeight]);

  useEffect(() => {
    var found = false;

    if (orderItems.length > 0) {
      for (var i = 0; i < orderItems.length; i++) {
        var item = orderItems[i];
        if (
          String(item.productCode).substring(0, 2) === "17" ||
          String(item.productCode).substring(0, 2) === "16" ||
          String(item.productCode).substring(0, 2) === "15"
        ) {
          found = true;
        }
      }
    }
    if (found === true) setShowTrackWarning("");
    else setShowTrackWarning("none");
  }, [orderItems]);

  const {
    verifyFreightDetails: { triggerChanged: verifyTriggerChanged },
    autoSaveOrder: { triggerChanged: autoSaveTriggerChanged },
  } = useSelector((state) => state.eventHandler);

  // States
  const [showThirdPartyBillingPopup, setShowThirdPartyBillingPopup] =
    useState(false);
  const [freightLineValue, setFreightLineValue] = useState();

  // Freight lines states
  const [openFreightLines, setOpenFreightLines] = useState(false);
  const loadingFreightLines =
    openFreightLines && availableFreightLines.length === 0;

  // Refs
  const bolAppendButtonRef = useRef(null);
  const freightInputRef = useRef(null);
  const lineInputRef = useRef(null);
  const hcInputRef = useRef(null);

  // Checkboxes refs
  const isDirectShipRef = useRef();
  const prepaidRef = useRef();
  const dropShipRef = useRef();
  const blindShipRef = useRef();
  const otherFreightRef = useRef();

  // Custom hooks
  useFocusEvent(FREIGHT_SECTION_REF, SUB_ORDER_BOL, bolAppendButtonRef);

  // Show freight rater popup warning
  useEffect(() => {
    if (rated && weight !== ratedWeight) {
      customAlert.handleOpen(
        "Please re-rate the freight",
        "danger",
        "The weight of the order has changed. Please re-rate the freight.",
        4000
      );

      dispatch(
        setFreightRater({
          rateLTLOptions: [],
          packageRaterOptions: [],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rated, weight, ratedWeight]);

  useEffect(() => {
    dispatch(getBolAppendable({ subOrderId, billOfLading, warehouse_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOrderId, billOfLading, warehouse_id]);

  useEffect(() => {
    if (billOfLading > 0) {
      dispatch(getBolDetail(billOfLading));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billOfLading]);

  useEffect(() => {
    if (loadingFreightLines) {
      (async () => {
        getFreightLines({ page: 1 });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFreightLines]);

  useEffect(() => {
    if (triggerDropShipFocus) {
      dropShipRef.current.focus();
    }
  }, [triggerDropShipFocus]);

  useEffect(() => {
    let timeoutId;
    if (freightLineValue !== undefined) {
      if (freightLineValue) {
        timeoutId = setTimeout(() => {
          getFreightLines(
            isDigitsString(freightLineValue)
              ? { id: freightLineValue }
              : { name: freightLineValue }
          );
        }, 500);
      } else {
        getFreightLines({ page: 1 });
      }
    }
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freightLineValue]);

  /**
   * Function to catch the onBlur events of the Freight, Hnd Chg and CC fields
   * @param {object} event - event info about the field
   */
  const handleBlur = (event) => {
    if (event.value) {
      dispatch(
        setFreight({ [event.name]: parseFloat(event.value).toFixed(2) })
      );
    }

    handleBlurForAutoSave();
  };

  const handleBlurForAutoSave = () => {
    if (autoSaveTriggerChanged) {
      handleAutoSaveTriggerTrue();
    }
  };

  const handleAutoSaveTriggerChange = () => {
    if (subOrderId) {
      dispatch(setAutoSaveTriggerChanged());
    }
  };

  const handleAutoSaveTriggerTrue = () => {
    if (subOrderId) {
      dispatch(setAutoSaveFocusOuttedTrue());
    }
  };

  const getFreightLines = ({ id, name, page }) => {
    dispatch(getFreightLineList({ id, name, page }));
  };

  const freightAmountHandleBlur = () => {
    if (verifyTriggerChanged && !has_shipping_rules) {
      dispatch(verifyFreightDetails()).then(() => {
        handleAutoSaveTriggerTrue();
      });
    }
  };

  const onKeyDownHandler = (event, ref) => {
    if (event.key === "Enter" && !event.shiftKey) {
      if (event.target.name === "bill_of_lading") {
        if (customerId) {
          ref.current.focus();
        } else {
          lineInputRef.current.focus();
        }
      } else if (event.target.name === "handling_charge") {
        if (customerId && (savedAsQuote || subOrdersLocked || subOrderId)) {
          prepaidRef.current.focus();
        } else {
          ref.current.focus();
        }
      } else if (event.target.name === "prepaid") {
        if (customerId) {
          ref.current.focus();
        } else {
          otherFreightRef.current.focus();
        }
      } else if (event.target.name === "dropShip") {
        if (dropShipAddress.isDropShip) {
          ref.current.focus();
        } else {
          otherFreightRef.current.focus();
        }
      } else {
        ref.current.focus();
      }
    }
  };

  const handleOpenBolAppendModal = () => {
    setOpenBolAppendModal(true);
  };

  const handleCloseBolAppendModal = () => {
    setOpenBolAppendModal(false);
  };

  const handleOpenBolDetailModal = () => {
    setOpenBolDetailModal(true);
  };

  const handleCloseBolDetailModal = () => {
    setOpenBolDetailModal(false);
  };

  return (
    <div className="FreightDetails">
      <DropShipPopup handleAutoSaveTriggerTrue={handleAutoSaveTriggerTrue} />
      <DirectShipPopup />
      <FreightRaterPopup />
      {billAppendable && (
        <BolAppendDialog
          open={openBolAppendModal}
          onClose={handleCloseBolAppendModal}
        />
      )}
      <BolDetailDialog
        open={openBolDetailModal}
        onClose={handleCloseBolDetailModal}
      />
      <Card className="mb-3 container-fluid">
        <Card.Body>
          <Accordion className="accordion-flush">
            <Accordion.Item>
              <Accordion.Header>
                <Row>
                  <Col>
                    <h3>Freight</h3>
                  </Col>
                </Row>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="mb-4">
                  <Col>
                    <Alert
                      style={{ color: "darkred", display: showTrackWarning }}
                      severity="error"
                    >
                      There is a track present, please check freight amount.
                    </Alert>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={7}></Col>
                  <Col sm={5} className="text-end">
                    <Button
                      disabled={subOrderId !== billOfLading}
                      onClick={(_e) =>
                        dispatch(toggleFreightRaterPopupState(true))
                      }
                      variant="primary"
                      className="mt-3"
                    >
                      Freight Rater
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col sm={9}>
                    <Row className="mb-4">
                      <Col sm={4} className="d-flex justify-content-end gap-2">
                        <InputNumber
                          id="filled-basic"
                          label="BOL #"
                          name="bill_of_lading"
                          value={billOfLading}
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                          disabled
                        />
                        {billAppendable && (
                          <Button
                            onClick={handleOpenBolAppendModal}
                            ref={bolAppendButtonRef}
                          >
                            Append
                          </Button>
                        )}
                        {billOfLading > 0 &&
                          (subOrderId !== billOfLading ||
                            bolDetail.subOrderIds?.length > 0) && (
                            <Tooltip title="BOL Details" placement="top" arrow>
                              <IconButton
                                color="primary"
                                aria-label="open BOL detail modal"
                                onClick={handleOpenBolDetailModal}
                              >
                                <ArticleIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                      </Col>
                      <Col sm={4}>
                        <TextField
                          id="filled-basic"
                          type="text"
                          label="Freight Amount"
                          value={freightAmount}
                          onChange={(e) => {
                            const validaNumber = validateNumber(
                              e.target.value,
                              2
                            );
                            if (validaNumber) {
                              dispatch(
                                setFreight({ freightAmount: e.target.value })
                              );
                              dispatch(setVerifyFreightDetailsTriggerChanged());
                            }
                          }}
                          style={formStyle.inputStyle}
                          name="freightAmount"
                          onBlur={freightAmountHandleBlur}
                          InputLabelProps={formStyle.inputLabelProps}
                          inputRef={freightInputRef}
                          onKeyDown={(event) =>
                            onKeyDownHandler(event, lineInputRef)
                          }
                          disabled={
                            savedAsQuote ||
                            subOrdersLocked ||
                            !customerId ||
                            subOrderId !== billOfLading
                              ? true
                              : false
                          }
                          InputProps={{
                            endAdornment: (
                              <React.Fragment>
                                {loadingFreightDetails ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                              </React.Fragment>
                            ),
                          }}
                          error={freightAmountHasError}
                          helperText={
                            freightAmountHasError
                              ? `This field should be less than 1,000,000.`
                              : ""
                          }
                        />
                        {!customerId && (
                          <div className="little-warning text-danger">
                            You have to select a customer to enable this field.
                          </div>
                        )}
                      </Col>
                      <Col sm={4} className="weight">
                        <InputNumber
                          id="filled-basic"
                          label="Weight"
                          name="weight"
                          disabled
                          onChange={(e) =>
                            dispatch(setFreight({ weight: e.target.value }))
                          }
                          value={weight}
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={8}>
                        <Autocomplete
                          freeSolo
                          className="autocomplete-input"
                          options={availableFreightLines}
                          name="freight_line_num"
                          value={freightLine ? freightLine : null}
                          onOpen={() => {
                            setOpenFreightLines(true);
                          }}
                          onClose={() => {
                            setOpenFreightLines(false);
                          }}
                          onChange={(_e, value) => {
                            dispatch(setFreightLine(value));
                            handleAutoSaveTriggerTrue();
                          }}
                          loading={loadingFreightLines}
                          getOptionLabel={(option) => {
                            return option.title;
                          }}
                          openOnFocus
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Line"
                              style={formStyle.inputStyle}
                              InputLabelProps={formStyle.inputLabelProps}
                              inputRef={lineInputRef}
                              value={freightLineValue}
                              onChange={(e) =>
                                setFreightLineValue(e.target.value)
                              }
                              onKeyDown={(event) =>
                                onKeyDownHandler(event, hcInputRef)
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loadingFreightLines ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                          disabled={
                            savedAsQuote ||
                            subOrdersLocked ||
                            subOrderId !== billOfLading
                              ? true
                              : false
                          }
                        />
                        {rated && weight === ratedWeight && (
                          <span className="little-warning text-danger">
                            RATED
                          </span>
                        )}
                        {rated && weight !== ratedWeight && (
                          <span className="little-warning text-danger">
                            PLEASE RE-RATE
                          </span>
                        )}
                      </Col>
                      <Col sm={4}>
                        <InputNumber
                          id="filled-basic"
                          label="Handling Charge"
                          value={handlingCharge}
                          name="handling_charge"
                          onChange={(e) => {
                            const validaNumber = validateNumber(
                              e.target.value,
                              2
                            );
                            if (validaNumber) {
                              dispatch(
                                setFreight({ handlingCharge: e.target.value })
                              );
                              handleAutoSaveTriggerChange();
                            }
                          }}
                          onBlur={(e) => {
                            handleBlur(e.target);
                          }}
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                          inputRef={hcInputRef}
                          onKeyDown={(event) =>
                            onKeyDownHandler(event, isDirectShipRef)
                          }
                          disabled={
                            savedAsQuote ||
                            subOrdersLocked ||
                            subOrderId !== billOfLading
                              ? true
                              : false
                          }
                          error={handlingChargeHasError}
                          helperText={
                            handlingChargeHasError
                              ? `This field should be less than 10,000`
                              : ""
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Due Date"
                            disabled
                            className="muiDatePicker"
                            inputFormat="MM/DD/YYYY"
                            value={deliveryTarget}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={formStyle.inputStyle}
                                InputLabelProps={formStyle.inputLabelProps}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Col>
                      <Col sm={4}>
                        <InputNumber
                          id="filled-basic"
                          label="Sales Tax"
                          disabled
                          name="tax"
                          onChange={(e) =>
                            dispatch(setFreight({ tax: e.target.value }))
                          }
                          onBlur={(e) => handleBlur(e.target)}
                          value={tax}
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                        />
                      </Col>
                      <Col sm={4}>
                        <InputNumber
                          id="filled-basic"
                          label="Surcharge"
                          name="surcharge"
                          disabled
                          //onChange={e => handleChange(e.target)}
                          //onBlur={e => handleBlur(e.target)}
                          value={surcharge}
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={4}>
                        <InputNumber
                          id="filled-basic"
                          label="Discount"
                          name="discount"
                          disabled
                          //onChange={e =>  dispatch(setFreightValue({ discount: e.target.value }))}
                          value={discount}
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                        />
                      </Col>
                      <Col sm={4}>
                        <InputNumber
                          label="Prepaid Weight"
                          name="prepaidWeight"
                          value={prepaidWeight}
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                          disabled
                        />
                      </Col>
                      <Col sm={4}>
                        <InputNumber
                          label="Actual Cost"
                          name="actualCost"
                          value={actualCost}
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={2}>
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isDirectShip}
                              inputRef={isDirectShipRef}
                              onChange={(e) => {
                                dispatch(
                                  setFreight({ isDirectShip: e.target.checked })
                                );
                              }}
                              sx={formStyle.checkboxStyle}
                              disabled={
                                savedAsQuote || subOrdersLocked || subOrderId
                                  ? true
                                  : false
                              }
                              name="direct_ship"
                              onKeyDown={(event) =>
                                onKeyDownHandler(event, prepaidRef)
                              }
                            />
                          }
                          label="Direct Ship"
                        />
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={prepaid}
                              inputRef={prepaidRef}
                              name="prepaid"
                              onChange={(e) => {
                                dispatch(setPrepaid(e.target.checked));
                                handleAutoSaveTriggerTrue();
                              }}
                              sx={formStyle.checkboxStyle}
                              disabled={
                                savedAsQuote ||
                                subOrdersLocked ||
                                subOrderId !== billOfLading
                                  ? true
                                  : false
                              }
                              onKeyDown={(event) =>
                                onKeyDownHandler(event, dropShipRef)
                              }
                            />
                          }
                          label="Prepaid"
                        />
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                dropShipAddress.isDropShip ? true : false
                              }
                              inputRef={dropShipRef}
                              name="dropShip"
                              onChange={(e, value) => {
                                if (value) {
                                  dispatch(toggleDropShipPopupState(true));
                                } else {
                                  dispatch(deleteDropShip({ shippingZipCode }));
                                  handleAutoSaveTriggerTrue();
                                }
                              }}
                              sx={formStyle.checkboxStyle}
                              disabled={
                                savedAsQuote ||
                                subOrdersLocked ||
                                !customerId ||
                                subOrderId !== billOfLading
                                  ? true
                                  : false
                              }
                              onKeyDown={(event) =>
                                onKeyDownHandler(event, blindShipRef)
                              }
                            />
                          }
                          label={
                            dropShipAddress.isDropShip && !quoteId ? (
                              <div className="d-flex flex-column align-items-start">
                                <span>Drop Ship </span>
                                <button
                                  type="button"
                                  className="d-flex drop-ship-edit-icon text-primary"
                                  disabled={quoteId ? true : false}
                                  onClick={() =>
                                    dispatch(toggleDropShipPopupState(true))
                                  }
                                >
                                  <span style={{ textAlign: "left" }}>
                                    {dropShipAddress.name}
                                  </span>
                                </button>
                              </div>
                            ) : (
                              <span>
                                Drop Ship
                                {!customerId && (
                                  <div className="little-warning text-danger">
                                    You have to select a customer
                                  </div>
                                )}
                              </span>
                            )
                          }
                        />
                        {}
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={blindShip}
                              onChange={(e) => {
                                dispatch(
                                  setFreight({ blindShip: e.target.checked })
                                );
                                handleAutoSaveTriggerTrue();
                              }}
                              sx={formStyle.checkboxStyle}
                              disabled={
                                quoteId ||
                                subOrdersLocked ||
                                !dropShipAddress.isDropShip
                                  ? true
                                  : false
                              }
                              inputRef={blindShipRef}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  otherFreightRef.current.focus();
                                }
                              }}
                            />
                          }
                          label="Blind Ship"
                        />
                      </FormGroup>
                    </div>

                    <div className="d-flex">
                      {thirdPartyBilling.name ? (
                        <>
                          <Checkbox
                            style={{ marginLeft: "-10px" }}
                            sx={formStyle.checkboxStyle}
                            disabled={
                              savedAsQuote ||
                              subOrdersLocked ||
                              subOrderId !== billOfLading
                                ? true
                                : false
                            }
                            checked={true}
                            onChange={(e, value) => {
                              if (value) {
                                setShowThirdPartyBillingPopup(true);
                              } else {
                                dispatch(
                                  setThirdPartyBilling({
                                    name: "",
                                    addr1: "",
                                    addr2: "",
                                    city: "",
                                    state: "",
                                    account: "",
                                    zipCode: "",
                                  })
                                );
                                handleAutoSaveTriggerTrue();
                              }
                            }}
                            inputRef={otherFreightRef}
                          />
                          <div className="d-flex flex-column align-items-start mui-custom-label">
                            <span>3rd Party Freight </span>
                            <button
                              type="button"
                              className="d-flex drop-ship-edit-icon text-primary"
                              onClick={() =>
                                setShowThirdPartyBillingPopup(true)
                              }
                              disabled={
                                savedAsQuote || subOrdersLocked ? true : false
                              }
                            >
                              <span style={{ textAlign: "left" }}>
                                {thirdPartyBilling.name}
                              </span>
                            </button>
                          </div>
                        </>
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={formStyle.checkboxStyle}
                                disabled={
                                  savedAsQuote ||
                                  subOrdersLocked ||
                                  subOrderId !== billOfLading
                                    ? true
                                    : false
                                }
                                checked={false}
                                onChange={(e, value) => {
                                  if (value) {
                                    setShowThirdPartyBillingPopup(true);
                                  } else {
                                    dispatch(
                                      setThirdPartyBilling({
                                        name: "",
                                        addr1: "",
                                        addr2: "",
                                        city: "",
                                        state: "",
                                        account: "",
                                        zipCode: "",
                                      })
                                    );
                                    handleAutoSaveTriggerTrue();
                                  }
                                }}
                                inputRef={otherFreightRef}
                              />
                            }
                            label={<span>3rd Party Freight</span>}
                          />
                        </FormGroup>
                      )}

                      <ThirdPartyBillingPopup
                        showPopup={showThirdPartyBillingPopup}
                        setShowPopup={useCallback(
                          (param) => setShowThirdPartyBillingPopup(param),
                          []
                        )}
                        handleAutoSaveTriggerTrue={handleAutoSaveTriggerTrue}
                      />
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>
    </div>
  );
};

export default FreightDetails;

import { BASE_API_URL } from "./baseUrl";
// Base URL for the API
export const endpoints = {
  TESTAPI_URL: "https://jsonplaceholder.typicode.com/posts",
  MITCO_API_URL: BASE_API_URL,

  // Authentication Endpoints
  USER_LOGIN: "/users/login/",
  USER_LOGOUT: "/users/logout/",

  // Endpoints for the API
  CUSTOMER_LIST: "/client/", // add customer id to end of endpoint to get specific customer
  CUSTOMER_CREDITS_LIST: "/client/credits/",
  CUSTOMER_NETPRICE_LIST: "/client/netprice/",
  CUSTOMER_ORDERS_LIST: "/client/orders/",
  TRACKS_LIST: "/tracks/",
  VC: "/vc/",
  WAREHOUSE_LIST: "/warehouse/", // add warehouse id to end of endpoint to get specific warehouse
  CSA_ORDER_READ: "/csa/order/", // add id
  CSA_GET_ITEM_SUBS: "/csa/item_substitution/get_substitutions/",
  SUB_ORDER_READ: "/suborder/", // add id
  SUB_ORDER_POST: "/suborder/",
  QUOTE_POST: "/suborder/as_quote/",
  SUBORDER_ITEMS_CREATE: "/items/",
  SUBORDER_ITEMS_DELETE: "/items/",
  CSA_PRODUCT: "/csa/product/",
  CSA_MARGIN: "/csa/margin/",
  CSA_CREDIT: "/csa/credit/",
  CSA_WAREHOUSE: "/csa/warehouse/",
  CSA_DUPLICATE: "/csa/duplicate/",
  CSA_CHECK_MARGIN: "check_margin/",
  CSA_PRINT_ORDER_CONFIRMATION: "print_confirmation/",
  CSA_CHECK_CREDIT: "check_credit/",
  CSA_CHECK_DUPLICATE: "check_duplicate",
  CSA_PRODUCT_PRICE: "/price/",
  CSA_MANUFACTURE: "/csa/manufactured/",
  CSA_GET_MANU_ITEMS: "manufactured_items/",
  ITEMS_READ: "/items/", // add { product_code } to end of endpoint to get specific item
  WAREHOUSE_READ: "/csa/warehouse/", // add warehouse {id} to end of endpoint to get specific warehouse
  CUSTOMER_READ: "/client/", // add {customer_num} to end of endpoint to get specific customer
  FREIGHT_LINE: "/freight_line/",
  FREIGHT_LINE_BY_SCAC: "freight_line_by_scac/",

  // Variations for specific customer info (e.g. /client/1/account_balance/)
  CUSTOMER_ACCOUNT_BALANCE: "/account_balance/",
  CUSTOMER_CHECK_CREDIT: "/check_credit/",
  CUSTOMER_VENDOR_DISCOUNTS: "/vendor_discounts/",
  RECENT_ORDERS: "/recent_orders/",
  MOST_RECENT_PRICE: "/get_last_sold_price/",
  POPULAR_NOW: "/popular_now/",

  // Vendor Category Discounts
  VENDOR_CATEGORY_DISCOUNTS: "/vendor_category_discounts/",
  DISCOUNT_SCHEDULES_FOR_PRODUCTS:
    "/vendor_category_discounts/discount_schedules/",

  DROP_SHIP_ADDRESSES: "/client/{customer}/drop_ship_addresses/",
  GET_QUOTE: "/quote/{quote_num}/",
  SAVE_AS_SUBORDER: "/quote/{quote_num}/to_suborder/",
  DELETE_SUB_ORDER: "/suborder/",
  RATE_LTL: "/rateLTL/",

  XREF_PRODUCTS: "/xref_products/",

  BOL: "/bill_of_lading/",

  ACTIVE_WAREHOUSES: "get_active_warehouses/",

  SUGGESTED_ORDER_REPORT: "/reports/suggested_order_report/",
  INVOICES: "/reports/print_invoice/",
  DEFAULT_CUSTOMER_NUM: "/reports/has_customer_num/",
  CUSTOMER_EMAILS: "/reports/all_customer_emails/",

  ORDER_ENTRY: "/customer-orders",
  CREDIT_ENTRY: "/customer-credits",
  PURCHASE_ENTRY: "/purchasing",
  ACCOUNTS_RECEIVABLE_ENTRY: "/accounts-receivable",

  //Start Credits
  CREDIT_BASE: "/credits/",
  CREDIT_DUPLICATE: "check_duplicate_credit/",
  CREDIT_INITIAL_ORDER: "get_initial_order/",
  CREDIT_PROBLEMS_ALL: "get_credit_problems/",
  CREDIT_CREATE: "post_credit/",
  CREDIT_PRINT: "print_confirmation",

  //start claims
  CLAIM_BASE: "/claims/",
  CLAIM_DUPLICATE: "check_duplicate_claims",
  CLAIM_CREATE: "create_claim/",
  CLAIM_GET: "get_claim/",
  CLAIM_UPDATE: "update_claim/",
  CLAIM_DELETE: "delete_claim/",
  CLAIM_UNDO_DELETE: "undo_delete/",
  CLAIM_ADD_LOG: "add_claim_log/",
  CLAIM_IMAGE_UPLOAD: "save_images/",
  CLAIM_IMAGE_GET: "get_images/",
  CLAIM_CONVERT_CREDIT: "convert_to_credit/",
  CLAIM_SUMMARY: "print_claim/",

  //API Logs
  APILOGS_BASE: "/ApiLogs/",

  //Rater Logs
  RATERLOGS_BASE: "/RaterLogs/",
  RATERLOGS_SAVE: "save_rated_quotes/",

  //invoices
  INVOICE_BASE: "/invoices/",

  //Images
  IMAGE_BASE: "/images/",
  IMAGE_UPLOAD: "upload_image/",
  IMAGE_DELETE: "delete_image/",
  IMAGE_GET: "get_image/",
};

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { memo, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import BackOrderMessage from "../BackOrderMessage";
import { TextField } from "@mui/material";
import { formStyle } from "../../../theming/formStyle";
import StatusSelector from "../../sections/SubHeading/StatusSelector";
import ARBalanceMessage from "../ARBalanceMessage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../../api/endpoints";
import { resetSubmitProcess } from "../../../store/slices/validations/validationsSlice";
import { AlertMessageContext } from "../AlertMessage/AlertMessage";
import {
  createSubOrder,
  printBol,
  setCheckDuplicatedDisabled,
  updateSubOrder,
} from "../../../store/slices/subOrderSlice";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { deleteItems } from "../../../store/slices/item/itemSlice";
import {
  DATE_ORDER_HOLD_STATUS,
  OPEN_ORDER_STATUS,
  PENDING_ORDER_STATUS,
} from "../../../helpers/const";
import FaxEmail from "../FaxEmail/FaxEmail";

const SaveOrderPopup = ({
  show,
  setShowStatusPopup,
  validateFields,
  savingAsQuote,
  setSaved,
}) => {
  const {
    submitProcess,
    hasItemsErrors,
    hasCustomerErrors,
    hasOrderDetailsErrors,
    hasWhError,
    hasFreightError,
  } = useSelector((state) => state.validations);

  const {
    subOrder,
    subOrder: { status: orderStatus, hold_status: hold_status },
    subOrderId,
  } = useSelector((state) => state.subOrder);

  const { allItemsToDelete } = useSelector((state) => state.items);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Context
  const alertNotification = useContext(AlertMessageContext);

  const [customerComment, setCustomerComment] = useState("");
  const [commentDate, setCommentDate] = useState(dayjs());

  const doPostSaveAction = (response) => {
    setSaved(true);
    if (response.payload.customMsg === 1) {
      alertNotification.handleOpen(
        "create-suborder-success",
        "success",
        "Order Saved",
        3000
      );
      dispatch(printBol()).then((res) => {
        let success = "not";
        if (!res?.error && res.payload.status === 1) success = "";
        alertNotification.handleOpen(
          "success-savign-as-sub-order",
          success ? "warning" : "success",
          `Bol ${success} printed to warehouse!`,
          3000
        );
      });
    } else if (response.payload.customMsg === 2) {
      alertNotification.handleOpen(
        "create-suborder-successs",
        "danger",
        "Order saved with errors. There is an issue with the order cost.",
        3000
      );
    }
  };

  /**
   * Show messages of error when the fields of the form
   * haven't been filled
   */
  useEffect(() => {
    if (submitProcess && !savingAsQuote) {
      dispatch(resetSubmitProcess());

      let error_str = "";
      if (hasCustomerErrors) error_str = "please select a customer";
      else if (hasOrderDetailsErrors)
        error_str = "Inputs require adjustment in order details section";
      else if (hasItemsErrors || hasWhError)
        error_str = "Inputs require adjustment in order items section";
      else if (hasFreightError)
        error_str = "Inputs require adjustment in order freight section";

      if (error_str) {
        alertNotification.handleOpen(
          "validation-freight-details",
          "danger",
          error_str,
          2000
        );
        return;
      }

      if (subOrderId === 0) {
        dispatch(
          createSubOrder({
            comment: customerComment,
            date: dayjs(commentDate).format("YYYY-MM-DD"),
          })
        ).then((response) => {
          if (!response?.error) {
            doPostSaveAction(response);
            navigate(endpoints.ORDER_ENTRY + "/order/" + response.payload.id);
          }
        });
      } else if (subOrderId) {
        if (allItemsToDelete.length > 0)
          dispatch(deleteItems(allItemsToDelete));

        dispatch(updateSubOrder()).then((response) => {
          doPostSaveAction(response);
        });
      }
    }
  }, [
    submitProcess,
    hasCustomerErrors,
    hasOrderDetailsErrors,
    hasItemsErrors,
    hasFreightError,
    alertNotification,
    subOrderId,
    hasWhError,
    subOrder,
    dispatch,
    navigate,
  ]);

  return (
    <>
      <Modal
        show={show}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setShowStatusPopup(false)}
      >
        <Modal.Header className="p-5" closeButton>
          <Modal.Title>
            <h3 className="h5">Confirm Save Order</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          <Row className="mt-5">
            <Col md={12}>
              <div className="mb-4">Select the Status</div>
            </Col>
            <StatusSelector />
          </Row>
          <div className="mt-5">
            A/R Balance information:{` `}
            <ARBalanceMessage />
          </div>
          <div>
            <BackOrderMessage />
          </div>
          <Row className="mt-5">
            <Col md={5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date"
                  className="muiDatePicker"
                  inputFormat="MM/DD/YYYY"
                  name="commentDate"
                  value={commentDate}
                  onChange={(newValue) => setCommentDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={formStyle.inputStyle}
                      InputLabelProps={formStyle.inputLabelProps}
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <TextField
                type="text"
                label="Customer Comments"
                name="name"
                multiline
                rows={4}
                value={customerComment}
                onChange={(e) => setCustomerComment(e.target.value)}
                style={{
                  ...formStyle.inputStyle,
                }}
                InputLabelProps={formStyle.inputLabelProps}
              />
            </Col>
          </Row>
          <div className="mt-5 d-flex justify-content-end">
            <Button
              variant="primary"
              disabled={orderStatus === OPEN_ORDER_STATUS.id}
              onClick={() => {
                dispatch(setCheckDuplicatedDisabled(true));
                if (
                  orderStatus !== PENDING_ORDER_STATUS.id &&
                  hold_status !== DATE_ORDER_HOLD_STATUS.id
                ) {
                  setShowStatusPopup(false);
                  validateFields();
                } else {
                  if (!subOrder.hold_date) {
                    alertNotification.handleOpen(
                      "holddate-error",
                      "danger",
                      "The hold date field is required",
                      5000
                    );
                  } else {
                    setShowStatusPopup(false);
                    validateFields();
                  }
                }
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(SaveOrderPopup);

import React, { useEffect, useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCookie, getShortUserName } from "../../helpers/helperFunctions";
import { SubOrderModel } from "../../api/models/SubOrderModel";
import { defaultOrderItem } from "../../api/models/ItemsModel";

// Import slices
import {
  setSavedAsOrder,
  setSavedAsQuote,
} from "../../store/slices/headerSlice";
import {
  resetSubOrderId,
  setSubOrder,
  getSubOrder,
} from "../../store/slices/subOrderSlice";
import {
  getQuote as getQuoteFromApi,
  resetQuoteId,
} from "../../store/slices/quoteSlice";
import { updateAllItems } from "../../store/slices/item/itemSlice";
import { resetFormValidations } from "../../store/slices/validations/validationsSlice";
import { resetCustomer } from "../../store/slices/customer/customerSlice";

// import all the required components
import CustomerWidget from "../../components/sections/CustomerWidget/CustomerWidget";
import OrderDetails from "../../components/sections/OrderDetails/OrderDetails";
import OrderItems from "../../components/sections/OrderItems/OrderItems";
import FreightDetails from "../../components/sections/FreightDetails/FreightDetails";
import ARBalance from "../../components/sections/ARBalance/ARBalance";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { AlertMessageContext } from "../../components/atoms/AlertMessage/AlertMessage";
import ARBalanceMessage from "../../components/atoms/ARBalanceMessage";
import LoaderSpinner from "../../components/atoms/LoaderSpinner";
import CreditLimit from "../../components/atoms/CreditLimit";

// styles
import "./SubOrder.scss";
import { Accordion, Collapse } from "react-bootstrap";

const SubOrder = ({ savedAsOrder, savedAsQuote }) => {
  // Redux
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderSpinner);

  // Local states
  const { id, customerId } = useParams();
  const [collapseCustomer, setCollapse] = useState(false);
  //const initialCustomerId = customerId ? parseInt(customerId) : 200;

  // Refs
  const poInputRef = useRef(null);
  const productInputRef = useRef([]);

  const alertNotification = useContext(AlertMessageContext);

  /**
   * set if the it was saved as an order or as a quote
   * This is useful to hide or not buttons in the header
   */
  useEffect(() => {
    dispatch(setSavedAsOrder(savedAsOrder ? true : false));
    dispatch(setSavedAsQuote(savedAsQuote ? true : false));
  }, [savedAsOrder, savedAsQuote]);

  /**
   * Getting subOrder and quote Data
   */
  useEffect(() => {
    const formattedSubOrderId = parseInt(id);
    if (formattedSubOrderId) {
      if (savedAsOrder) {
        if (typeof formattedSubOrderId === "number") {
          if (formattedSubOrderId > 0) {
            dispatch(getSubOrder(formattedSubOrderId)).then((data) => {
              if (data?.error) {
                alertNotification.handleOpen(
                  "getting-sub-order-error",
                  "danger",
                  data.error.message,
                  5000
                );
              } else {
                const {
                  payload: { formattedItems },
                } = data;
                dispatch(updateAllItems(formattedItems));
              }
            });
          }
        }
      } else if (savedAsQuote) {
        if (typeof formattedSubOrderId === "number") {
          dispatch(resetCustomer());
          dispatch(resetSubOrderId());
          dispatch(getQuoteFromApi(formattedSubOrderId)).then((response) => {
            if (response?.error) {
              alertNotification.handleOpen(
                "get-quote-error",
                "danger",
                response.error.message,
                5000
              );
            } else {
              dispatch(setSubOrder(response.payload.quote));
              dispatch(updateAllItems(response.payload.formattedItems));
            }
          });
        }
      }
    } else {
      let userName = { took_by: getShortUserName(getCookie("username")) };
      dispatch(
        setSubOrder({
          ...SubOrderModel,
          ...userName,
          customer_id: +customerId || SubOrderModel.customer_id,
        })
      );
      dispatch(updateAllItems([]));
      dispatch(resetSubOrderId(0));
      dispatch(resetQuoteId(0));
    }
    dispatch(resetFormValidations());
  }, [id]);

  return (
    <div className="SubOrder">
      <Form className="OrderForm">
        <Card className="mb-3">
          <Card.Body>
            <Accordion className="accordion-flush">
              <Accordion.Item>
                <Accordion.Header>
                  <Row>
                    <Col>
                      <h3>Customer Information/Order Details</h3>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body>
                  <Row className="gx-4">
                    <CustomerWidget />
                    <OrderDetails
                      poInputRef={poInputRef}
                      productInputRef={productInputRef}
                    />
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Body>
        </Card>

        <Row className="mb-4">
          <OrderItems />
        </Row>

        {/* End of Order Items Section  */}
        <Row className="mb-4 d-flex">
          <Col sm={9} className="d-flex justify-content-end">
            <CreditLimit />
          </Col>
          <Col
            sm={3}
            className="d-flex justify-content-end"
            style={{ textAlign: "right" }}
          >
            <ARBalanceMessage />
          </Col>
        </Row>

        <FreightDetails />
        <ARBalance />
      </Form>
      <LoaderSpinner loading={loading} />
    </div>
  );
};

export default SubOrder;

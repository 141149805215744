import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";
import { apiPost } from "../../../api/apiPost";
import { apiDelete } from "../../../api/apiDelete";
import {
  CREDIT_LINE_TYPE,
  CREDIT_TYPE,
  ORDER_ITEM_ADDON_TYPE,
  ORDER_ITEM_DESCRIPTION_TYPE,
} from "../../../helpers/const";
import {
  defaultCredit,
  defaultCreditItem,
} from "../../../api/models/CreditModels";

export const getInitialOrder = createAsyncThunk(
  "credit/initialize",
  async ({ transaction_num, type }, thunkAPI) => {
    try {
      const response = await apiGet.orderByInvoice({
        transaction_num,
        type,
      });
      if (response.data.Error) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading the initial order (Does it exist?).",
      });
    }
  }
);

export const checkDuplicateCredit = createAsyncThunk(
  "credit/duplicate",
  async ({ invoiceNum }, thunkAPI) => {
    try {
      const response = await apiGet.duplicateCredit(invoiceNum);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const getProblemList = createAsyncThunk(
  "credit/problems",
  async (thunkAPI) => {
    try {
      const response = await apiGet.problemList();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const getActiveWarehouses = createAsyncThunk(
  "credit/warehouses",
  async (thunkAPI) => {
    try {
      const response = await apiGet.activeWarehouses();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const deleteCredit = createAsyncThunk(
  "credit/deleteCredit",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        credits: { credit },
      } = storeStates;

      const response = await apiDelete.deleteCredit(credit.credit_memo_num);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const rateLTLCredit = createAsyncThunk(
  "credit/rateLTL",
  async ({ fromZip, warehouseZip, weight }, thunkAPI) => {
    try {
      const czarZip = fromZip;
      const destinationZip = warehouseZip;

      const response = await apiGet.rateLTLOptions(
        czarZip,
        destinationZip,
        weight
      );

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error rating the credit.",
      });
    }
  }
);

export const createCredit = createAsyncThunk(
  "credit/create",
  async ({ status, holdStatus, password }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        credits: {
          credit,
          creditItems,
          creditImages,
          require_password,
          creditOrder,
        },
        freightRater: { selectedRate, modPrice, prepaid },
      } = storeStates;
      const response = await apiPost.createCredit({
        credit: {
          ...credit,
          apply_to_po: creditOrder.customer_po,
          hold_status: holdStatus.id,
          customer: credit.customer.customer_num,
          freight_line: credit.freight_line.freight_line_num || 129,
          status: status.id,
          freight_amount: modPrice,
          return_prepaid: prepaid,
          tms_quote_num: selectedRate?.quote?.tmsquoteid || "",
        },
        creditItems,
        selectedRate,
        require_password,
        password,
      });

      const credit_memo_num = response.data.credit_memo_num;
      const keys = response.data.key_transformations;

      //handle images

      let tempImages = [...creditImages];
      let resultImages = [];
      let sequences = [];
      tempImages = tempImages.filter((image) => image.image !== null);
      for (let i = 0; i < tempImages.length; i++) {
        let curr_image = tempImages[i];
        let curr_key = keys.filter(
          (key) => key.old_key === curr_image.sequence
        )[0];
        if (!curr_key) {
          continue;
        }
        curr_image = {
          ...curr_image,
          sequence: curr_key.new_key,
        };
        const response = await fetch(curr_image.image);

        sequences.push(`${curr_image.key}_${curr_image.sequence}`);
        const blob = await response.blob();
        let tempImg = new File([blob], credit_memo_num + ".JPEG", {
          type: blob.type,
        });
        resultImages.push(tempImg);
      }

      const image_response = await apiPost.uploadImages({
        images: resultImages,
        credit_memo_num,
        sequences,
      });

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error saving the credit.",
      });
    }
  }
);

export const getCredit = createAsyncThunk(
  "credit/get",
  async ({ id }, thunkAPI) => {
    try {
      const response = await apiGet.getCredit(id);
      return {
        credit: response.data,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the credit.",
      });
    }
  }
);

export const getImages = createAsyncThunk(
  "credit/getImages",
  async ({ id, item, inc }, thunkAPI) => {
    try {
      let count = 1;
      let result = [];
      const pc = item.product_code;
      while (true) {
        const image = await apiGet.getImage({ id, pc, inc, count });
        if (image.result || count === 6) {
          break;
        } else {
          result.push({
            id: id + "_" + pc + "_" + inc + "_" + count,
            key: pc,
            sequence: inc,
            image: URL.createObjectURL(image.data),
          });
          count += 1;
        }
      }

      return {
        images: result,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Issue loading images (Try reloading your page).",
      });
    }
  }
);

export const doConfirmationAction = createAsyncThunk(
  "credit/doConfirmationAction",
  async ({ email, method, credit_memo_num, use_rga }, thunkAPI) => {
    try {
      const response = await apiGet.doConfirmationAction({
        email,
        method,
        credit_memo_num,
        use_rga,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error sending the confirmation.",
      });
    }
  }
);

export const getCreditsByFilter = createAsyncThunk(
  "credit/creditsByFilter",
  async (
    {
      credit_memo_num,
      ref_invoice,
      return_address,
      to,
      from,
      status,
      selected,
      customer_num,
      page,
      order_by,
      sort_order,
    },
    thunkAPI
  ) => {
    try {
      const response = await apiGet.getCredits({
        credit_memo_num,
        ref_invoice,
        return_address,
        customer_num,
        to,
        from,
        status,
        selected,
        page,
        order_by,
        sort_order,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading the credits.",
      });
    }
  }
);

/**
 * Function to get the data of a customer
 * @param {number} id - id of the customer
 */
export const getCustomerByIdCredits = createAsyncThunk(
  "credits/getCustomer",
  async (id, thunkAPI) => {
    try {
      const response = await apiGet.customerById(id);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting a customer by ID.",
      });
    }
  }
);

export const getFreightLineByScac = createAsyncThunk(
  "credits/getCustomer",
  async ({ scac }, thunkAPI) => {
    try {
      const response = await apiGet.getFreightLineByScac({ scac });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting a customer by ID.",
      });
    }
  }
);

export const creditSlice = createSlice({
  name: "creditSlice",
  initialState: {
    credit: { ...defaultCredit },
    creditLoading: false,
    creditTypeString: "Standard Credit",
    creditType: 0,
    displayItems: [],
    creditOrder: undefined,
    problemList: [],
    creditItems: [],
    activeWarehouses: [],
    selectedWarehouse: {
      warehouse_id: 0,
      name: "",
      zip: 0,
    },
    totalWeight: 0,
    freightAmount: 0,
    loadedCredit: undefined,
    creditImages: [],
    freightMinimum: 0,
    require_password: false,
  },
  reducers: {
    resetRedux: (state, action) => {
      state.displayItems = [];
      state.creditOrder = undefined;
      state.problemList = [];
      state.creditItems = [];
      state.freightAmount = 0;
      state.selectedRate = undefined;
      state.selectedWarehouse = undefined;
      state.totalWeight = 0;
      state.ratedOptions = [];
      state.displayItems = [];
    },
    setFreightMin: (state, action) => {
      state.freightMinimum = action.payload;
    },
    updateCredit: (state, action) => {
      state.credit = action.payload;
    },
    setCreditLoading: (state, action) => {
      state.creditLoading = action.payload;
    },
    addCreditItem: (state, action) => {
      //action is a object => defined in LineItem.js
      state.creditItems.push(action.payload);
      state.credit = {
        ...state.credit,
        next_line_num: state.credit.next_line_num + 1,
      };
    },
    removeCreditItem: (state, action) => {
      const possible_items = state.creditItems.filter(
        (item) => item.product_code === action.payload.product_code
      );
      const remove_item = possible_items.pop();
      if (remove_item) {
        state.creditItems = state.creditItems.filter(
          (item) => item != remove_item
        );
      }
    },
    updateCreditItem: (state, action) => {
      let to_update = action.payload;
      const item = state.creditItems.find(
        (item) => item.sequence_num === to_update.sequence_num
      );
      const index = state.creditItems.indexOf(item);
      to_update = {
        ...to_update,
        extended_credit:
          to_update.credit_amount *
            (to_update.percent_allowed / 100) *
            to_update.qty_shipped || 0,
      };
      state.creditItems[index] = to_update;
      state.require_password = false;
      for (let i = 0; i < state.creditItems.length; i++) {
        if (to_update.credit_amount > to_update.sale_price) {
          state.require_password = true;
          break;
        }
      }
    },
    addDescriptionItem: (state, action) => {
      const index = state.creditItems.indexOf(
        state.creditItems.find(
          (item) => item.sequence_num === action.payload.sequence_num
        )
      );
      state.creditItems[index].subitems.push({
        ...defaultCreditItem,
        product_code: "Descript",
        item_type: CREDIT_LINE_TYPE.DESCRIPTION,
        sequence_num: state.credit.next_line_num,
      });

      state.credit.next_line_num += 1;
    },
    removeDescriptionItem: (state, action) => {
      const item = state.creditItems.find(
        (item) => item.sequence_num === action.payload.parent
      );
      const index = state.creditItems.indexOf(item);
      let { subitems } = item;
      state.creditItems[index].subitems = subitems.filter(
        (item) => item.sequence_num !== action.payload.sequence_num
      );
    },
    updateDescriptionItem: (state, action) => {
      const item = state.creditItems.find(
        (item) => item.sequence_num === action.payload.parent
      );
      const index = state.creditItems.indexOf(item);
      let { subitems } = item;
      const subindex = subitems.indexOf(
        subitems.find(
          (item) => item.sequence_num === action.payload.sequence_num
        )
      );
      state.creditItems[index].subitems[subindex] = action.payload.item;
    },
    setSelectedWarehouse: (state, action) => {
      const warehouse = state.activeWarehouses.filter((warehouse) => {
        return warehouse.warehouse_id === action.payload;
      });

      state.selectedWarehouse = warehouse[0];
    },
    setTotalWeight: (state, action) => {
      state.totalWeight = action.payload;
    },
    updateImageRedux: (state, action) => {
      const id = action.payload.id;
      const file = action.payload.file;
      const filtered_image = state.creditImages.filter(
        (image) => image.id === id
      )[0];
      const index = state.creditImages.indexOf(filtered_image);
      state.creditImages[index].image = file;
    },
    setCreditImages: (state, action) => {
      state.creditImages = action.payload;
    },
    removeImagesByKey: (state, action) => {
      const id = action.payload.id;
      if (id) {
        state.creditImages = state.creditImages.filter(
          (image) => image.id !== id
        );
      }
    },
    addNonSale: (state, action) => {
      const non_sale = {
        ...defaultCreditItem,
        sequence_num: state.credit.next_line_num,
        product_code: "Non-Sale",
        item_type: CREDIT_LINE_TYPE.NON_SALE,
      };
      state.credit.next_line_num += 1;
      state.displayItems = [...state.displayItems, non_sale];
      state.creditItems = [...state.creditItems, non_sale];
    },
    removeNonSale: (state, action) => {
      const key = parseFloat(action.payload.sequence_num);
      state.displayItems = state.displayItems.filter(
        (item) => parseFloat(item.sequence_num) !== key
      );
      state.creditItems = state.creditItems.filter(
        (item) => parseFloat(item.sequence_num) !== key
      );
    },
    updateNonSale: (state, action) => {
      const key = parseFloat(action.payload.key);
      const item = action.payload.item;
      const display_item = state.displayItems
        .filter((item) => parseFloat(item.key) === key)
        .pop();
      const display_index = state.displayItems.indexOf(display_item);
      state.displayItems[display_index] = item;

      const credit_item = state.creditItems
        .filter((item) => parseFloat(item.key) === key)
        .pop();
      const credit_index = state.creditItems.indexOf(credit_item);
      state.creditItems[credit_index] = item;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCredit.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(createCredit.fulfilled, (state, action) => {
        state.creditLoading = false;
        state.credit.credit_memo_num = action.payload.credit_memo_num;
      })
      .addCase(createCredit.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(getCredit.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(getCredit.fulfilled, (state, action) => {
        state.creditLoading = false;
        const { line_items, ...credit } = action.payload.credit;
        let items = [];
        for (let i = 0; i < line_items.length; i++) {
          if (line_items[i].item.item_type === CREDIT_LINE_TYPE.NON_SALE) {
            state.displayItems = [
              ...state.displayItems,
              { ...line_items[i].item },
            ];
          }
          items.push({
            ...line_items[i].item,
            subitems: line_items[i].subitems || [],
          });
        }
        state.creditItems = items;
        state.credit = credit;

        state.require_password = false;
        for (let i = 0; i < state.creditItems.length; i++) {
          if (
            state.creditItems[i].credit_amount > state.creditItems[i].sale_price
          ) {
            state.require_password = true;
            break;
          }
        }
      })
      .addCase(getCredit.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(checkDuplicateCredit.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(checkDuplicateCredit.fulfilled, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(checkDuplicateCredit.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(deleteCredit.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(deleteCredit.fulfilled, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(deleteCredit.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(getInitialOrder.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(getInitialOrder.fulfilled, (state, action) => {
        state.creditLoading = false;
        const order = action.payload;
        const items = action.payload.line_items;
        state.displayItems = [];
        if (state.credit.credit_memo_num === 0) {
          state.credit = {
            ...state.credit,
            apply_to_po: order.customer_po,
            customer: order.customer,
            warehouse: order.warehouse_id,
            ref_invoice: order.customer_invoice_num,
          };
          if (order.freight.is_drop_ship) {
            const { ship_to_name, address } = order.freight.drop_ship_address;
            state.credit = {
              ...state.credit,
              return_customer_name: ship_to_name,
              return_street: address.street,
              return_route: address.route,
              return_city: address.city,
              return_state: address.state,
              return_zip: address.zip_code,
            };
          }
        }

        state.creditOrder = order;

        items.forEach((mast_item) => {
          const curr_item = mast_item.item;
          const {
            id,
            item_price,
            price,
            product,
            subitems,
            line_num,
            weight,
            qty_shipped,
          } = curr_item;

          state.displayItems.push({
            ...defaultCreditItem,
            product_code: product.product_code,
            description: product.description,
            id,
            sequence_num: line_num,
            weight: weight,
            qty_shipped: qty_shipped,
            sale_price: item_price,
            price,
          });

          if (Array.isArray(subitems)) {
            subitems.forEach((curr_subitem) => {
              if (
                ![ORDER_ITEM_DESCRIPTION_TYPE, ORDER_ITEM_ADDON_TYPE].includes(
                  curr_subitem.item_type
                )
              ) {
                const { id, item_price, price, product } = curr_subitem;

                state.displayItems.push({
                  ...defaultCreditItem,
                  product_code: product.product_code,
                  description: product.description,
                  id,
                  sale_price: item_price,
                  price,
                });
              }
            });
          }
        });
      })
      .addCase(getInitialOrder.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(getProblemList.fulfilled, (state, action) => {
        state.problemList = action.payload;
      })
      .addCase(getActiveWarehouses.fulfilled, (state, action) => {
        state.activeWarehouses = action.payload;

        state.selectedWarehouse = state.activeWarehouses[0];
      })
      .addCase(getImages.fulfilled, (state, action) => {
        const newImages = action.payload.images;
        let res = [...state.creditImages];
        for (let i = 0; i < newImages.length; i++) {
          const find = res.filter((image) => image.id === newImages[i].id);
          if (!(find.length > 0)) {
            res.push(newImages[i]);
          }
        }
        state.creditImages = [...res];
      })
      .addCase(doConfirmationAction.fulfilled, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(doConfirmationAction.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(doConfirmationAction.rejected, (state, action) => {
        state.creditLoading = false;
      });
  },
});

export const {
  setCreditLoading,
  setCreditTypeString,
  addCreditItem,
  removeCreditItem,
  addDescriptionItem,
  removeDescriptionItem,
  updateDescriptionItem,
  resetRedux,
  updateCredit,
  setSelectedWarehouse,
  setTotalWeight,
  setDropShip,
  updateImageRedux,
  addUsedNum,
  findNextNum,
  removeImagesByKey,
  setCreditImages,
  setFreightMin,
  updateCreditItem,
  addNonSale,
  removeNonSale,
  updateNonSale,
} = creditSlice.actions;

export default creditSlice.reducer;

import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import Claims from "../views/Claims/Claims";
import { Alert } from "@mui/material";
import CreditEntry from "../views/Credits/CreditEntry";
import CreditSearch from "../views/Credits/CreditSearch";
import ClaimSearch from "../views/Claims/ClaimSearch";

function CustomerCredits(props) {
  return (
    <>
      {props.groups.includes(props.creditGroup) ? (
        <Container className="">
          <Routes>
            {/* <Route path={""} element={<Claims />} /> */}
            <Route path={""} element={<CreditEntry />} />
            <Route path={"/credit/:id"} element={<CreditEntry />} />
            <Route path={"/credit-search"} element={<CreditSearch />} />
            <Route path={"/claim-search"} element={<ClaimSearch />} />
            <Route path={"/warranty-claim"} element={<Claims />} />
            <Route path={"/warranty-claim/:id"} element={<Claims />} />
          </Routes>
        </Container>
      ) : (
        <Container>
          {props.groups.length > 0 && (
            <span>
              <Alert severity="error" variant="outlined" className="mt-5">
                You are not allowed to view this page. If you believe you are
                seeing this in error, please contact a system administrator
              </Alert>
              <a href="/">Back to home</a>
            </span>
          )}
        </Container>
      )}
      <div style={{ float: "right", marginRight: "1%" }}>
        <b>v0.8.3</b>
      </div>
    </>
  );
}

export default CustomerCredits;

import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import {
  ADJUSTMENT_CREDIT,
  CREDIT_LINE_TYPE,
  REBILL_CREDIT,
  STANDARD_CREDIT,
} from "../../../../helpers/const";
import { blue, red } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  addDescriptionItem,
  updateCreditItem,
} from "../../../../store/slices/Credits/CreditSlice";
import ImageUpload from "../ImageUpload";
import DescriptionCreditLine from "./DescriptionLineItem";

const CreditLine = ({
  item,
  totalReturn,
  maxAllowedQty,
  maxAllowedPrice,
  disabled,
}) => {
  const { credit, problemList } = useSelector((state) => state.credits);

  const dispatch = useDispatch();

  // Item managers
  const handleProblemChange = (event) => {
    const val = parseFloat(event.target.textContent.split(" ")[0]);

    const tempProb = problemList.find((prob) => prob.problem_num === val);
    if (tempProb) {
      dispatch(
        updateCreditItem({
          ...item,
          added_to_stock: tempProb.stock_item,
          manufacturer_defect: tempProb.manufacturer_defect,
          problem_num: val,
        })
      );
    }
  };

  const handlePercentChange = (event) => {
    let value = event.target.value || "0";

    let val = parseFloat(value.replace(/[^0-9.]/g, ""));
    if (val > 100) {
      val = 100;
    } else if (val < 0) {
      val = 0;
    }
    dispatch(
      updateCreditItem({
        ...item,
        percent_allowed: val,
      })
    );
  };

  const handleCommentChange = (event) => {
    dispatch(
      updateCreditItem({
        ...item,
        comments: event.target.value,
      })
    );
  };

  const handleReturnChange = (event) => {
    let val = parseInt(event.target.value.replace(/\D/g, ""));

    const this_return = totalReturn - item.qty_shipped;
    if (val && val + this_return > maxAllowedQty) {
      val = maxAllowedQty - this_return;
    }

    dispatch(
      updateCreditItem({
        ...item,
        qty_shipped: val || 0,
      })
    );
  };

  const handleCreditAmountChange = (event) => {
    dispatch(
      updateCreditItem({
        ...item,
        credit_amount: parseFloat(event.target.value),
      })
    );
  };

  const handleDefectChange = (event) => {
    dispatch(
      updateCreditItem({
        ...item,
        manufacturer_defect: event.target.checked ? "Y" : "N",
      })
    );
  };

  const handleAddedToStockChange = (event) => {
    dispatch(
      updateCreditItem({
        ...item,
        added_to_stock: event.target.checked ? "Y" : "N",
      })
    );
  };

  const handleInHouseChange = (event) => {
    dispatch(
      updateCreditItem({
        ...item,
        in_house: event.target.checked ? "Y" : "N",
      })
    );
  };

  const handleSerialNumChange = (event) => {
    dispatch(
      updateCreditItem({
        ...item,
        serial_num: event.target.value,
      })
    );
  };

  const appendDescriptionItem = (event) => {
    dispatch(addDescriptionItem(item));
  };

  const problemProps = {
    options: problemList,
    getOptionLabel: (option) => option.problem_num + " - " + option.description,
  };

  return (
    <>
      <div className="credit-item-dropdown">
        <Row>
          <Col>
            <ImageUpload
              disabled={disabled}
              key={item.product_code}
              itemKey={item.product_code}
              sequence={item.sequence_num}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-5">
            <b>Total Credit: </b> {item.extended_credit.toFixed(2)}
          </Col>
          <Col>
            <div className="collapse-item" onClick={appendDescriptionItem}>
              + Add Description
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="info-header">Percent Allowed</div>
            <TextField
              disabled={disabled}
              value={item.percent_allowed}
              helperText="Maximum Allowed: 100.00"
              onChange={handlePercentChange}
            ></TextField>
          </Col>
          <Col
            className={
              [STANDARD_CREDIT.id, REBILL_CREDIT.id].includes(
                credit.credit_type
              )
                ? ""
                : "hidden "
            }
          >
            <div className="info-header">Credit Qty</div>
            <TextField
              disabled={disabled}
              value={item.qty_shipped || ""}
              helperText={"Maximum Allowed: " + (maxAllowedQty - totalReturn)}
              onChange={handleReturnChange}
            ></TextField>
          </Col>
          <Col
            className={
              [ADJUSTMENT_CREDIT.id, STANDARD_CREDIT.id].includes(
                credit.credit_type
              )
                ? ""
                : "hidden "
            }
          >
            <div className="info-header">New Item Price</div>
            <TextField
              disabled={disabled}
              value={item.credit_amount}
              type="number"
              helperText={"Order Item Price: " + maxAllowedPrice}
              onChange={handleCreditAmountChange}
            ></TextField>
          </Col>

          <Col
            className={
              "g-line" +
              (credit.credit_type === STANDARD_CREDIT.id ? "" : "hidden ")
            }
          >
            <FormGroup className="mt-5">
              <FormControlLabel
                onChange={handleInHouseChange}
                label="In House"
                checked={item.in_house === "Y" ? true : false}
                control={
                  <Checkbox
                    disabled={disabled}
                    sx={{
                      color: red[800],
                      "&.Mui-checked": {
                        color: blue[600],
                      },
                    }}
                  />
                }
              />
            </FormGroup>
          </Col>
          <Col
            className={
              credit.credit_type === STANDARD_CREDIT.id ? "" : "hidden "
            }
          >
            <FormGroup className="mt-5">
              <FormControlLabel
                onChange={handleAddedToStockChange}
                label="Added To Stock"
                checked={item.added_to_stock === "Y" ? true : false}
                control={
                  <Checkbox
                    disabled={disabled}
                    sx={{
                      color: red[800],
                      "&.Mui-checked": {
                        color: blue[600],
                      },
                    }}
                  />
                }
              />
            </FormGroup>
          </Col>
          <Col
            className={
              credit.credit_type === STANDARD_CREDIT.id ? "" : "hidden "
            }
          >
            <FormGroup className="mt-5">
              <FormControlLabel
                onChange={handleDefectChange}
                label="Manufacturer Defect"
                checked={item.manufacturer_defect === "Y" ? true : false}
                control={
                  <Checkbox
                    disabled={disabled}
                    sx={{
                      color: red[800],
                      "&.Mui-checked": {
                        color: blue[600],
                      },
                    }}
                  />
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="credit-comments">
            <div className="info-header">Serial Number</div>
            <TextField
              disabled={disabled}
              inputProps={{ maxLength: 20 }}
              value={item.serial_num}
              className="problems"
              onChange={handleSerialNumChange}
              helperText={
                "Characters Remaining: " +
                (20 - item.serial_num.length) +
                "/" +
                20
              }
            ></TextField>
          </Col>
        </Row>
        <Row>
          <Col className="credit-comments">
            <div className="info-header">Comments</div>
            <TextField
              disabled={disabled}
              inputProps={{ maxLength: 80 }}
              value={item.comments}
              className="problems"
              onChange={handleCommentChange}
              helperText={
                "Characters Remaining: " +
                (80 - item.comments.length) +
                "/" +
                80
              }
            ></TextField>
          </Col>
          <Col>
            <div className="info-header">Problems</div>
            <Autocomplete
              disabled={disabled}
              disableClearable
              {...problemProps}
              className="autocomplete-input-credit"
              onChange={handleProblemChange}
              value={problemList.find(
                (prob) => prob.problem_num === item.problem_num
              )}
              isOptionEqualToValue={(option, value) =>
                option.problem_num === value.problem_num
              }
              renderInput={(params) => (
                <TextField
                  className="autocomplete-problems"
                  {...params}
                  variant="standard"
                />
              )}
            />
          </Col>
        </Row>
        <Row className="footnote">
          {item.type === CREDIT_LINE_TYPE.NORMAL && <i>Normal Item</i>}
          {item.type === CREDIT_LINE_TYPE.MOUNTIONG_CHARGE && (
            <i>Mounting Charge</i>
          )}
          {item.type === CREDIT_LINE_TYPE.SPECIALTY && <i>Specialty Item</i>}
        </Row>
      </div>
      <div>
        {item.subitems.map((subitem, index) => {
          return (
            <DescriptionCreditLine
              key={index}
              disabled={disabled}
              subitem={subitem}
              parentSequence={item.sequence_num}
            />
          );
        })}
      </div>
    </>
  );
};

export default CreditLine;

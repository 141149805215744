import axios from "axios";
import { endpoints } from "./endpoints";
import {
  CREDENTIAL_ISSUE,
  SERVER_GENERAL_SERVER_ERRROR,
  ERR_NETWORK,
} from "../helpers/const";
import { makeQueryParams } from "../helpers/helperFunctions";

export const apiGet = {
  test: getTestPayload,
  authToken: getAuthenticationToken,
  checkLogin: getCheckLogin,
  logOut,
  customerList: getCustomerList,
  masterOrderById: getMasterOrderById,
  subOrderById: getSubOrderById,
  customerById: getCustomerById,
  customerAccountBalance: getCustomerAccountBalance,
  customerCreditCheck: getCustomerCreditCheck,
  itemsById: getItemsById,
  productList: getProductList,
  productById: getProductById,
  getItemSubstitutions: getItemSubstitutions,
  productSearchById: getProductSearchById,
  productCodes: getProductCodes,
  productPriceById: getProductPriceById,
  productInitialPriceById: getProductInitialPriceById,
  creditCheck: getCreditError,
  freightLineList: getFreightLineList,
  getGroups: getGroups,
  getFreightLineById,
  ordersList: getOrdersList,
  quoteSearchById: getQuotesList,
  warehouseList: getWarehouseList,
  marginError: getMarginError,
  manufacturedItems: getManufacturedItems,
  warehouseById: getWarehouseById,
  customerRecentOrders: getCustomerRecentOrders,
  customerRecentQuotes: getCustomerRecentQuotes,
  customerVendorDiscounts: getCustomerVendorDiscounts,
  discountPrograms: getDiscountPrograms,
  discountSchedulesForProduct: getDiscountSchedulesForProduct,
  dropShipAddresses: getDropShipAddresses,
  checkDuplicate: getDuplicateError,
  getQuote,
  checkBackordersProcess,
  rateLTLOptions: getRateLTLResults,
  verifyFreightDetails,
  applyShippingRules,
  getSentToParams,
  getStateFromZipCode,
  calculateCcCharge,
  xrefProducts: getXrefProducts,
  getBolList,
  getBolById,
  subOrderItems: getSubOrderItems,
  SuggestedOrderReport: getSuggestedOrderReport,
  invoices: getInvoice,
  hasCustomerNum: defaultCustomerNum,
  allCustomerEmails: getCustomerEmails,
  duplicateCredit: checkDuplicateCredit,
  orderByInvoice: getOrderByInvoice,
  problemList: getAllProblems,
  activeWarehouses: getActiveWarehouses,
  getCredit: getCredit,
  getImage,
  doConfirmationAction,
  getCredits: getCreditsByFilter,
  checkDuplicateClaims,
  getClaimByClaimNumber,
  getClaims: getClaimsBYFilter,
  getClaimImages,
  getInvoices: getInvoicesByFilter,
  sendClaimSummary,
  lastPrice: getCustomerRecentPrice,
  getPopularNow,
  getFreightLineByScac,
};

// *************  API functions  *************

function getTestPayload() {
  console.log("getTestPayload");
  return axios
    .get(`${endpoints.TESTAPI_URL}/2`)
    .then((res) => {
      console.log(res.data.title);
      return res.data.title;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

async function getAuthenticationToken() {
  try {
    const loginURL = endpoints.MITCO_API_URL + endpoints.USER_LOGIN;
    const response = await axios.get(loginURL, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data?.csrfmiddlewaretoken?.initial_value;
  } catch (error) {
    let message = error.code === "ERR_NETWORK" ? ERR_NETWORK : error.message;
    throw new Error(message);
  }
}

async function logOut() {
  try {
    await axios.get(endpoints.MITCO_API_URL + endpoints.USER_LOGOUT, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    });
  } catch (error) {
    throw new Error(error.message);
  }
}

async function getCustomerList(param) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${
      endpoints.CUSTOMER_LIST
    }${makeQueryParams(param)}`,
    headers: {
      Accept: "application/json",
    },
  });
  return response;
}

async function getItemSubstitutions(
  product_code,
  discountSchedule,
  priceOverride,
  customerId,
  itemType,
  priceType,
  subOrderId,
  company,
  whId,
  substitution
) {
  var data_set = {
    product_code: product_code,
  };

  if (discountSchedule) {
    data_set = { ...data_set, discount_schedule: discountSchedule };
  }
  if (priceOverride) {
    data_set = { ...data_set, price_override: priceOverride };
  }
  if (customerId && customerId > 0) {
    data_set = { ...data_set, customer_id: customerId };
  }

  if (typeof itemType === "number") {
    data_set = { ...data_set, item_type: itemType };
  }
  if (subOrderId && subOrderId > 0) {
    data_set = { ...data_set, suborder_id: subOrderId };
  }
  if (company) {
    data_set = { ...data_set, company: company };
  }
  if (whId || whId === 0) {
    data_set = { ...data_set, warehouse_id: whId };
  }

  if (substitution) {
    data_set = { ...data_set, substitution: substitution };
  }

  data_set = { ...data_set, price_type: priceType };
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1" + endpoints.CSA_GET_ITEM_SUBS,
    headers: {
      accept: "application/json",
    },
    params: data_set,
  });

  return response.data;
}

async function getManufacturedItems(product_code) {
  var queryParams = {
    product_code: product_code,
  };

  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.CSA_MANUFACTURE +
      endpoints.CSA_GET_MANU_ITEMS,
    headers: {
      accept: "application/json",
    },
    params: queryParams,
  });

  return response.data;
}

async function getCustomerById(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1" + endpoints.CUSTOMER_READ + `${id}/`,
    headers: {
      Accept: "application/json",
    },
  });
  return response;
}

async function getGroups() {
  try {
    const response = await axios.get(
      endpoints.MITCO_API_URL + "/v1/csa/groups/get_groups/",
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    let message = "";
    if (error?.request?.status === 403) {
      message = CREDENTIAL_ISSUE;
    } else {
      message = SERVER_GENERAL_SERVER_ERRROR;
    }
    if (error.code === "ERR_NETWORK") {
      message = ERR_NETWORK;
    }
    throw new Error(message);
  }
}

async function getCheckLogin() {
  try {
    const response = await axios.get(
      endpoints.MITCO_API_URL + "/users/check_login/",
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    let message = "";
    if (error?.request?.status === 403) {
      message = CREDENTIAL_ISSUE;
    } else {
      message = SERVER_GENERAL_SERVER_ERRROR;
    }
    if (error.code === "ERR_NETWORK") {
      message = ERR_NETWORK;
    }
    throw new Error(message);
  }
}

async function getMasterOrderById(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    // xsrfCookieName: 'sessionid',
    url: endpoints.MITCO_API_URL + "/v1" + endpoints.CSA_ORDER_READ + id + "/",
    headers: {
      Accept: "application/json",
    },
  }).catch((error) =>
    console.log(
      "getMasterOrderById() error.response.data: ",
      error.response.data
    )
  );

  return response;
}

async function getSubOrderById(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    // xsrfCookieName: 'sessionid',
    url: endpoints.MITCO_API_URL + "/v1" + endpoints.SUB_ORDER_READ + id + "/",
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getCustomerAccountBalance(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.CUSTOMER_READ +
      id +
      endpoints.CUSTOMER_ACCOUNT_BALANCE,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getCustomerCreditCheck({ customerId, subOrderId }) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${endpoints.CUSTOMER_READ}${customerId}${
      endpoints.CUSTOMER_CHECK_CREDIT
    }${subOrderId ? `?suborder_id=${subOrderId}` : ""}`,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getItemsById(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1" + endpoints.ITEMS_READ + id + "/",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      console.log("getItemsById", response.data);
      return response.data;
    })
    .catch((error) =>
      console.log("getItemsById error.response.data: ", error.response.data)
    );
  return response;
}

async function getOrdersList({
  company = "mitco",
  customerOrderNum,
  customerNum,
  customerName,
  customerPo,
  dropShipAddress,
  from,
  to,
  orderStatus,
  orderBy,
  sortOrder,
  strictMatch = false,
  page,
}) {
  const parameters = makeQueryParams({
    company,
    suborder_num: customerOrderNum,
    customer_num: customerNum,
    customer_name: customerName,
    drop_ship: dropShipAddress,
    customer_po: customerPo,
    from,
    to,
    status: orderStatus,
    order_by: orderBy,
    sort_order: sortOrder,
    strict_match: strictMatch,
    page,
  });

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${endpoints.SUB_ORDER_READ}${parameters}`,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getProductList(page) {
  try {
    const response = await axios({
      method: "get",
      withCredentials: true,
      url:
        endpoints.MITCO_API_URL +
        "/v1" +
        endpoints.CSA_PRODUCT +
        "?page=" +
        page,
      headers: {
        Accept: "application/json",
      },
    });
    console.log("get products: ", response.data.results);
    return response.data.results;
  } catch (error) {
    console.log("error getting products: ", error);
  }
}
// UNTESTED
async function getProductById(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1" + endpoints.CSA_PRODUCT + id + "/",
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getProductPriceById(
  id,
  discountSchedule,
  priceOverride,
  customerId,
  itemType,
  priceType,
  subOrderId,
  company,
  whId,
  substitution
) {
  // prepares query parameters for axios params
  var queryParams = {};
  if (discountSchedule) {
    queryParams = { ...queryParams, discount_schedule: discountSchedule };
  }
  if (priceOverride) {
    queryParams = { ...queryParams, price_override: priceOverride };
  }
  if (customerId && customerId > 0) {
    queryParams = { ...queryParams, customer_id: customerId };
  }

  if (typeof itemType === "number") {
    queryParams = { ...queryParams, item_type: itemType };
  }
  if (subOrderId && subOrderId > 0) {
    queryParams = { ...queryParams, suborder_id: subOrderId };
  }
  if (company) {
    queryParams = { ...queryParams, company: company };
  }
  if (whId || whId === 0) {
    queryParams = { ...queryParams, warehouse_id: whId };
  }
  if (substitution) {
    queryParams = { ...queryParams, substitution: substitution };
  }

  queryParams = { ...queryParams, price_type: priceType };

  let url =
    endpoints.MITCO_API_URL +
    "/v1" +
    endpoints.CSA_PRODUCT +
    id +
    endpoints.CSA_PRODUCT_PRICE;

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: url,
    params: queryParams,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getMarginError(customer_num) {
  const dataObject = {
    customer_order_num: customer_num,
  };
  let url =
    endpoints.MITCO_API_URL +
    "/v1" +
    endpoints.CSA_MARGIN +
    endpoints.CSA_CHECK_MARGIN;

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: url,
    params: dataObject,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getCreditError(customer_num) {
  const dataObject = {
    customer_order_num: customer_num,
  };

  let url =
    endpoints.MITCO_API_URL +
    "/v1" +
    endpoints.CSA_CREDIT +
    endpoints.CSA_CHECK_CREDIT;

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: url,
    params: dataObject,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getDuplicateError(customer_num, customer_po) {
  const dataObject = {
    customer_order_num: customer_num,
    customer_po: customer_po,
  };

  let url =
    endpoints.MITCO_API_URL +
    "/v1" +
    endpoints.CSA_DUPLICATE +
    endpoints.CSA_CHECK_DUPLICATE;

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: url,
    params: dataObject,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getProductInitialPriceById(
  id,
  customerId,
  itemType,
  subOrderId,
  priceType,
  substitution,
  price
) {
  // prepares query parameters for axios params
  var queryParams = {};
  if (customerId && customerId > 0) {
    queryParams = { ...queryParams, customer_id: customerId };
  }
  if (typeof itemType === "number") {
    queryParams = { ...queryParams, item_type: itemType };
  }
  if (subOrderId && subOrderId > 0) {
    queryParams = { ...queryParams, suborder_id: subOrderId };
  }

  if (priceType) {
    queryParams = { ...queryParams, price_type: priceType };
  }

  if (substitution && substitution !== -1) {
    queryParams = { ...queryParams, substitution: substitution };
  }

  if (price && price > 0) {
    queryParams = { ...queryParams, price_override: price };
  }

  let url =
    endpoints.MITCO_API_URL +
    "/v1" +
    endpoints.CSA_PRODUCT +
    id +
    endpoints.CSA_PRODUCT_PRICE;

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: url,
    params: queryParams,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getProductCodes(product_code) {
  let data_obj = {
    product_code: product_code,
  };
  const response = await axios({
    method: "get",
    withCredentials: true,
    // xsrfCookieName: 'sessionid',
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.CSA_PRODUCT +
      +"?product_code=" +
      product_code +
      "/get_product_codes",
    params: data_obj,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getProductSearchById(product_code) {
  let data_obj = {
    product_code: product_code,
  };
  const response = await axios({
    method: "get",
    withCredentials: true,
    // xsrfCookieName: 'sessionid',
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.CSA_PRODUCT +
      +"?product_code=" +
      product_code +
      "/search_product",
    params: data_obj,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}
// async function getCustomerCreditList() {}

// async function getCustomerNetpriceList() {}

// async function getCustomerOrdersList() { }
async function getCustomerRecentOrders(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.CUSTOMER_READ +
      id +
      endpoints.RECENT_ORDERS,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getCustomerRecentPrice({ id, product_code }) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.CUSTOMER_READ +
      id +
      endpoints.MOST_RECENT_PRICE,
    headers: {
      Accept: "application/json",
    },
    params: {
      product_code,
    },
  });
  return response.data;
}

async function getPopularNow({ id }) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.CUSTOMER_READ +
      (id || 0) +
      endpoints.POPULAR_NOW,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getCustomerRecentQuotes(customerId) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1/client/${customerId}/recent_quotes`,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function getCustomerVendorDiscounts({ customerId, page = 1 }) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${endpoints.CUSTOMER_LIST}${customerId}${endpoints.CUSTOMER_VENDOR_DISCOUNTS}?client_pk=${customerId}&page=${page}`,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getDiscountPrograms(customerId) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1/client/${customerId}/discount_programs`,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getDiscountSchedulesForProduct(product_code) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.DISCOUNT_SCHEDULES_FOR_PRODUCTS +
      "?product_id=" +
      product_code,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data.codes;
}

async function getFreightLineList({ page, name, scacCode: scac_code, id }) {
  const parameters = {};
  if (page) parameters.page = page;
  if (name) parameters.name = name;
  if (scac_code) parameters.scac_code = scac_code;
  if (id) parameters.freight_line_num = id;

  const stringified = Object.keys(parameters)
    .map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(parameters[k]);
    })
    .join("&");

  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.FREIGHT_LINE +
      "?" +
      stringified +
      "&order_by=name&sort_order=asc",
    headers: {
      Accept: "application/json",
    },
  });
  return response;
}

async function getFreightLineById(id) {
  try {
    const response = await axios({
      method: "get",
      withCredentials: true,
      url: endpoints.MITCO_API_URL + "/v1" + endpoints.FREIGHT_LINE + id + "/",
      headers: {
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("error getting freight line by id: ", error);
  }
}

async function getDropShipAddresses(
  client,
  company,
  name,
  city,
  route,
  state,
  street,
  zipCode,
  page
) {
  let parameters = `?company=${company}`;

  if (name) parameters += `&name=${name}`;
  if (city) parameters += `&city=${city}`;
  if (route) parameters += `&route=${route}`;
  if (state) parameters += `&state=${state}`;
  if (street) parameters += `&street=${street}`;
  if (zipCode) parameters += `&zip_code=${zipCode}`;
  if (page || page === 0) parameters += `&page=${page + 1}`;

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${endpoints.DROP_SHIP_ADDRESSES.replace(
      "{customer}",
      client
    )}${parameters}`,
    headers: {
      Accept: "application/json",
    },
  });
  if (response?.data?.results) {
    return {
      total: response?.data?.count,
      addresses: response.data.results
        .filter((address) => address.address !== null)
        .map((address) => ({
          id: address.drop_ship_address_id,
          name: address.ship_to_name,
          city: address.address.city,
          route: address.address.route,
          state: address.address.state,
          street: address.address.street,
          zipCode: address.address.zip_code,
          email: address.contact.email,
          fax: address.contact.fax,
          contactName: address.contact.name,
          phone: address.contact.phone,
        })),
    };
  }
  return { total: null, addresses: [] };
}

async function getQuote(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.GET_QUOTE.replace("{quote_num}", id),
    headers: {
      Accept: "application/json",
    },
  });
  if (response?.data) {
    return response.data;
  } else {
    return null;
  }
}

async function getQuotesList({
  quoteId,
  page,
  customerNum,
  customerName,
  company,
  orderBy,
  sortOrder,
  from,
  to,
}) {
  let parameters = `${company ? `?company=${company}` : "?company=mitco"}`;
  parameters += `${quoteId ? `&quote_num=${quoteId}` : ""}`;
  parameters += `${customerNum ? `&customer_num=${customerNum}` : ""}`;
  parameters += `${customerName ? `&customer_name=${customerName}` : ""}`;
  parameters += `${company ? `&company=${company}` : ""}`;
  parameters += `${page ? `&page=${page}` : ""}`;
  parameters += `${orderBy ? `&order_by=${orderBy}` : ""}`;
  parameters += `${sortOrder ? `&sort_order=${sortOrder}` : ""}`;
  parameters += `${from ? `&from=${from}` : ""}`;
  parameters += `${to ? `&to=${to}` : ""}`;

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1/quote/" + parameters,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

/**
 * Get freight LTL options
 * Endpoint: /rateLTL/
 * @param {string} czarZip
 * @param {string} destinationZip
 * @param {number} weight
 * @param {string} freightClass
 * @param {string} billOfLading
 * @param {number} page
 */
// UNTESTED ...
async function getRateLTLResults(
  czarZip,
  destinationZip,
  weight,
  freightClass,
  billOfLading
) {
  // prepares query parameters for axios params
  const queryParams = makeQueryParams({
    shipping_ticket: billOfLading,
    originzip: czarZip,
    destzip: destinationZip,
    weight,
    frtclass: freightClass,
    type: "json",
  });

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${endpoints.RATE_LTL}${queryParams}`,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data?.rated?.map((option, index) => ({
    id: index + 1,
    name: option.NAME,
    net: option.PRICE,
    days: option.DAYS,
    carrier: option.CARRIER,
    carrierQuoteNumber: option.CARRIERQUOTENUM,
    TMSQuoteNumber: option.TMSQUOTEID,
    mode: option.LTL,
  }));
}

async function getWarehouseList(params) {
  try {
    const response = await axios(
      `${endpoints.MITCO_API_URL}/v1${
        endpoints.WAREHOUSE_READ
      }${makeQueryParams(params)}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get Warehouse Information
 * Endpoint: /rateLTL/
 * @param {string} warehouse_id
 */

async function getWarehouseById(id) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${endpoints.WAREHOUSE_READ}${id}/`,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

/**
 * Check the status of the backorders of the items
 * Endpoint: /suborder/{suborder_pk}/backorder_items/
 * @param {number} subOrderId
 */
async function checkBackordersProcess({ subOrderId, productCode, itemType }) {
  let parameters = "?company=mitco";
  if (productCode) {
    parameters += `&product_code=${productCode}`;
  }
  if (itemType || itemType === 0) {
    parameters += `&item_type=${itemType}`;
  }

  const response = await axios({
    method: "get",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      `/v1/suborder/${subOrderId}/backorder_items/${parameters}`,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function verifyFreightDetails({
  company = "mitco",
  customerNum,
  amount,
  freightLineId,
  weight,
}) {
  const parameters = makeQueryParams({
    company,
    amount,
    freight_line_id: freightLineId ? freightLineId : 118,
    weight,
  });

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1/client/${customerNum}/verify_freight_details/${parameters}`,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}
async function applyShippingRules({
  company = "mitco",
  customerNum,
  prepaidCode,
  freightLineId,
  weight,
  subOrderId,
  last_rule,
}) {
  const parameters = makeQueryParams({
    company,
    code: prepaidCode,
    freight_line_id: freightLineId,
    weight,
    order_num: subOrderId,
    last_rule: last_rule,
  });

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1/client/${customerNum}/apply_shipping_rules/${parameters}`,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getSentToParams({ isSubOrder, id }) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1/${
      isSubOrder ? "suborder" : "quote"
    }/${id}/get_send_to_params/?company=mitco`,
    headers: {
      Accept: "application/json",
    },
  });
  return response;
}

async function getStateFromUSZipCode(zipCode) {
  try {
    const response = await axios({
      method: "get",
      url: `https://api.zippopotam.us/us/${zipCode.split("-")[0]}`,
      headers: {
        Accept: "application/json",
      },
    });

    return response.data.places[0]["state abbreviation"];
  } catch (error) {
    console.log("state error", error);
  }
}

async function getStateFromCAZipCode(zipCode) {
  try {
    const response = await axios({
      method: "get",
      url: `https://api.zippopotam.us/ca/${zipCode.split("-")[0]}`,
      headers: {
        Accept: "application/json",
      },
    });

    return response.data.places[0]["state abbreviation"];
  } catch (error) {
    console.log("state error", error);
  }
}

async function getStateFromZipCode(zipCode) {
  try {
    const response =
      (await getStateFromUSZipCode(zipCode)) ||
      (await getStateFromCAZipCode(zipCode));

    return response;
  } catch (error) {
    console.log("state error", error);
  }
}

async function calculateCcCharge({ subTotal, customerId, terms }) {
  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${endpoints.CUSTOMER_LIST}${customerId}/calculate_cc_charge/?company=mitco&subtotal=${subTotal}&terms=${terms}`,
    headers: {
      Accept: "application/json",
    },
  });
  return response;
}

async function getXrefProducts({
  company = "mitco",
  customerId,
  code,
  productCode,
  page,
}) {
  const parameters = makeQueryParams({
    company,
    code,
    product_code: productCode,
    page,
  });

  const response = await axios({
    method: "get",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1${endpoints.CUSTOMER_READ}${customerId}${endpoints.XREF_PRODUCTS}${parameters}`,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getBolList(data) {
  const queryParams = makeQueryParams(data);
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.BOL}${queryParams}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getBolById(id) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.BOL}${id}/`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getSubOrderItems(id) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.SUB_ORDER_READ}${id}/items/`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
}

async function getSuggestedOrderReport(
  vendCat,
  mthNet,
  mthOrder,
  warehouse,
  dropShip,
  database
) {
  const params = {
    vendor_category: vendCat,
    months_net_sales: mthNet,
    months_to_stock: mthOrder,
    warehouse_id: warehouse,
    drop_ship_flag: dropShip,
    database: database,
  };

  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.SUGGESTED_ORDER_REPORT}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "blob",
    params: params,
  });

  return response;
}

async function getInvoice(
  customerNum,
  transactionNum,
  transactionNumType,
  deliveryMethod,
  lowerDate,
  upperDate,
  lowerRange,
  upperRange,
  emailAddress,
  bodyText,
  countQuery,
  doLastBusinessDay,
  useOverride
) {
  const params = {
    customer_num: customerNum,
    type: transactionNumType,
    transaction_num: transactionNum,
    return_method: deliveryMethod,
    text: bodyText,
    recipient_address: deliveryMethod === "email" ? emailAddress : "",
    start_date: lowerDate,
    end_date: upperDate,
    transaction_lower_range: lowerRange,
    transaction_upper_range: upperRange,
    count_query: countQuery,
    do_last_business_day: doLastBusinessDay,
    use_override: useOverride,
  };

  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.INVOICES}`;

  const response =
    deliveryMethod === "file" && !countQuery
      ? await axios.get(url, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
          responseType: "blob",
          params: params,
        })
      : await axios.get(url, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
          params: params,
        });

  return response;
}

async function defaultCustomerNum() {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.DEFAULT_CUSTOMER_NUM}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
  });

  return response;
}

async function getCustomerEmails(customerNum) {
  const params = {
    customer_num: customerNum,
  };

  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CUSTOMER_EMAILS}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
    params: params,
  });

  return response;
}

// Start Credits
async function checkDuplicateCredit(invoiceNum) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CREDIT_BASE + endpoints.CREDIT_DUPLICATE}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
    params: {
      invoice_num: invoiceNum,
    },
  });

  return response;
}

async function getOrderByInvoice({ transaction_num, type }) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CREDIT_BASE + endpoints.CREDIT_INITIAL_ORDER}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
    params: { transaction_num, type },
  });

  return response;
}

async function getAllProblems() {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CREDIT_BASE + endpoints.CREDIT_PROBLEMS_ALL}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
  });

  return response;
}

async function getActiveWarehouses() {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CSA_WAREHOUSE + endpoints.ACTIVE_WAREHOUSES}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
  });

  return response;
}

async function getCredit(credit_memo_num) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CREDIT_BASE + credit_memo_num + "/"}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
    params: {
      credit_memo_num,
    },
  });
  return response;
}

async function getImage({ id, pc, inc, count }) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.IMAGE_BASE + endpoints.IMAGE_GET}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "blob",
    params: {
      image_name: id,
      product_code: pc,
      increment: inc,
      count,
    },
  });
  if (response.data.type === "image/jpeg") {
    return response;
  } else {
    let data = {
      result: "loading_complete",
    };
    return data;
  }
}

async function doConfirmationAction({
  method,
  email,
  credit_memo_num,
  use_rga,
}) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CREDIT_BASE + endpoints.CREDIT_PRINT}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
    params: {
      method,
      email,
      credit_memo_num,
      use_rga,
    },
  });
  return response;
}

async function getClaimsBYFilter({
  credit_memo_num,
  claim_num,
  ref_invoice,
  to,
  status,
  from,
  selected,
  page,
  order_by,
  sort_order,
  show_deleted,
}) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CLAIM_BASE}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
    params: {
      credit_memo_num,
      claim_num,
      ref_invoice,
      to,
      status,
      from,
      selected,
      page,
      order_by,
      sort_order,
      show_deleted,
    },
  });

  return response;
}

async function getCreditsByFilter({
  credit_memo_num,
  ref_invoice,
  return_address,
  to,
  from,
  status,
  customer_num,
  selected,
  page,
  order_by,
  sort_order,
}) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CREDIT_BASE}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "json",
    params: {
      credit_memo_num,
      ref_invoice,
      return_address,
      to,
      from,
      customer_num,
      status,
      selected,
      page,
      order_by,
      sort_order,
    },
  });

  return response;
}

//start claims
async function checkDuplicateClaims({ ref_invoice }) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CLAIM_BASE + endpoints.CLAIM_DUPLICATE}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      accept: "application/json",
    },
    responseType: "json",
    params: { ref_invoice },
  });

  return response;
}

async function getClaimByClaimNumber(claim_num) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CLAIM_BASE + endpoints.CLAIM_GET}`;
  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      accept: "application/json",
    },
    responseType: "json",
    params: {
      claim_num,
    },
  });

  return response;
}

async function getClaimImages({ item_keys, claim_num }) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CLAIM_BASE + endpoints.CLAIM_IMAGE_GET}`;

  const data = {
    item_keys,
    claim_num,
  };

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "blob",
    params: data,
  });

  return response;
}

async function getInvoicesByFilter({
  invoice_num,
  customer_num,
  ref_number,
  customer_po,
  search,
  to,
  from,
  page,
  order_by,
  sort_order,
}) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.INVOICE_BASE}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "application/json",
    params: {
      invoice_num,
      customer_num,
      ref_number,
      customer_po,
      search,
      to,
      from,
      page,
      order_by,
      sort_order,
    },
  });

  return response;
}

async function sendClaimSummary({ claim_num, method, email }) {
  const data = { claim_num, method, email };

  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CLAIM_BASE + endpoints.CLAIM_SUMMARY}`;

  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "application/json",
    params: data,
  });

  return response;
}

async function getFreightLineByScac({ scac }) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.FREIGHT_LINE + endpoints.FREIGHT_LINE_BY_SCAC}`;
  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: "application/json",
    params: { scac },
  });

  return response;
}

import { Card, Col, Row } from "react-bootstrap";
import { CREDIT_REASON, STANDARD_CREDIT } from "../../../helpers/const";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateCredit } from "../../../store/slices/Credits/CreditSlice";
import { setModPrice } from "../../../store/slices/FreightRater/FreightRaterSlice";

/**
 *
 * @param show boolean that is toggled in parent to display or hide this component
 * @param disabled boolean defined in parent that determines if the order is editable or not
 * @param creditType type of CREDIT_TYPE use for conditional rendering to simplify UI
 *
 * @returns
 */
const CreditInputFields = ({ show, disabled }) => {
  const { creditOrder, activeWarehouses, credit, freightAmount } = useSelector(
    (state) => state.credits
  );
  const dispatch = useDispatch();
  const originalFreight = parseFloat(creditOrder.freight.freight_amount);
  const [minFreight, setMinFreight] = useState(
    credit.freight_amount < 0 ? credit.freight_amount * 2 : originalFreight * -1
  );
  const [handlingMin, setHandlingMin] = useState(
    credit.handling_charge > 0
      ? credit.handling_charge
      : creditOrder.freight.freight_line.handling_charge
  );

  useEffect(() => {
    if (freightAmount) {
      dispatch(setModPrice(freightAmount));
    }
  }, []);
  useEffect(() => {
    dispatch(updateCredit(credit));
  }, [credit]);

  // Possible credit reasons are defined in CONST.js
  const updateCreditReason = (event) => {
    dispatch(
      updateCredit({
        ...credit,
        credit_reason: parseInt(event.target.dataset.optionIndex),
      })
    );
  };

  const updateWarehouse = (event) => {
    dispatch(
      updateCredit({
        ...credit,
        warehouse: parseInt(event.target.innerHTML.split("-")[0].trim()),
      })
    );
  };

  const updateHandling = (event) => {
    let val = event.target.value;

    if (parseFloat(val) > parseFloat(handlingMin)) {
      val = handlingMin;
    }
    dispatch(
      updateCredit({
        ...credit,
        handling_charge: val,
      })
    );
  };

  const updateFreightCredit = (event) => {
    let value = event.target.value;

    if (value > creditOrder.freight.freight_amount) {
      value = creditOrder.freight.freight_amount;
    } else if (value < minFreight) {
      value = minFreight;
    }
    dispatch(setModPrice(value));

    dispatch(
      updateCredit({
        ...credit,
        freight_amount: value,
      })
    );
  };

  const updateCreditCardCharge = (event) => {
    let val = event.target.value;
    if (parseFloat(val) > parseFloat(creditOrder.creditcard_charge)) {
      val = creditOrder.creditcard_charge;
    }
    dispatch(
      updateCredit({
        ...credit,
        creditcard_charge: val,
      })
    );
  };

  const updateSurcharge = (event) => {
    let val = event.target.value;
    if (val > creditOrder.surcharge) {
      val = creditOrder.surcharge;
    }
    dispatch(
      updateCredit({
        ...credit,
        surcharge: val,
      })
    );
  };

  const updateTax = (event) => {
    let val = event.target.value;
    if (val > creditOrder.tax) {
      val = creditOrder.tax;
    }
    dispatch(
      updateCredit({
        ...credit,
        tax: val,
      })
    );
  };

  const creditReasonProps = {
    options: Object.values(CREDIT_REASON),
    getOptionLabel: (option) => option.label,
  };

  const warehouseProps = {
    options: activeWarehouses,
    getOptionLabel: (option) => option.warehouse_id + " - " + option.name,
  };

  return (
    <>
      <Card hidden={!show}>
        <Card.Body>
          <Row className="cust-return">
            <Col>
              <div className="info-header">Credit Reason</div>
              <Autocomplete
                disableClearable
                disabled={disabled}
                {...creditReasonProps}
                className="autocomplete-input-credit"
                value={CREDIT_REASON.find(
                  (reason) => reason.id === credit.credit_reason
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={updateCreditReason}
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
            <Col>
              <div className="info-header">Warehouse</div>
              <Autocomplete
                disableClearable
                disabled={disabled}
                {...warehouseProps}
                className="autocomplete-input-credit"
                value={activeWarehouses.find(
                  (wh) => wh.warehouse_id === credit.warehouse
                )}
                onChange={updateWarehouse}
                isOptionEqualToValue={(option, value) =>
                  option.warehouse_id === value.warehouse_id
                }
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
            <Col>
              <div className="info-header">Freight Credit</div>
              <TextField
                disabled={disabled}
                value={credit.freight_amount}
                onChange={updateFreightCredit}
                type="number"
                helperText={
                  "Min: " + minFreight.toFixed(2) + " Max: " + originalFreight
                }
                inputProps={{
                  maxLength: 6,
                }}
              ></TextField>
            </Col>

            <Col>
              <div className="info-header">Handling Charge</div>
              <TextField
                disabled={disabled}
                value={credit.handling_charge}
                onChange={updateHandling}
                type="number"
                inputProps={{
                  maxLength: 6,
                }}
                helperText={"Maximum Allowed: " + handlingMin}
              ></TextField>
            </Col>
            <Col>
              <div className="info-header">Credit Card Charge</div>
              <TextField
                disabled={disabled}
                value={credit.creditcard_charge}
                inputProps={{
                  maxLength: 6,
                }}
                type="number"
                onChange={updateCreditCardCharge}
                helperText={"Maximum Allowed: " + creditOrder.creditcard_charge}
              ></TextField>
            </Col>
            <Col>
              <div className="info-header">Surcharge</div>
              <TextField
                disabled={disabled}
                value={credit.surcharge}
                inputProps={{
                  maxLength: 6,
                }}
                onChange={updateSurcharge}
                helperText={"Maximum Allowed: " + creditOrder.surcharge}
              ></TextField>
            </Col>
            <Col>
              <div className="info-header">Tax</div>
              <TextField
                disabled={disabled}
                value={credit.tax}
                type="number"
                inputProps={{
                  maxLength: 6,
                }}
                onChange={updateTax}
                helperText={"Maximum Allowed: " + creditOrder.tax}
              ></TextField>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreditInputFields;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Heading from "../Heading/Heading";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { getOrderStatusLabel } from "../../../helpers/helperFunctions";
import { getCustomerRecentOrders } from "../../../store/slices/customer/customerSlice";
import { endpoints } from "../../../api/endpoints";
import { getCreditsByFilter } from "../../../store/slices/Credits/CreditSlice";
import { CREDIT_APPROVAL_STATUS, CREDIT_REASON } from "../../../helpers/const";

const CustomerCredits = () => {
  const [customerCredits, setCustomerCredits] = useState([]);
  const [loading, setLoading] = useState(false);

  // Redux
  const { customerId } = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      dispatch(
        getCreditsByFilter({
          customer_num: customerId,
          order_by: "date_entered",
          sort_order: "dsc",
        })
      ).then((res) => {
        if (!res.error) {
          setCustomerCredits(res.payload.results);
          setLoading(false);
        }
      });
    }
  }, [customerId]);

  return (
    <div className="mt-5">
      <Heading title="Customer Credits" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", minHeight: 200 }}
        >
          <Spinner animation="grow" variant="primary" />
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Memo Number</th>
              <td>Customer PO</td>
              <th>Date Of Credit</th>
              <th>Status</th>
              <th>Total</th>
              <th>Agent</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            {customerCredits?.length > 0 &&
              customerCredits.map((credit, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link
                        to={
                          endpoints.CREDIT_ENTRY +
                          `/credit/${credit.credit_memo_num}`
                        }
                      >
                        {credit.credit_memo_num}
                      </Link>
                    </td>
                    <td>{credit.apply_to_po}</td>
                    <td>{credit.date_entered}</td>
                    <td>
                      {
                        CREDIT_APPROVAL_STATUS.find(
                          (status) => status.id === credit.status
                        ).label
                      }
                    </td>
                    <td>{credit.memo_amount}</td>
                    <td>{credit.entered_by}</td>
                    <td>
                      {
                        CREDIT_REASON.find(
                          (reason) => reason.id === credit.credit_reason
                        ).label
                      }
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default CustomerCredits;

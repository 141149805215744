/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect, useCallback, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { AiFillMinusCircle } from "react-icons/ai";
import FaxEmailTextField from "./FaxEmailTextField";
import FaxEmailDropdown from "./FaxEmailDropdown";

function FaxEmailRecipients({
  control,
  unregister,
  useWatch,
  isSubmitting,
  isSubmitted,
  getValues,
  refElement,
  changeRefElement,
  recipientsList,
  prefillRecipientsList,
  setValue,
}) {
  // Refs
  const deleteButtonsRefs = useRef([]);
  const addButtonsRefs = useRef([]);

  const [recipients, setRecipients] = useState([
    {
      id: 0,
      email: `recipients.${0}.email`,
      fax: `recipients.${0}.fax`,
      name: `recipients.${0}.name`,
      error: false,
    },
  ]);

  const [amountError, setAmountError] = useState(false);

  const recipientsWatch = useWatch({ control, name: "recipients" });

  const addRecipient = (recipientData) => {
    const recipientsValues = getValues("recipients");

    if (
      recipients.length === 1 &&
      !recipientsValues[recipients[0].id].email &&
      !recipientsValues[recipients[0].id].fax &&
      !recipientsValues[recipients[0].id].name
    ) {
      setValue(`recipients.${recipients[0].id}.email`, recipientData.email);
      setValue(`recipients.${recipients[0].id}.fax`, recipientData.fax);
      setValue(`recipients.${recipients[0].id}.name`, recipientData.name);
    } else {
      if (recipients.length < 7) {
        const maxId = Math.max(...recipients.map((o) => o.id));
        setRecipients((prev) => {
          changeRefElement(`recipients.${maxId + 1}.email`);
          return [
            ...prev,
            {
              id: maxId + 1,
              email: `recipients.${maxId + 1}.email`,
              fax: `recipients.${maxId + 1}.fax`,
              name: `recipients.${maxId + 1}.name`,
              error: false,
            },
          ];
        });

        if (recipientData) {
          setValue(`recipients.${maxId + 1}.email`, recipientData.email);
          setValue(`recipients.${maxId + 1}.fax`, recipientData.fax);
          setValue(`recipients.${maxId + 1}.name`, recipientData.name);
        }
      } else {
        setAmountError(true);
      }
    }
  };

  const removeRecipient = (recipientId) => {
    if (recipients.length === 1) {
      setValue(`recipients.${recipients[0].id}.email`, "");
      setValue(`recipients.${recipients[0].id}.fax`, "");
      setValue(`recipients.${recipients[0].id}.name`, "");
    } else {
      const recipientsNewList = recipients.filter((p) => p.id !== recipientId);
      setRecipients(recipientsNewList);
      unregister(`recipients.${recipientId}.email`);
      unregister(`recipients.${recipientId}.fax`);
      unregister(`recipients.${recipientId}.name`);

      changeRefElement(`recipients.${recipientsNewList[0].id}.email`);
    }
  };

  const reValidateData = useCallback(() => {
    const recipientsValues = getValues("recipients");
    if (recipientsValues) {
      const validatedRecipients = recipients.map((recipient) => {
        if (
          !recipientsValues[recipient.id].email &&
          !recipientsValues[recipient.id].fax
        ) {
          return {
            ...recipient,
            error: true,
          };
        }
        return {
          ...recipient,
          error: false,
        };
      });
      setRecipients(validatedRecipients);
    }
  }, [getValues, recipients]);

  const jumpToNextRecipient = (field) => {
    if (!field) {
      const splittedRef = refElement.split(".");
      if (splittedRef[2] === "email") {
        changeRefElement(`recipients.${splittedRef[1]}.fax`);
      }
      if (splittedRef[2] === "fax") {
        changeRefElement(`recipients.${splittedRef[1]}.name`);
      }
      if (splittedRef[2] === "name") {
        if (addButtonsRefs.current[splittedRef[1]]) {
          addButtonsRefs.current[splittedRef[1]].focus();
        }
        if (deleteButtonsRefs.current[splittedRef[1]]) {
          deleteButtonsRefs.current[splittedRef[1]].focus();
        }
      }
    } else {
      changeRefElement(field);
    }
  };

  useEffect(() => {
    if (isSubmitting) {
      reValidateData();
    }
  }, [isSubmitting, reValidateData]);

  useEffect(() => {
    if (isSubmitted && recipientsWatch) {
      reValidateData();
    }
  }, [recipientsWatch, isSubmitted]);

  useEffect(() => {
    if (refElement === "recipients") {
      changeRefElement(`recipients.${recipients[0].id}.email`);
    }
  }, [refElement]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault();
        changeRefElement("billBackAccount");
      }
    };

    if (
      ![
        "subject",
        "billBackAccount",
        "useBlankCoversheet",
        "confirmationEmail",
        "comment",
      ].includes(refElement)
    ) {
      document.addEventListener("keydown", keyDownHandler);
    }
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [refElement]);

  useEffect(() => {
    if (recipientsList.length > 0) {
      const recipientsInputs = [];

      recipientsList.forEach((r, i) => {
        recipientsInputs.push({
          id: i,
          email: `recipients.${i}.email`,
          fax: `recipients.${i}.fax`,
          name: `recipients.${i}.name`,
          error: false,
        });

        setValue(`recipients.${i}.email`, r.email);
        setValue(`recipients.${i}.fax`, r.fax);
        setValue(`recipients.${i}.name`, r.name);
      });

      setRecipients(recipientsInputs);
    }
  }, [recipientsList.length]);

  return (
    <div className="p-5 border border-light">
      <Row>
        <Col md={2}>
          <h3 className="h5 mt-2">Recipients</h3>
        </Col>
        <Col md={8}>
          <FaxEmailDropdown
            options={prefillRecipientsList}
            addRecipient={addRecipient}
          />
        </Col>
      </Row>
      {amountError && (
        <div className="text-form-error">
          You can't add more than 7 recipients
        </div>
      )}
      {recipients.map((r, index) => (
        <Row className="mt-5" key={r.name}>
          <Col md={4}>
            <FaxEmailTextField
              control={control}
              name={r.email}
              label="Email"
              rules={{
                maxLength: {
                  value: 50,
                  message: "This field cannot have more than 50 characters",
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              }}
              refElement={refElement}
              changeRefElement={jumpToNextRecipient}
              externalError={r.error}
              externalErrorMsg="Email Or Fax required"
            />
          </Col>
          <Col md={3}>
            <FaxEmailTextField
              control={control}
              name={r.fax}
              label="Fax"
              rules={{
                validate: (value) =>
                  !value ||
                  value?.replace(/\D/g, "").length <= 10 ||
                  "This field cannot have more than 10 digits",
              }}
              refElement={refElement}
              changeRefElement={jumpToNextRecipient}
              externalError={r.error}
              externalErrorMsg="Email Or Fax required"
            />
          </Col>
          <Col md={4}>
            <FaxEmailTextField
              control={control}
              name={r.name}
              label="Name"
              rules={{
                maxLength: {
                  value: 30,
                  message: "This field cannot have more than 30 characters",
                },
              }}
              refElement={refElement}
              changeRefElement={jumpToNextRecipient}
            />
          </Col>
          <Col md={1} className="d-flex align-items-center">
            <button
              onClick={() => removeRecipient(r.id)}
              type="button"
              className="text-secondary border-0 bg-transparent"
              ref={(el) => (deleteButtonsRefs.current[r.id] = el)}
            >
              <AiFillMinusCircle size="1.5em" />
            </button>
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default memo(FaxEmailRecipients);

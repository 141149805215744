import { memo, useEffect, useState } from "react";
import { Modal, Button, Row, Col, Card, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./DuplicateCreditPopup.scss";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { PENDING, RETURN } from "../../../helpers/const";
import { getAllCustomerEmails } from "../../../store/slices/AccountsReceivable/InvoiceAccessSlice";
import { SetMeal } from "@mui/icons-material";

function SendConfirmationPopup({
  show,
  handleCancelEvent,
  handleConfirmEvent,
  status,
  holdStatus,
  customer,
}) {
  const [email, setCurrentEmail] = useState("");
  const [useRga, setUseRga] = useState(true);
  const [recipientList, setRecipientsList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (customer) {
      const contactEmails = customer.contact?.emails || [];
      const apContactEmails = customer.ap_contact?.emails || [];
      setRecipientsList([...contactEmails, ...apContactEmails]);
    }
  }, [customer]);
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>
            Send Confirmation
            {PENDING.id === parseInt(status) &&
            parseInt(holdStatus) === RETURN.id
              ? " and RGA"
              : ""}
          </b>
        </div>
        {PENDING.id === parseInt(status) &&
          parseInt(holdStatus) === RETURN.id && (
            <FormGroup className="mt-3">
              <FormControlLabel
                id="useRga"
                label="Send With RGA"
                control={
                  <Checkbox
                    defaultChecked={true}
                    onClick={(e) => setUseRga(e.target.checked)}
                  />
                }
              />
            </FormGroup>
          )}
      </Modal.Header>

      <Modal.Body>
        <Card>
          <Card.Title className="mb-4">Email Credit</Card.Title>
          <Card.Body>
            <Row>
              <div style={{ padding: "10px" }}>
                <label
                  htmlFor="email-select"
                  style={{ fontWeight: "bold", marginRight: "10px" }}
                >
                  Select an Email:
                </label>
                <select
                  id="email-select"
                  value={email}
                  onChange={(e) => setCurrentEmail(e.target.value)}
                  style={{
                    padding: "8px",
                    fontSize: "14px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                    float: "right",
                  }}
                >
                  <option value="">-- Choose an email --</option>
                  {recipientList.map((email, index) => (
                    <option key={index} value={email}>
                      📧 {email}
                    </option>
                  ))}
                </select>
              </div>
            </Row>
            <Row>
              <Col style={{ display: "grid", alignItems: "center" }}>
                Email Address:
              </Col>
              <Col style={{ display: "grid", alignItems: "center" }}>
                <TextField
                  value={email}
                  onChange={(e) => setCurrentEmail(e.target.value)}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button
          variant={"primary"}
          onClick={() => handleConfirmEvent("email", email, useRga, true)}
        >
          email
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(SendConfirmationPopup);

import { IconButton, TextField, Tooltip } from "@mui/material";
import { ButtonGroup, Row } from "react-bootstrap";
import { BsArrowReturnRight } from "react-icons/bs";
import "../CreditInput.scss";
import { AiFillDelete } from "react-icons/ai";
import {
  removeDescriptionItem,
  updateDescriptionItem,
} from "../../../../store/slices/Credits/CreditSlice";
import { useDispatch } from "react-redux";

/**
 * All of these props are updated in redux with the exception of disabled
 *
 * @param sequenceNum this is the sequence num associated with each item, it is unique. When we first create an item this number is just a result of Math.random()
 * @param description this just contains the description text of the display item
 * @param parentId this is the sequence num of the parent
 * @param disabled this determines whether the order is editable or not as detemined by credit Entry
 *
 * @returns
 */
const DescriptionCreditLine = ({ subitem, parentSequence, disabled }) => {
  const dispatch = useDispatch();

  const remDescriptionItem = () => {
    dispatch(
      removeDescriptionItem({
        parent: parentSequence,
        sequence_num: subitem.sequence_num,
      })
    );
  };

  const handleDescriptionChange = (event) => {
    dispatch(
      updateDescriptionItem({
        parent: parentSequence,
        sequence_num: subitem.sequence_num,
        item: { ...subitem, description: event.target.value },
      })
    );
  };
  return (
    <>
      <div className="description-row mt-5">
        <div className="description-icon">
          <BsArrowReturnRight />
        </div>
        <div className="description-field">
          <TextField
            disabled={disabled}
            inputProps={{ maxLength: 30 }}
            value={subitem.description}
            onChange={handleDescriptionChange}
            helperText={
              "Characters Remaining: " +
              (30 - subitem.description.length) +
              "/" +
              30
            }
          ></TextField>
        </div>
        <div className="description-delete">
          <ButtonGroup aria-label="action-button-group" className="mt-4">
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                color="primary"
                disabled={disabled}
                onClick={remDescriptionItem}
                size="small"
              >
                <AiFillDelete />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </div>
      </div>
      <Row className="footnote">
        <i>Description Item</i>
      </Row>
    </>
  );
};

export default DescriptionCreditLine;

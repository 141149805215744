/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useRef, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { Button, Row, Col } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosSend } from "react-icons/io";
import FaxEmailTextField from "./FaxEmailTextField";
import FaxEmailCheckBox from "./FaxEmailCheckBox";
import FaxEmailRecipients from "./FaxEmailRecipients";
import {
  sendToClient,
  getSentToParams,
} from "../../../store/slices/faxEmail/faxEmailSlice";
import { AlertMessageContext } from "../AlertMessage/AlertMessage";

function FaxEmailForm({ closePopup }) {
  const [refElement, setRefElement] = useState("subject");
  const [recipientsList, setRecipientsList] = useState([]);
  const [prefillRecipientsList, setPrefillRecipientsList] = useState([]);

  // React hook form
  const {
    control,
    unregister,
    handleSubmit,
    formState: { isSubmitting, isSubmitted },
    getValues,
    setValue,
  } = useForm();

  // Refs
  const createButtonInputRef = useRef();

  // Redux
  const dispatch = useDispatch();

  const {
    subOrder: {
      customer: { contact, ap_contact },
    },
  } = useSelector((state) => state.subOrder);

  // Context
  const alertNotification = useContext(AlertMessageContext);

  useEffect(() => {
    let contactList = [];
    if (contact?.emails?.length) {
      contact.emails.forEach((email) => {
        contactList.push({
          email,
          fax: contact.fax,
          name: contact.name,
        });
      });
    } else if (contact?.fax?.replace(/\D/g, "")) {
      contactList.push({
        email: "",
        fax: contact?.fax,
        name: contact?.name,
      });
    }

    if (ap_contact?.emails?.length) {
      ap_contact.emails.forEach((email) => {
        contactList.push({
          email,
          fax: ap_contact.fax,
          name: ap_contact.name,
        });
      });
    } else if (ap_contact?.fax?.replace(/\D/g, "")) {
      contactList.push({
        email: "",
        fax: ap_contact?.fax,
        name: ap_contact?.name,
      });
    }

    setPrefillRecipientsList(contactList);
  }, [contact, ap_contact]);

  const onSubmit = (data) => {
    let thereIserror = false;
    data.recipients.forEach((recipient) => {
      if (recipient !== null) {
        if (!recipient.email && !recipient.fax) {
          thereIserror = true;
        }
      }
    });

    if (!thereIserror) {
      dispatch(sendToClient(data)).then((response) => {
        if (!response?.error) {
          closePopup();
          alertNotification.handleOpen(
            "send-email-fax-success",
            "success",
            "Data sent",
            5000
          );
        }
      });
    }
  };

  const jumpToNextField = (field) => {
    if (field) {
      setRefElement(field);
    } else {
      if (refElement === "subject") setRefElement("recipients");
      if (refElement === "billBackAccount") setRefElement("useBlankCoversheet");
      if (refElement === "useBlankCoversheet")
        setRefElement("confirmationEmail");
      if (refElement === "confirmationEmail") setRefElement("comment");
      if (refElement === "comment") createButtonInputRef.current.focus();
    }
  };

  useEffect(() => {
    dispatch(getSentToParams()).then(({ payload }) => {
      if (payload) {
        const { subject, comments, recipients } = payload;
        setValue("subject", subject);
        setValue("comments", comments);
        setRecipientsList(recipients);
      }
    });
  }, []);

  return (
    <form>
      <Row>
        <Col md={12}>
          <FaxEmailTextField
            control={control}
            name="subject"
            label="Subject"
            rules={{
              required: "This field is required",
              maxLength: {
                value: 80,
                message: "This field cannot have more than 80 characters",
              },
            }}
            rows={2}
            refElement={refElement}
            changeRefElement={jumpToNextField}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={12}>
          <FaxEmailRecipients
            control={control}
            unregister={unregister}
            useWatch={useWatch}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
            getValues={getValues}
            refElement={refElement}
            changeRefElement={(refElem) => setRefElement(refElem)}
            recipientsList={recipientsList}
            prefillRecipientsList={prefillRecipientsList}
            setValue={setValue}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={6} className="mt-5">
          <FaxEmailTextField
            control={control}
            name="billBackAccount"
            label="Bill Back Account"
            rules={{
              maxLength: {
                value: 50,
                message: "This field cannot have more than 50 characters",
              },
            }}
            refElement={refElement}
            changeRefElement={jumpToNextField}
          />
        </Col>
        <Col md={6} className="mt-5">
          <FaxEmailCheckBox
            control={control}
            name="useBlankCoversheet"
            label="Use Blank Coversheet"
            rules={{}}
            refElement={refElement}
            changeRefElement={jumpToNextField}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={6}>
          <FaxEmailTextField
            control={control}
            name="confirmationEmail"
            label="Confirmation Email"
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
              maxLength: {
                value: 50,
                message: "This field cannot have more than 50 characters",
              },
            }}
            refElement={refElement}
            changeRefElement={jumpToNextField}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={12}>
          <FaxEmailTextField
            control={control}
            name="comments"
            label="Comments"
            rules={{
              maxLength: {
                value: 640,
                message: "This field cannot have more than 640 characters",
              },
            }}
            rows={5}
            refElement={refElement}
            changeRefElement={jumpToNextField}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end align-items-center mt-5">
        <Button variant="primary" onClick={closePopup}>
          <span className="me-2">Cancel</span>
          <AiFillCloseCircle />
        </Button>
        <Button
          variant="secondary"
          className="ms-3"
          onClick={handleSubmit(onSubmit)}
          ref={createButtonInputRef}
        >
          <span className="me-2">Send to Client</span>
          <IoIosSend />
        </Button>
      </div>
    </form>
  );
}

export default memo(FaxEmailForm);

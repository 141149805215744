/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import { AiOutlineClear, AiOutlineSave, AiFillDelete } from "react-icons/ai";

import { useNavigate } from "react-router-dom";

import "./SubHeading.scss";

import SubOrderSelector from "../../atoms/SubOrderSelector";
import QuoteSelector from "../../atoms/QuoteSelector";

import { setCleaningAll } from "../../../store/slices/headerSlice";
import { setErrors } from "../../../store/slices/validations/validationsSlice";
import { createQuote, resetQuoteId } from "../../../store/slices/quoteSlice";
import { checkBackordersProcess } from "../../../store/slices/item/itemSlice";
import { endpoints } from "../../../api/endpoints";

import {
  saveAsOrder,
  deleteSubOrder,
  resetSubOrderId,
  printToOffice,
} from "../../../store/slices/subOrderSlice";

import {
  ORDER_STATUS,
  ORDER_HOLD_STATUS,
  PENDING_ORDER_STATUS,
} from "../../../helpers/const";

import ConfirmationAlert from "../../atoms/ConfirmationAlert/ConfirmarionAlert";
import ConfirmationAlertBySteps from "../../atoms/ConfirmationAlertBySteps";
import { AlertMessageContext } from "../../atoms/AlertMessage/AlertMessage";
import FaxEmail from "../../atoms/FaxEmail/FaxEmail";
import { resetCustomer } from "../../../store/slices/customer/customerSlice";
import { doInvoiceAction } from "../../../store/slices/AccountsReceivable/InvoiceAccessSlice";
import SendInvoicePopup from "../../atoms/NEWSubheadingPopups/SendInvoicePopup";
import SaveOrderPopup from "../../atoms/NEWSubheadingPopups/SaveOrderPopup";

function SubHeading() {
  // Local states
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const [openSaveAsOrderConfirmation, setOpenSaveAsOrderConfirmation] =
    useState(false);
  const [openDeleteOrderConfirmation, setOpenDeleteOrderConfirmation] =
    useState(false);
  const [deletionMessages, setDeletionMessages] = useState([]);
  const [savingAsQuote, setSavingAsQuote] = useState(false);
  const [openFaxEmail, setOpenFaxEmail] = useState(false);
  const [saved, setSaved] = useState(false);

  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  // Redux states
  const { customerId, customerName } = useSelector((state) => state.customer);
  const { items } = useSelector((state) => state.items);
  const { savedAsQuote, savedAsOrder } = useSelector((state) => state.header);
  const { quoteId } = useSelector((state) => state.quote);

  const { billOfLading, freightAmount, handlingCharge } = useSelector(
    (state) => state.freight
  );

  const {
    subOrder,
    subOrder: {
      status: orderStatus,
      hold_status: hold_status,
      took_by,
      warehouse_name,
      placed_by,
      customer_po,
      warehouse_id,
      customer_invoice_num,
      stock_transfer_error,
    },
    subOrderId,
    subOrdersLocked,
  } = useSelector((state) => state.subOrder);

  const {
    hasItemsErrors,
    hasCustomerErrors,
    hasOrderDetailsErrors,
    hasWhError,
    hasFreightError,
  } = useSelector((state) => state.validations);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Context
  const alertNotification = useContext(AlertMessageContext);

  const simulateClick = () => {
    const button = document.getElementById("simulate-for-confirm");
    console.log(button);
    if (button) {
      const event = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      button.dispatchEvent(event);
    }
  };

  /*
   * Function to handle the status of the delete confirmation popup
   * and if the confirmDeletion is true it will call a delete request of the subOrder
   * @param {boolean} state - State of the popup
   * @param {boolean} confirmDeletion
   */
  const setOpenDeleteOrderConfirmationState = useCallback(
    (state, confirmDeletion) => {
      setOpenDeleteOrderConfirmation(state);
      if (confirmDeletion) {
        dispatch(deleteSubOrder(subOrderId)).then((response) => {
          if (!response?.error) {
            alertNotification.handleOpen(
              "success-deleting-a-sub-order",
              "success",
              "Order deleted successfully",
              5000
            );

            dispatch(resetCustomer());
            dispatch(resetSubOrderId());
            navigate(endpoints.ORDER_ENTRY);
          }
        });
      }
    },
    [dispatch, subOrderId]
  );

  const handleSendInvoice = (value) => {
    setShowInvoicePopup(false);
    dispatch(
      doInvoiceAction({
        transactionNum: customer_invoice_num,
        emailAddress: value,
        deliveryMethod: "email",
        customerNum: customerId,
        useOverride: true,
      })
    ).then((response) => {
      if (!response?.error) {
        alertNotification.handleOpen(
          "validation-customer-widget",
          "success",
          `Invoice sent to '${value}' successfully!`,
          2000
        );
      }
    });
  };

  /**
   * Function to handle the status of the saves as suborder confirmation popup
   * and if the confirmConvertion is true it will call a convertion request
   * @param {boolean} state - State of the popup
   * @param {boolean} confirmConvertion
   */
  const setSaveAsOrderOpenConfirmationState = useCallback(
    (state, confirmConvertion, conf) => {
      setOpenSaveAsOrderConfirmation(state);
      if (confirmConvertion) {
        dispatch(saveAsOrder({ id: quoteId })).then((response) => {
          if (!response?.error) {
            alertNotification.handleOpen(
              "success-savign-as-sub-order",
              "success",
              "Order saved successfully",
              5000
            );
          }

          dispatch(resetQuoteId());
          navigate(
            endpoints.ORDER_ENTRY + "/order/" + response.payload.suborder
          );
        });
      }
    },
    [dispatch, quoteId]
  );

  /**
   * Function to analyze all the required fields in the form and
   * validate if all of them have been filled
   */
  const validateFields = () => {
    dispatch(
      setErrors({
        customer: {
          customerId,
          customerName,
        },
        orderDetails: {
          orderedBy: placed_by,
          customerPo: customer_po,
        },
        freight: {
          billOfLading,
          freightAmount,
          handlingCharge,
        },
        items,
        warehouseId: warehouse_id,
      })
    );
  };

  const deletingProcess = () => {
    dispatch(checkBackordersProcess({})).then((response) => {
      if (!response?.error) {
        if (response.payload.length > 0) {
          const boMessage = response.payload.map((d) => (
            <div key={d.id}>{d.message}</div>
          ));

          setDeletionMessages([
            {
              id: "boMessage",
              content: boMessage,
            },
            {
              id: "deleteQuestion",
              content: "Are you sure you want to delete this order?",
            },
          ]);
        } else {
          setDeletionMessages([
            {
              id: "deleteQuestion",
              content: "Are you sure you want to delete this order?",
            },
          ]);
        }
        setOpenDeleteOrderConfirmation(true);
      }
    });
  };

  const saveAsQuote = () => {
    let error_str = "";
    if (hasCustomerErrors) error_str = "please select a customer";
    else if (hasOrderDetailsErrors)
      error_str = "Inputs require adjustment in order details section";
    else if (hasItemsErrors || hasWhError)
      error_str = "Inputs require adjustment in order items section";
    else if (hasFreightError)
      error_str = "Inputs require adjustment in order freight section";

    if (error_str) {
      alertNotification.handleOpen(
        "validation-freight-details",
        "danger",
        error_str,
        2000
      );
      return;
    }

    dispatch(createQuote()).then((response) => {
      if (!response?.error) {
        alertNotification.handleOpen(
          "create-quote-success",
          "success",
          "Quote saved",
          5000
        );
        navigate(endpoints.ORDER_ENTRY + "/quote/" + response.payload);
      }
    });
  };

  return (
    <Container className="py-4">
      <Row>
        <Col>
          <div>
            {!savedAsQuote && <SubOrderSelector />}
            {savedAsQuote && <QuoteSelector />}
          </div>
        </Col>
        <Col>
          <div>
            <span>WAREHOUSE: </span>
            <span className="ms-2">{warehouse_name}</span>
          </div>
          <div>
            <span>Entered By </span>
            <span>{took_by}</span>
          </div>
        </Col>
        <Col className="subheading-btns">
          <div>
            <Dropdown>
              <Dropdown.Toggle className="drop-status-button">
                Tools
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* Fax/Email old school order confirmation, this endpoints still uses active fax */}
                <Dropdown.Item
                  disabled={!(savedAsOrder && subOrderId) && !savedAsQuote}
                >
                  <FaxEmail override={openFaxEmail} />
                </Dropdown.Item>

                {/* This will print the bol the warehouse will receive */}
                <Dropdown.Item
                  disabled={!(savedAsOrder && subOrderId) && !savedAsQuote}
                  onClick={() => {
                    dispatch(
                      printToOffice({
                        method: "print",
                        bol_num: subOrderId,
                        quote_num: quoteId,
                      })
                    );
                  }}
                >
                  Print BOL
                </Dropdown.Item>
                {/* This will email the invoice associated with this order to the entered email */}
                <Dropdown.Item
                  disabled={customer_invoice_num <= 0}
                  onClick={() => setShowInvoicePopup(true)}
                >
                  Send Invoice
                </Dropdown.Item>

                {/* This will show always, if the order is not saved, it deletes everything. If the order IS saved, it refreshes to a blank screen*/}
                <Dropdown.Item
                  disabled={subOrdersLocked}
                  onClick={() => {
                    if (subOrderId === 0) {
                      dispatch(setCleaningAll());
                    } else {
                      navigate(endpoints.ORDER_ENTRY);
                    }
                  }}
                >
                  <span className="me-2">Reset</span>
                  <AiOutlineClear />
                </Dropdown.Item>
                {/* Delete button only enabled if it is a order and the order has not passed in-progress */}
                <Dropdown.Item
                  disabled={subOrdersLocked}
                  onClick={() => deletingProcess()}
                  hidden={!savedAsOrder}
                >
                  <span className="me-2" style={{ color: "red" }}>
                    Delete Order
                  </span>
                  <AiFillDelete style={{ color: "red" }} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="ms-2">
            <Button
              variant="primary"
              disabled={
                items.length === 0 || subOrdersLocked || stock_transfer_error
              }
              onClick={() => {
                if (!savedAsQuote) {
                  setSavingAsQuote(true);
                  validateFields();
                  saveAsQuote();
                  if (subOrderId > 0 && subOrder.status <= 2) {
                    dispatch(deleteSubOrder(subOrderId));
                  }
                } else {
                  setSaveAsOrderOpenConfirmationState(true);
                }
              }}
            >
              <span className="me-2">
                Save as {!savedAsQuote ? "Quote" : "Order"}
              </span>
              <AiOutlineSave />
            </Button>
          </div>
          <div className="ms-2">
            <Button
              variant="primary"
              onClick={() => setShowStatusPopup(true)}
              hidden={savedAsQuote}
              disabled={
                subOrdersLocked || items.length === 0 || stock_transfer_error
              }
            >
              {savedAsOrder && subOrderId > 0 ? "Update" : "Save"}{" "}
              <AiOutlineSave />
            </Button>
          </div>
        </Col>
      </Row>
      <Row hidden={subOrderId === 0}>
        <Col>
          <div>
            <b>Status:</b>{" "}
            {ORDER_STATUS.find((os) => os.id === orderStatus).label +
              (orderStatus === PENDING_ORDER_STATUS.id
                ? " " +
                  ORDER_HOLD_STATUS.find((os) => os.id === subOrder.hold_status)
                    .label
                : "")}
          </div>
          <div>
            <b>Order Total:</b> {` ${subOrder.total}`}
          </div>
        </Col>
      </Row>
      {!savedAsQuote && (
        <SaveOrderPopup
          show={showStatusPopup}
          setShowStatusPopup={setShowStatusPopup}
          validateFields={validateFields}
          savingAsQuote={savingAsQuote}
          setSaved={setSaved}
        />
      )}
      <ConfirmationAlert
        content="Are you sure you want to save this quote as order?"
        show={openSaveAsOrderConfirmation}
        handledCancelEvent={() => setSaveAsOrderOpenConfirmationState(false)}
        handleConfirmEvent={() =>
          setSaveAsOrderOpenConfirmationState(false, true)
        }
      />
      <ConfirmationAlert
        content="Would you like to send a confirmation?"
        show={saved}
        handledCancelEvent={() => {
          setOpenFaxEmail(false);
          setSaved(false);
        }}
        handleConfirmEvent={() => {
          simulateClick();
          setSaved(false);
        }}
      />
      <ConfirmationAlertBySteps
        title="Process of deletion"
        show={openDeleteOrderConfirmation}
        close={() => setOpenDeleteOrderConfirmationState(false)}
        steps={deletionMessages}
        confirmationLabel="Delete"
        handledCancelEvent={() => setOpenDeleteOrderConfirmationState(false)}
        handleConfirmEvent={() =>
          setOpenDeleteOrderConfirmationState(false, true)
        }
      />
      <SendInvoicePopup
        show={showInvoicePopup}
        handleConfirmEvent={handleSendInvoice}
        handleCancelEvent={() => setShowInvoicePopup(false)}
        order={subOrder}
      />
    </Container>
  );
}

export default SubHeading;

import { memo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Autocomplete, Alert } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import Card from "react-bootstrap/Card";
import {
  setShippingRulesTrigger,
  setAutoSaveFocusOuttedTrue,
} from "../../../store/slices/eventHandlerSlice";
import {
  applyShippingRules,
  verifyFreightDetails,
} from "../../../store/slices/freight/freightSlice";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrderItem from "../../atoms/OrderItem/OrderItem";
import { AlertMessageContext } from "../../atoms/AlertMessage/AlertMessage";
import useOrderItems from "../../../hooks/useOrderItems";
import OrderItemsFooter from "../OrderItemsFooter/OrderItemsFooter";
import { formStyle } from "../../../theming/formStyle";
import "./OrderItems.scss";
import XRefPopup from "./XRefPopup";
import InputNumber from "../../atoms/InputNumber";
import { Accordion } from "react-bootstrap";

function OrderItems() {
  const dispatch = useDispatch();

  const {
    orderItems,
    itemType,
    allowedActions,
    typeItemSelector,
    hasItemsErrors,
    hasWhError,
    subOrderWarehouseName,
    subOrdersLocked,
    itemsTypeOptions,
    xrefOpen,
    addRow,
    onClickHandler,
    setXrefOpen,
    addXrefItem,
  } = useOrderItems();

  const { savedAsQuote } = useSelector((state) => state.header);
  const [lastRule, setLastRule] = useState(0);

  const {
    subOrderId,
    subOrder: {
      freight: { ignore_shipping_rules, has_shipping_rules },
    },
  } = useSelector((state) => state.subOrder);

  const { items } = useSelector((state) => state.items);

  const {
    customerId,
    customerData: { useShippingRules },
  } = useSelector((state) => state.customer);

  const {
    shippingRules: { shouldApplyShippingRules, triggerChanged, focusOutted },
    verifyFreightDetails: { triggerChanged: verifyFreightTriggerChanged },
    autoSaveOrder: { triggerChanged: autoSaveTriggerChanged },
  } = useSelector((state) => state.eventHandler);

  const customAlert = useContext(AlertMessageContext);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    if (customerId && useShippingRules && !ignore_shipping_rules) {
      dispatch(
        setShippingRulesTrigger({
          shouldApplyShippingRules: true,
          triggerChanged: false,
          focusOutted: false,
        })
      );
    } else {
      dispatch(
        setShippingRulesTrigger({
          shouldApplyShippingRules: false,
        })
      );
    }
  }, [customerId, useShippingRules, ignore_shipping_rules]);

  useEffect(() => {
    // customerId cannot be changed in the existing subOrder.
    // If customerId changes in the existing subOrder, it is because of the first data loading from the database - changes from 0 to its id
    if (!subOrderId && shouldApplyShippingRules && items.length > 1) {
      dispatch(
        setShippingRulesTrigger({
          triggerChanged: true,
          focusOutted: true,
        })
      );
    }
  }, [customerId]);

  useEffect(() => {
    if (shouldApplyShippingRules && triggerChanged && focusOutted) {
      dispatch(applyShippingRules(lastRule)).then((response) => {
        setLastRule(
          response.payload.rule ? parseInt(response.payload.rule) : lastRule
        );
        handleAutoSaveTriggerTrue();

        const confirmText1 = response.payload.confirmText?.text1;
        const confirmText2 = response.payload.confirmText?.text2;

        if (lastRule !== parseInt(response.payload.rule)) {
          customAlert.handleOpen(
            "shipping-rules-apply-confirmation",
            "success",
            <div>
              <div>Shipping rules are applied.</div>
              <div className="text-break">{confirmText1}</div>
              <div className="text-break">{confirmText2}</div>
            </div>,
            (confirmText1 && confirmText1.trim()) ||
              (confirmText2 && confirmText2.trim())
              ? 0
              : 5000
          );
        }
      });
    }
  }, [shouldApplyShippingRules, triggerChanged, focusOutted]);

  const handleAutoSaveTriggerTrue = () => {
    if (subOrderId && autoSaveTriggerChanged) {
      dispatch(setAutoSaveFocusOuttedTrue());
    }
  };

  const handleClickAway = () => {
    if (shouldApplyShippingRules) {
      if (triggerChanged) {
        dispatch(setShippingRulesTrigger({ focusOutted: true }));
      } else {
        handleAutoSaveTriggerTrue();
      }
    } else {
      if (verifyFreightTriggerChanged && !has_shipping_rules) {
        if (customerId) {
          dispatch(verifyFreightDetails());
        }
      } else {
        handleAutoSaveTriggerTrue();
      }
    }

    if (shouldApplyShippingRules && triggerChanged) {
      // qrd is changed
      dispatch(setShippingRulesTrigger({ focusOutted: true }));
    } else {
      // other field in line item section is changed
      handleAutoSaveTriggerTrue();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="OrderItems">
        <Card>
          <Card.Body>
            <Accordion className="accordion-flush">
              <Accordion.Item>
                <Accordion.Header>
                  <Row>
                    <Col>
                      <h3>Line Items</h3>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body>
                  {items.length === 0 && (
                    <Alert severity="error" variant="filled">
                      You must have at least one item present to save the order.
                    </Alert>
                  )}
                  {hasItemsErrors && (
                    <Alert severity="error" variant="filled">
                      Some fields on the above items list have not been set
                    </Alert>
                  )}
                  {hasWhError && (
                    <Alert severity="error" variant="filled">
                      The order is currently set to the {subOrderWarehouseName}{" "}
                      wh. Only one warehouse allowed.
                    </Alert>
                  )}
                  <table className="table">
                    <tbody>
                      {orderItems.map((orderItem) => (
                        <OrderItem
                          key={`${orderItem.ln}${orderItem.referenceCode}`}
                          item={orderItem}
                          allowedActions={allowedActions}
                        />
                      ))}
                      <tr style={{ borderColor: "black" }}>
                        <td colSpan={2}>
                          <strong>Select Type to Add Item:</strong>
                          <Autocomplete
                            disableClearable
                            className="autocomplete-input"
                            options={itemsTypeOptions}
                            name="productCode"
                            disabled={
                              savedAsQuote || subOrdersLocked ? true : false
                            }
                            value={itemType}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option) =>
                              option.id === itemType.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="productCode"
                                style={formStyle.inputStyle}
                                InputLabelProps={formStyle.inputLabelProps}
                                inputRef={typeItemSelector}
                                onFocus={onClickHandler}
                              />
                            )}
                            openOnFocus
                            onChange={(_e, value) => addRow(value)}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          borderBottomStyle: "solid",
                          borderBottomWidth: "1px",
                          borderBottomColor: "black",
                        }}
                      >
                        <OrderItemsFooter orderItems={orderItems} />
                      </tr>
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <XRefPopup
              open={xrefOpen}
              setOpen={setXrefOpen}
              addItem={addXrefItem}
            />
          </Card.Body>
        </Card>
      </div>
    </ClickAwayListener>
  );
}

export default memo(OrderItems);

import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Heading from "../../components/sections/Heading/Heading";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import DataGrid from "../../components/atoms/Table/DataGrid";
import {
  CREDIT_APPROVAL_STATUS,
  CREDIT_HOLD_STATUS,
  CREDIT_REASON,
  CREDIT_TYPE,
} from "../../helpers/const";
import { endpoints } from "../../api/endpoints";
import { getInvoicesByFilter } from "../../store/slices/AccountsReceivable/InvoiceAccessSlice";
import { Button, Col, Row } from "react-bootstrap";

/**
 * These header attributes can be changed at any point if needed and it will automatically update below.
 * Be careful changing the ID as that is actually used to set values when filtering
 */
const newHeaderAttributes = [
  {
    id: "invoice_num",
    label: "Invoice #",
    tooltip: "Invoice Number",
    sortable: true,
    searchable: {
      label: "Search by Invoice Number",
      type: "text",
      width: 4,
    },
    width: "5%",
  },
  {
    id: "type",
    label: "Invoice Type",
    sortable: true,
  },
  {
    id: "ref_number",
    label: "Ref Order/Credit #",
    hidden: false,
    searchable: {
      label: "Search by Reference Number",
      type: "text",
      width: 4,
    },
  },
  {
    id: "customer_po",
    label: "PO",
    sortable: false,
    searchable: {
      label: "Search by Customer PO",
      type: "text",
      width: 4,
    },
  },
  {
    id: "date_invoiced",
    label: "Date",
    tooltip: "Date of Invoice",
    sortable: true,
    width: "10%",
  },
  {
    id: "total",
    label: "Total",
    sortable: true,
  },
  {
    id: "customer",
    label: "Customer Summary",
    sortable: true,
  },
  {
    id: "customer_alt",
    label: "Customer Billing Name",
    sortable: true,
  },
  {
    id: "aftermarket",
    label: "After Market #",
    sortable: true,
  },
  {
    id: "parent_num",
    label: "Parent #",
    sortable: true,
  },
  {
    id: "none",
    label: "",
    sortable: true,
  },
  {
    id: "customer_num",
    label: "Customer Number",
    hidden: true,
    sortable: false,
    width: "20%",
    searchable: {
      label: "Search by Customer Number",
      type: "text",
      width: 4,
    },
  },
  {
    id: "search",
    label: "Customer Name",
    sortable: false,
    hidden: true,
    width: "20%",
    searchable: {
      label: "Customer Fuzzy Search",
      type: "text",
      width: 4,
    },
  },
];

/**
 * Component to fetch and render the list of credits based on parameters
 *
 * @returns InvoiceSearch
 */
const InvoiceSearch = () => {
  const [dataList, setDataList] = useState([]);
  //The total count of credits that fit into this filter, even if not all are displayed since we paginate at 50
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [resetFields, setResetFields] = useState(false);

  // Redux
  const dispatch = useDispatch();

  const fetchInvoices = useCallback(
    async ({
      invoice_num,
      customer_num,
      ref_number,
      customer_po,
      search,
      to,
      from,
      page,
      order_by,
      sort_order,
    }) => {
      /**
       * This can look intimidating but all this does is get the objects by passing filter params to the API
       *  then
       * manually sets each field in header attributes if it exists
       */
      dispatch(
        getInvoicesByFilter({
          invoice_num,
          customer_num,
          ref_number,
          customer_po,
          search,
          to,
          from,
          page,
          order_by,
          sort_order,
        })
      ).then((response) => {
        if (!response?.error) {
          setTotalInvoices(response.payload.count);
          let tempList = response?.payload.results;
          setDataList(
            tempList.map((value) => ({
              id: value.invoice_num,
              invoice_num: value.invoice_num,
              type: value.type === 1 ? "Credit" : "Order",
              ref_number: value.customer_order_num,
              po: value.customer_po,
              date: value.date_invoiced,
              total: value.total_invoiced,
              customer: `${value.customer_num} - ${value.customer_name}`,
              customer_alt: `${value.customer_bill_name} `,
              aftermarket:
                value.customer_aftermarket_network_num > 0
                  ? value.customer_aftermarket_network_num
                  : "",
              parent:
                value.customer_parent_num > 0 ? value.customer_parent_num : "",
              actions: (
                <Link
                  target="_blank"
                  to={
                    endpoints.ACCOUNTS_RECEIVABLE_ENTRY +
                    `/${value.invoice_num}/${value.customer_num}`
                  }
                >
                  Send
                </Link>
              ),
            }))
          );
        }
      });
    },
    []
  );

  /**
   * This function is probably not necessary but I do it to allow the data to be tracked easier,
   * you could just explode customParameters but doing like this,
   * once you get to creditSearch you can assume all of your data is either valid or undefined
   */
  const prepareFetchInvoices = useCallback(
    async ({ page = 0, orderBy, sortOrder, customParameters, stat }) => {
      let invoice_num;
      let customer_num;
      let customer_po;
      let ref_number;
      let search;
      let from = null;
      let to = null;

      if (customParameters) {
        customParameters.forEach((c) => {
          switch (c?.id) {
            case "invoice_num":
              invoice_num = c.value;
              break;
            case "customer_num":
              customer_num = c.value;
              break;
            case "customer_po":
              customer_po = c.value;
              break;
            case "ref_number":
              ref_number = c.value;
              break;
            case "search":
              search = c.value;
              break;
            case "dates":
              from = dayjs(c.value[0]).format("YYYY-MM-DD");
              to = dayjs(c.value[1]).format("YYYY-MM-DD");
              break;
          }
        });
      }

      await fetchInvoices({
        page: page + 1,
        order_by: orderBy,
        sort_order: sortOrder === "desc" ? "dsc" : "asc",
        invoice_num,
        customer_num,
        ref_number,
        customer_po,
        search,
        to,
        from,
      });
    },
    [fetchInvoices]
  );

  const handleReset = () => {
    setResetFields(true);
  };

  return (
    <div>
      <Row className="mt-3">
        <Col>
          <Heading title="Invoices" />
        </Col>
        <Col>
          <Button
            variant="danger"
            onClick={handleReset}
            className="btn-reset f-right"
          >
            Reset Fields
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              float: "right",
              fontSize: "80%",
              color: "gray",
            }}
          >
            <i>Customer PO requires at LEAST 3 characters before searching.</i>
          </div>
        </Col>
      </Row>

      <div className="mt-5">
        <div className="mt-5">
          {/* This is a custom component not a MUI component */}
          <DataGrid
            headerAttributes={newHeaderAttributes}
            dataList={dataList}
            hideId={true}
            rowsPerPage={50}
            total={totalInvoices}
            defaultOrder="desc"
            defaultOrderBy="invoice_num"
            isInvoices={true}
            fetchData={prepareFetchInvoices}
            resetFields={resetFields}
            setResetFields={setResetFields}
            dateFilter={{
              show: true,
              inputs: [
                {
                  label: "From",
                  name: "from",
                },
                {
                  label: "To",
                  name: "to",
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceSearch;

import { memo, useState } from "react";
import { Modal, Button, Row, Col, Card, Dropdown } from "react-bootstrap";
import { ClaimStatus } from "../../../helpers/const";
import { useDispatch, useSelector } from "react-redux";
import { setClaim } from "../../../store/slices/Claims/ClaimSlice";

/**
 * This is definitely the most complicated popup used in the credit application,
 * it actually defines two components, but the status selector is private
 *
 * The main modal shows a summary of the credit and the status selector
 *
 * @param content used to display a summary of what is going to be saved
 * @param show determines whether or not the modal should be hidden
 * @param handleCancelEvent what to do when the user clicks cancel
 * @param handleConfirmEvent what to do when the user click the confirm button
 * @param status the status of the credit (choices of CREDIT_APPROVAL_STATUS), this is an object NOT a number it has the field {id, label}
 * @param setStatus setter for status to keep the status updated accross components
 *
 * @returns
 */
function ConfirmSaveClaimPopup({
  content,
  show,
  handleCancelEvent,
  handleConfirmEvent,
  status,
  setStatus,
}) {
  const [statusObj, setStatusObj] = useState(
    ClaimStatus.filter((stat) => stat.id === status)[0]
  );

  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>Claim Summary</b>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Card>
          <Card.Title className="mb-4">Save Credit?</Card.Title>
          <Row>
            <Col>
              <b>Cust #:</b> {content.customer.customer_num}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Order #:</b> {content.customer_order_num}
            </Col>
            <Col>
              <b>Invoice #:</b> {content.customer_invoice_num}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Customer PO:</b> {content.customer_po}
            </Col>
            <Col>
              <b>Date Entered: </b> {content.date_of_order}
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <Card.Title className="mb-4">Select the Status</Card.Title>
            </Col>

            <Card.Body>
              <StatusSelector
                statusObj={statusObj}
                setStatusObj={setStatusObj}
              />
            </Card.Body>
          </Row>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Save Claim
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const StatusSelector = ({ statusObj, setStatusObj }) => {
  const dispatch = useDispatch();
  const { claim } = useSelector((state) => state.claims);
  const setStatus = (stat) => {
    setStatusObj(stat);
    const temp = {
      ...claim,
      claim_status: stat.id,
    };

    dispatch(setClaim(temp));
  };
  return (
    <>
      <div className="d-flex">
        <Dropdown>
          <Dropdown.Toggle className="drop-status-button">
            {statusObj.label}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {ClaimStatus.map((status, index) => (
              <Dropdown.Item
                key={status.id}
                eventKey={index}
                className="dropdown-draft"
                onClick={(e) => setStatus(status)}
              >
                {status.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default memo(ConfirmSaveClaimPopup);

import { memo } from "react";
import { Modal, Button } from "react-bootstrap";
import "./DuplicateCreditPopup.scss";
function WarningDeletePopup({ show, handleCancelEvent, handleConfirmEvent }) {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>Delete Credit</b>
        </div>
      </Modal.Header>
      <Modal.Body>
        This will delete this order. <b>You will NOT be able to undo this!</b>
        <br />
        <br /> Are you sure you want to continue?
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(WarningDeletePopup);

import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import "../CreditInput.scss";
import { useEffect, useState } from "react";
import {
  addCreditItem,
  addDescriptionItem,
  removeCreditItem,
} from "../../../../store/slices/Credits/CreditSlice";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CreditLine from "./CreditLine";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  ADJUSTMENT_CREDIT,
  CREDIT_LINE_TYPE,
  CREDIT_TYPE,
} from "../../../../helpers/const";
import { defaultCreditItem } from "../../../../api/models/CreditModels";

/**
 *
 * @param  description this is stored in the original order and is not editable
 * @param  qtyShipped this is stored in the original order and is not editable
 * @param  lineNum this is stored in the original order and is not editable
 * @param  itemPrice this is stored in the original order and is not editable
 * @param  weight this is stored in the original order and is not editable
 * @param  price this is stored in the original order and is not editable
 * @param  productCode this is stored in the original order and is not editable
 * @param id this is a relation id to connect credit items with display items
 *
 * @param disabled this is determined by order entry whether or not this order should be editable
 *
 * @returns
 */
const LineItem = ({
  productCode,
  qtyShipped,
  itemPrice,
  description,
  weight,
  price,
  disabled,
}) => {
  const { creditItems, credit } = useSelector((state) => state.credits);

  const [showInput, setShowInput] = useState(true);
  const [thisItems, setThisItems] = useState([]);

  //These 3 fields are used primarily for managing the CreditLines component to ensure totals and maximums are maintained accross all line items
  const [totalReturn, setTotalReturn] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);

  const dispatch = useDispatch();

  //These two work together, after changes to either, totalCount === creditLines.length

  useEffect(() => {
    const filtered = creditItems.filter(
      (item) => item.product_code === productCode
    );
    setThisItems(filtered);
    let totalRtrn = 0;
    let totalCrdt = 0;
    let totalWgt = 0;
    for (let i = 0; i < filtered.length; i++) {
      totalRtrn += filtered[i].qty_shipped || 0;
      totalCrdt += filtered[i].extended_credit || 0;
      totalWgt += weight * filtered[i].qty_shipped || 0;
    }

    setTotalCredit(totalCrdt);
    setTotalReturn(totalRtrn);
    setTotalWeight(totalWgt);
  }, [creditItems]);

  const removeItem = () => {
    dispatch(removeCreditItem({ product_code: productCode }));
  };

  const addItem = () => {
    if (totalReturn < qtyShipped && thisItems.length < qtyShipped) {
      dispatch(
        addCreditItem({
          ...defaultCreditItem,
          sequence_num: credit.next_line_num,
          product_code: productCode,
          description: description,
          sale_price: itemPrice,
          item_price: itemPrice,
          item_type: CREDIT_LINE_TYPE.NORMAL,
          unit_weight: weight,
        })
      );
    }
  };

  return (
    <div className="mb-5 bottom-border-seperator">
      <Row className="label-row">
        <Col className="g-row">
          <div className="info-header">Product Code</div>
          <div className="info-credit">{productCode}</div>
        </Col>
        <Col className="g-row">
          <div className="info-header">Description</div>
          <div className="info-credit">{description}</div>
        </Col>
        <Col className="g-row">
          <div className="info-header">Qty Shipped</div>
          <div className="info-credit">{qtyShipped}</div>
        </Col>
        <Col className="g-row">
          <div className="info-header">Item Price</div>
          <div className="info-credit">{itemPrice}</div>
        </Col>
        <Col className="g-row">
          <div className="info-header">Total Price</div>
          <div className="info-credit">{price}</div>
        </Col>
        {/* This is the counter that tracks how many credit lines per line item on the original order */}
        <Col className="counter-row">
          <div
            onClick={removeItem}
            className={"counter-btn" + (disabled ? " disabled" : "")}
          >
            <RemoveIcon />
          </div>
          <div className={disabled ? "disabled" : ""}>{thisItems.length}</div>
          <div
            onClick={addItem}
            className={"counter-btn" + (disabled ? " disabled" : "")}
          >
            <AddIcon />{" "}
          </div>
        </Col>

        <Col className="g-line">
          <Button
            disabled={!thisItems.length > 0}
            onClick={() => setShowInput(!showInput)}
            className="bg-dull mt-4"
          >
            {showInput ? "Hide " : "Show"}{" "}
            {showInput && <KeyboardArrowDownIcon />}
            {!showInput && <KeyboardArrowRightIcon />}
          </Button>
        </Col>
      </Row>
      {/* Summary of credit items shown when a line item is hidden */}
      {thisItems.length > 0 && (
        <Row>
          {!showInput && (
            <Col>
              <i>
                <b>Item Total Qty:</b> {totalReturn} <b>Item Total Credit:</b>{" "}
                {totalCredit.toFixed(2)} <b>Item Total Weight:</b> {totalWeight}
              </i>
            </Col>
          )}
        </Row>
      )}

      {/* These are the input fields for the actual credit line items */}
      {showInput &&
        thisItems.map((item) => {
          return (
            <div key={item.sequence_num}>
              <CreditLine
                item={item}
                totalReturn={totalReturn}
                maxAllowedQty={qtyShipped}
                maxAllowedPrice={itemPrice}
                disabled={disabled}
              />
            </div>
          );
        })}
    </div>
  );
};

export default LineItem;

import { Container, Table } from "@mui/material";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

const MostPopularItems = ({ listData }) => {
  return (
    <Container fluid>
      <div
        style={{
          overflowX: "auto", // Enables horizontal scroll
          overflowY: "auto", // Enables vertical scroll
          maxHeight: "125px", // Reduces table height
        }}
      >
        <Table
          responsive
          style={{ borderCollapse: "collapse", fontSize: "14px" }}
        >
          <thead
            style={{
              backgroundColor: "#cce5ff",
              position: "sticky",
              top: 0,
              zIndex: 2,
              fontSize: "13px", // Smaller header text
            }}
          >
            <tr>
              <th style={{ padding: "6px 10px" }}>Product Code</th>
              <th style={{ padding: "6px 10px" }}>Description</th>
              <th style={{ padding: "6px 10px" }}>Order Chance</th>
            </tr>
          </thead>
          <tbody>
            {listData?.length > 0 ? (
              listData.map((data, index) => (
                <tr key={index}>
                  <td style={{ padding: "4px 10px", lineHeight: "1" }}>
                    {data.product_code}
                  </td>
                  <td style={{ padding: "4px 10px", lineHeight: "1" }}>
                    {data.description}
                  </td>
                  <td
                    style={{ padding: "4px 10px", lineHeight: "1" }}
                    className="f-right"
                  >
                    {parseFloat(data.probability).toFixed(2)} %
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="3"
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontStyle: "italic",
                    color: "#888",
                  }}
                >
                  No order history in the last year!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default MostPopularItems;

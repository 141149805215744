import { memo, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
import { AiOutlineVerticalAlignMiddle } from "react-icons/ai";

function SendInvoicePopup({
  handleCancelEvent,
  handleConfirmEvent,
  show,
  order,
}) {
  const [invoiceRecipient, setInvoiceRecipient] = useState(false);

  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>Send Invoice for Order # {order.customer_order_num}</div>
      </Modal.Header>

      <Modal.Footer></Modal.Footer>
      <Modal.Body>
        <Row>
          <Col>
            <b>Invoice #</b>: {order.customer_invoice_num}
          </Col>
          <Col>
            <b>Cust #</b>: {order.customer.customer_num}
          </Col>
        </Row>
        <Row>
          <Col
            className="mt-4"
            style={{ display: "flex", alignItems: "center", gap: 10 }}
          >
            <TextField
              className="w-100"
              variant="outlined"
              label="Recipient Email"
              onChange={(e) => setInvoiceRecipient(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && invoiceRecipient.length > 5)
                  handleConfirmEvent(invoiceRecipient);
              }}
            ></TextField>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>

        <Button
          variant={"primary"}
          onClick={() => handleConfirmEvent(invoiceRecipient)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && invoiceRecipient.length > 5)
              handleConfirmEvent(invoiceRecipient);
          }}
        >
          Send Invoice
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(SendInvoicePopup);

import { Card, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FreightRater from "../../FreightRater/FreightRater";
import {
  FREIGHT_LINE_PACKAGE_RATER_MAPPING,
  RATER_TYPE,
} from "../../../helpers/const";
import {
  setModPrice,
  setPrepaid,
  setSelectedRateGeneric,
} from "../../../store/slices/FreightRater/FreightRaterSlice";
import {
  getFreightLineByScac,
  updateCredit,
} from "../../../store/slices/Credits/CreditSlice";
import { getFreightLineById } from "../../../store/slices/freight/freightSlice";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const CreditInputFreight = (props) => {
  const {
    selectedWarehouse,
    creditOrder,
    totalWeight,
    creditItems,
    dropShip,
    credit,
    activeWarehouses,
  } = useSelector((state) => state.credits);

  const { selectedRate, prepaid } = useSelector((state) => state.freightRater);

  const dispatch = useDispatch();

  const handleDirectShipChange = (event) => {
    dispatch(
      updateCredit({
        ...credit,
        direct_ship: credit.direct_ship === "Y" ? "N" : "Y",
      })
    );
  };

  useEffect(() => {
    const scac =
      selectedRate?.quote?.carrier ||
      FREIGHT_LINE_PACKAGE_RATER_MAPPING.find(
        (rate) => rate.packageRaterName === selectedRate?.quote?.service
      )?.id ||
      undefined;
    const rate =
      selectedRate?.quote?.net || selectedRate?.quote?.rate || undefined;
    const handling_charge = selectedRate?.quote?.handling_charge || 0;
    if (scac) {
      dispatch(getFreightLineByScac({ scac })).then((response) => {
        if (!response?.error) {
          dispatch(
            updateCredit({
              ...credit,
              freight_line: response.payload,
              freight_amount: selectedRate.customPrice,
              return_prepaid: prepaid,
              handling_charge: handling_charge,
              incoming_freight: rate || 0 - handling_charge,
            })
          );
        }
      });
    }
  }, [selectedRate]);

  useEffect(() => {
    dispatch(setModPrice(credit.freight_amount));
    dispatch(setPrepaid(credit.return_prepaid));
    dispatch(
      setSelectedRateGeneric({
        quote: {
          name: credit.freight_line.name,
        },
        customPrice: credit.freight_amount,
      })
    );
  }, []);

  return (
    <>
      <Card hidden={!props.show}>
        <Card.Title className="bottom-border-seperator mb-0">
          Saved Freight Information
        </Card.Title>
        <Card.Body className="mb-2">
          <Row className="mb-4">
            <Col>
              <b>Freight Amount:</b> {credit.freight_amount}
            </Col>
            <Col>
              <b>Prepaid:</b> {credit.return_prepaid === "Y" ? "Yes" : "No"}
            </Col>
            <Col>
              <b>Freight Line:</b>{" "}
              {`${credit.freight_line.name} - (${credit.freight_line.freight_line_num}) `}
            </Col>
            <Col>
              <FormGroup className="f-right">
                <FormControlLabel
                  label="Direct Ship"
                  onChange={handleDirectShipChange}
                  checked={credit.direct_ship === "Y" ? true : false}
                  control={<Checkbox disabled={props.disabled} />}
                />
              </FormGroup>
            </Col>
          </Row>

          <FreightRater
            disabled={props.disabled}
            show={!props.show}
            warehouse={activeWarehouses.find(
              (wh) => wh.warehouse_id === credit.warehouse
            )}
            order={creditOrder}
            totalWeight={totalWeight}
            type={RATER_TYPE.CREDIT_RATER}
            items={creditItems}
            dropShip={dropShip}
            freightAmount={credit.freight_amount}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default CreditInputFreight;

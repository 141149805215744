import { memo } from "react";
import { Button, Modal } from "react-bootstrap";

const UpdateLogPopup = ({ show, tag, body, setShowUpdate }) => {
  return (
    <Modal
      show={show}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setShowUpdate(false)}
      style={{ maxHeight: "500px" }}
    >
      <Modal.Header closeButton>
        <div>
          <b>
            Update <i>{tag}</i>
          </b>
        </div>
      </Modal.Header>
      <Modal.Body>
        {body.map((update) => (
          <div className="mb-5">
            <i>
              ** <b>{update.date}</b>
            </i>

            {update.entries.map((entry) => (
              <>
                <div>{`- ${entry}`}</div>
              </>
            ))}
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default memo(UpdateLogPopup);

import { memo, useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "./SubstitutionItemPopup.scss";
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import { Grid } from "@mui/material";

function SubstitutionItemPopup({
  count,
  max,
  content,
  show,
  backEvent,
  forwardEvent,
  handleCancelEvent,
  handleConfirmEvent,
  confirmationLabel,
  handleRadioClick,
}) {
  const tableStr = "Toggle Table View";
  const pageStr = "Toggle Page View";

  const [table, setTable] = useState("none");
  const [page, setPage] = useState("");
  const [currStr, setCurrStr] = useState(tableStr);

  function toggleView() {
    var temp = table;
    setTable(page);
    setPage(temp);

    if (currStr === tableStr) {
      setCurrStr(pageStr);
    } else {
      setCurrStr(tableStr);
    }
  }

  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          Available Substitution: {count} of {max}
        </div>
        <Button
          variant={confirmationLabel === "Delete" ? "primary" : "primary"}
          onClick={() => toggleView()}
          className="subs-table-btn-head"
        >
          {currStr}
        </Button>
      </Modal.Header>
      <Modal.Body style={{ display: page }}>
        <div className="embed">
          <div>
            Product Code:{" "}
            {content[count - 1].productCode
              ? content[count - 1].productCode
              : "-"}{" "}
          </div>
          <div className="second-subs">
            Substitution Code:{" "}
            {content[count - 1].substitutionCode
              ? content[count - 1].substitutionCode
              : "-"}
          </div>
          <div>
            Description Code:{" "}
            {content[count - 1].description
              ? content[count - 1].description
              : "-"}
          </div>
          <div className="second-subs">
            Additional % disc:{" "}
            {content[count - 1].additionalPercOff
              ? content[count - 1].additionalPercOff
              : "0.0"}
          </div>
          <div style={{ whiteSpace: "pre-line" }}>
            Availability:{" "}
            {content[count - 1].availability
              ? content[count - 1].availability
              : "-"}
          </div>
        </div>
        <div className="btn btn-primary left btn-subs-left" onClick={backEvent}>
          <TbArrowBackUp />
        </div>
        <div
          className="btn btn-primary right btn-subs-right"
          onClick={forwardEvent}
        >
          <TbArrowForwardUp />
        </div>
      </Modal.Body>
      <Modal.Body style={{ display: table }}>
        <table className="subs-table">
          <tr
            className="subs-table-head"
            style={{ borderBottom: "solid black 2px" }}
          >
            <th></th>
            <th>Product Code</th>
            <th>Substitution Code</th>
            <th>Description</th>
            <th>% Disc</th>
            <th>Availability</th>
          </tr>
          {content.map((item, index) => (
            <tr key={index}>
              <td>
                <input
                  type="radio"
                  className="sub-radio"
                  name="subs"
                  id={index}
                  onChange={handleRadioClick}
                ></input>
              </td>
              <td> {item.productCode}</td>
              <td> {item.substitutionCode}</td>
              <td> {item.description}</td>
              <td> {item.additionalPercOff}</td>
              <td> {item.availability}</td>
            </tr>
          ))}
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={confirmationLabel === "Delete" ? "danger" : "danger"}
          onClick={handleCancelEvent}
        >
          Cancel
        </Button>
        <Button
          variant={confirmationLabel === "Delete" ? "primary" : "primary"}
          onClick={handleConfirmEvent}
        >
          Substitute Item
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(SubstitutionItemPopup);

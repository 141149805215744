import { useState } from "react";
import FreightRaterSelection from "./Sections/RaterSelection";
import { Card, Spinner } from "react-bootstrap";
import LoaderSpinner from "../../components/atoms/LoaderSpinner";
import { useDispatch, useSelector } from "react-redux";
import GenericFreightRaterHeader from "./Sections/RaterHeader";
import FreightRaterLTL from "./Sections/LTLResults";
import {
  getPackageRaterOptions,
  rateLTL,
} from "../../store/slices/FreightRater/FreightRaterSlice";
import FreightRaterPackage from "./Sections/FreightRaterPackage";

const FreightRater = (props) => {
  const [selectedRater, setSelectedRater] = useState("LTL");

  const dispatch = useDispatch();

  const { rating } = useSelector((state) => state.freightRater);

  const rate = () => {
    if (selectedRater === "LTL") {
      dispatch(
        rateLTL({
          fromZip: props.dropShip
            ? props.dropShip.address.zip_code
            : props.order.customer.shipping_address.zip_code,
          warehouseZip: props.warehouse.zip,
          weight: props.totalWeight,
        })
      );
    } else if (selectedRater === "PACKAGE") {
      dispatch(getPackageRaterOptions());
    }
  };

  return (
    <>
      <Card hidden={props.show || props.disabled} className="pad-0">
        {rating && (
          <div
            className="rater-spinner bg-dark"
            style={{ "--bs-bg-opacity": ".5", zIndex: 5000 }}
          >
            <Spinner className="" animation="grow" variant="success" />
          </div>
        )}
        <Card.Title className="mc-2">
          <h5 className="bottom-border-seperator">Freight Rater</h5>
        </Card.Title>
        <Card.Body className="mc-2">
          <FreightRaterSelection
            setSelectedRater={setSelectedRater}
            selectedRater={selectedRater}
          />

          <GenericFreightRaterHeader
            warehouse={props.warehouse}
            order={props.order}
            totalWeight={props.totalWeight}
            type={props.type}
            rate={rate}
            dropShip={props.dropShip}
          />

          {/* Freight Rater Order Details */}
          {selectedRater === "LTL" && <FreightRaterLTL order={props.order} />}

          {selectedRater === "PACKAGE" && (
            <FreightRaterPackage
              order={props.order}
              totalWeight={props.totalWeight}
              items={props.items}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default FreightRater;

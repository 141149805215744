import { TextField } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerByIdCredits,
  updateCredit,
} from "../../../store/slices/Credits/CreditSlice";
import { STANDARD_CREDIT } from "../../../helpers/const";
import { useEffect, useState } from "react";

/**
 *
 * @param show determines whether this component will be visible at all, this is toggled by the CreditInputToggle
 * @param disabled if the CreditEntry logic determines the user should not be able to edit these fields, this will be true
 *
 * @returns Component for customer information
 */
const CreditInputCustomer = ({ show, disabled }) => {
  // Drop ship could also be named "returnAddress", any time the return address is updated we just use this field since the drop ship is already kind of a custom variable
  const { creditOrder, credit } = useSelector((state) => state.credits);

  const [customer, setCustomer] = useState(
    credit.customer ? credit.customer : creditOrder.customer
  );
  const [editingCustomer, setEditingCustomer] = useState(false);
  const [lastCustomer, setlastCustomer] = useState(customer.customer_num);

  const dispatch = useDispatch();

  // There are only six fields that need to be updated and they all just need to be updated directly without any modifications
  // Therefore, we are using one method with a 'key' to access the proper case in the switch
  // the dispatch method is also the same for all of these

  useEffect(() => {
    if (!creditOrder.freight.is_drop_ship && !credit.credit_memo_num > 0) {
      dispatch(
        updateCredit({
          ...credit,
          return_customer_name: customer.name,
          return_street: customer.shipping_address.street,
          return_route: customer.shipping_address.route,
          return_city: customer.shipping_address.city,
          return_state: customer.shipping_address.state,
          return_zip: customer.shipping_address.zip_code,
        })
      );
    }
  }, []);

  const returnUpdate = (value, pick) => {
    dispatch(
      updateCredit({
        ...credit,
        return_customer_name:
          pick === "name" ? value : credit.return_customer_name,
        return_street: pick === "street" ? value : credit.return_street,
        return_route: pick === "route" ? value : credit.return_route,
        return_city: pick === "city" ? value : credit.return_city,
        return_state: pick === "state" ? value : credit.return_state,
        return_zip: pick === "zip" ? value : credit.return_zip,
      })
    );
  };

  const handleCustomerChange = (event) => {
    const value = parseInt(event.target.value.replace(/\D/g, ""));
    setCustomer({
      ...customer,
      customer_num: value ? value : "",
    });
  };

  const findCustomer = () => {
    setEditingCustomer(false);
    if (customer.customer_num > 199) {
      dispatch(getCustomerByIdCredits(customer.customer_num)).then(
        (response) => {
          if (!response?.error) {
            setlastCustomer(customer.customer_num);
            setCustomer(response.payload);
            if (!creditOrder.freight.is_drop_ship) {
              dispatch(
                updateCredit({
                  ...credit,
                  return_customer_name: customer.name,
                  return_street: customer.shipping_address.street,
                  return_route: customer.shipping_address.route,
                  return_city: customer.shipping_address.city,
                  return_state: customer.shipping_address.state,
                  return_zip: customer.shipping_address.zip_code,
                })
              );
            }
            dispatch(
              updateCredit({ ...credit, customer: { ...response.payload } })
            );
          } else {
            setCustomer({
              ...customer,
              customer_num: lastCustomer,
            });
          }
        }
      );
    } else {
      setCustomer({
        ...customer,
        customer_num: lastCustomer,
      });
    }
  };

  return (
    <>
      {/* 
            All of this information is provided initially by the order that is being credited. Most of this does not need to be changed ever with the exception of the return address
            For trouble shooting, if something looks weird check the order to make sure it is not also weird on the order
        */}
      {show && (
        <Card>
          <Card.Title className="bottom-border-seperator">
            <Row style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Col>
                <>
                  {customer.name} (
                  {editingCustomer ? (
                    <TextField
                      size="small"
                      style={{ width: "5%", marginTop: "-1%" }}
                      value={customer.customer_num}
                      onChange={handleCustomerChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          findCustomer();
                        }
                      }}
                    />
                  ) : (
                    <>{customer.customer_num}</>
                  )}
                  )
                </>{" "}
                <span
                  className="collapse-item"
                  onClick={(e) => setEditingCustomer(!editingCustomer)}
                >
                  edit
                </span>
              </Col>
            </Row>
          </Card.Title>

          <Row>
            <Col>
              <Card.Title className="mb-0">Order Information</Card.Title>
              <Card.Body className="mt-0">
                <b>Invoice Number: </b> {creditOrder.customer_invoice_num}
                <br />
                <b>Order Number: </b>
                {creditOrder.customer_order_num}
                <br />
                <b>Customer PO: </b>
                {creditOrder.customer_po}
                <br />
                <b>Customer Number:</b> {creditOrder.customer.customer_num}
              </Card.Body>
            </Col>
            {creditOrder.freight.is_drop_ship && (
              <Col>
                <Card.Title className="mb-0">
                  Order Drop Ship Address
                </Card.Title>
                <Card.Body className="mt-0">
                  {creditOrder.freight.drop_ship_address.address.street.trim()
                    .length > 1
                    ? creditOrder.freight.drop_ship_address.address.street.trim() +
                      "\n"
                    : ""}
                  {creditOrder.freight.drop_ship_address.address.route}
                  <br />
                  {creditOrder.freight.drop_ship_address.address.city +
                    " " +
                    creditOrder.freight.drop_ship_address.address.state +
                    " " +
                    creditOrder.freight.drop_ship_address.address.zip_code}
                </Card.Body>
              </Col>
            )}
            <Col></Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Card.Title className="mb-0">
                Customer Contact Information
              </Card.Title>
              <Card.Body className="mt-0">
                <b>{customer.contact.name}</b>
                <br />
                <b>Phone:</b> {customer.contact.phone}
                <br />
                <b>Email:</b> {customer.contact.emails[0]}
              </Card.Body>
            </Col>
            <Col>
              <Card.Title className="mb-0">Customer Billing Address</Card.Title>
              <Card.Body className="mt-0">
                {customer.billing_address.street}
                <br />
                {customer.billing_address.route}
                <br />
                {customer.billing_address.city +
                  " " +
                  customer.billing_address.state +
                  " " +
                  customer.billing_address.zip_code}
              </Card.Body>
            </Col>
            <Col>
              <Card.Title className="mb-0">
                Customer Shipping Address
              </Card.Title>
              <Card.Body className="mt-0">
                {customer.shipping_address.street.trim().length > 1
                  ? customer.shipping_address.street.trim() + "\n"
                  : ""}
                {customer.shipping_address.route}
                <br />
                {customer.shipping_address.city +
                  " " +
                  customer.shipping_address.state +
                  " " +
                  customer.shipping_address.zip_code}
              </Card.Body>
            </Col>
          </Row>
          {/* 
                        These return information fields populate initially as follows:

                        IF there is a drop ship address on the original order THEN populate using that
                        ELSE populate using the customer shipping address

                        When this information is changed it is always saved to the dropship regardless of drop ship being present or not

                        These fields are also populated on rebill or adjustment credits even though they will never need it. 
                        If this ever becomes a complaint we can always not display this information if it is not standard
                    */}
          <div className="cust-return">
            <Card.Title className="mt-5 bottom-border-seperator">
              Return Information{" "}
            </Card.Title>
            <Row>
              <Col className="cust-return-name">
                <div className="info-header">Customer Name</div>
                <TextField
                  className="w-25"
                  disabled={
                    credit.credit_type !== STANDARD_CREDIT.id || disabled
                  }
                  value={credit.return_customer_name}
                  onChange={(e) => returnUpdate(e.target.value, "name")}
                ></TextField>
              </Col>
            </Row>
            <Row className="mt-5 cust-return">
              <Col>
                <div className="info-header">Return Street</div>
                <TextField
                  className="w-100"
                  disabled={
                    credit.credit_type !== STANDARD_CREDIT.id || disabled
                  }
                  value={credit.return_street}
                  onChange={(e) => returnUpdate(e.target.value, "street")}
                ></TextField>
              </Col>
              <Col>
                <div className="info-header">Return Route</div>
                <TextField
                  className="w-100"
                  disabled={
                    credit.credit_type !== STANDARD_CREDIT.id || disabled
                  }
                  value={credit.return_route}
                  onChange={(e) => returnUpdate(e.target.value, "route")}
                ></TextField>
              </Col>
              <Col>
                <div className="info-header">Return City</div>
                <TextField
                  className="w-50"
                  disabled={
                    credit.credit_type !== STANDARD_CREDIT.id || disabled
                  }
                  value={credit.return_city}
                  onChange={(e) => returnUpdate(e.target.value, "city")}
                ></TextField>
              </Col>
              <Col>
                <div className="info-header">Return State</div>
                <TextField
                  className="w-50"
                  disabled={
                    credit.credit_type !== STANDARD_CREDIT.id || disabled
                  }
                  value={credit.return_state}
                  onChange={(e) =>
                    returnUpdate(e.target.value.slice(0, 2), "state")
                  }
                ></TextField>
              </Col>
              <Col>
                <div className="info-header">Return Zip</div>
                <TextField
                  className="w-50"
                  disabled={
                    credit.credit_type !== STANDARD_CREDIT.id || disabled
                  }
                  value={credit.return_zip}
                  onChange={(e) => returnUpdate(e.target.value, "zip")}
                ></TextField>
              </Col>
            </Row>
          </div>
        </Card>
      )}
    </>
  );
};

export default CreditInputCustomer;

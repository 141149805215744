import { useCallback, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
} from "@mui/material";

import { formStyle } from "../../../theming/formStyle";
import { validateNumber } from "../../../helpers/helperFunctions.js";
import Table from "../../atoms/Table/Table.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getFreightLinesByScacCode,
  setFreight,
  setFreightRater,
  setSelectedRateLTLOption,
  toggleFreightRaterPopupState,
} from "../../../store/slices/freight/freightSlice.js";
import { PREPAID_OPTIONS } from "../../../helpers/const.js";
import { setAutoSaveFocusOuttedTrue } from "../../../store/slices/eventHandlerSlice";
import InputNumber from "../../atoms/InputNumber/index.js";
import "./FreightDetails.scss";

export default function LTLResults({
  focusOnDataTable,
  applyButtonRef,
  cZarZipInputRef,
  setFocusOnDataTable,
}) {
  const dispatch = useDispatch();

  const { subOrderId } = useSelector((state) => state.subOrder);

  const { items: orderItems } = useSelector((state) => state.items);
  const [showTrackWarning, setShowTrackWarning] = useState("none");
  useEffect(() => {
    var found = false;

    if (orderItems.length > 0) {
      for (var i = 0; i < orderItems.length; i++) {
        var item = orderItems[i];
        if (
          String(item.productCode).substring(0, 2) === "17" ||
          String(item.productCode).substring(0, 2) === "16" ||
          String(item.productCode).substring(0, 2) === "15"
        ) {
          found = true;
        }
      }
    }
    if (found === true) setShowTrackWarning("");
    else setShowTrackWarning("none");
  }, [orderItems]);

  const {
    weight,
    prepaid,
    thirdPartyBilling,
    freightAmount: freightAmountStore,
    bolDetail: { appended_weight = 0 },
    prepaidWeight,
    freightRater: {
      czarZip,
      destinationZip,
      testCzarZip,
      testDestinationZip,
      rateLTLOptions,
      selectedRateLTLOption,
    },
  } = useSelector((state) => state.freight);

  const [freightAmount, setFreightAmount] = useState(freightAmountStore);
  const [prepaidOption, setPrepaidOption] = useState(
    prepaid
      ? +freightAmountStore
        ? PREPAID_OPTIONS.PREPAID_AND_ADD
        : PREPAID_OPTIONS.PREPAID
      : PREPAID_OPTIONS.COLLECT
  );
  const [collectChange, setCollectChange] = useState(false);

  const setFreightRaterInfo = useCallback((id, data) => {
    dispatch(setSelectedRateLTLOption(id ? data : null));
    setFocusOnDataTable(true);
  }, []);

  const handlePrepaidOptionChange = (option) => {
    setPrepaidOption(option);
  };

  useEffect(() => {
    if (selectedRateLTLOption) {
      setFreightAmount(selectedRateLTLOption?.net);
    }
  }, [selectedRateLTLOption]);

  const handleFreightAmountProccess = (freightAmountValue) => {
    const validaNumber = validateNumber(freightAmountValue, 2);

    if (validaNumber) {
      let _prepaidOption = PREPAID_OPTIONS.COLLECT;

      if (prepaidOption !== PREPAID_OPTIONS.COLLECT) {
        if (freightAmountValue) {
          _prepaidOption = PREPAID_OPTIONS.PREPAID_AND_ADD;
        } else {
          _prepaidOption = PREPAID_OPTIONS.PREPAID;
        }
      }

      setFreightAmount(
        _prepaidOption === PREPAID_OPTIONS.PREPAID ? 0 : freightAmountValue
      );
      setPrepaidOption(_prepaidOption);
    }
  };

  /**
   * Function to apply the freight data to the order object in Redux
   */
  const applyData = (fa) => {
    let prepaidCode = "";
    let prepaidBool = false;

    if (
      prepaidOption === PREPAID_OPTIONS.PREPAID ||
      prepaidOption === PREPAID_OPTIONS.PREPAID_AND_ADD
    ) {
      prepaidCode =
        thirdPartyBilling.name && thirdPartyBilling.name.trim().length > 0
          ? "3"
          : "Y";
      prepaidBool = prepaidCode === "3" ? false : true;
    } else {
      prepaidCode = "N";
      prepaidBool = false;
    }

    dispatch(
      getFreightLinesByScacCode({ carrier: selectedRateLTLOption?.carrier })
    ).then(() => {
      if (subOrderId) {
        dispatch(setAutoSaveFocusOuttedTrue());
      }
    });

    dispatch(
      setFreight({
        freightAmount: fa,
        prepaidCode,
        prepaid: prepaidBool,
        estimatedCost: selectedRateLTLOption?.net,
        handlingCharge: 0,
      })
    );

    dispatch(
      setFreightRater({
        carrierQuoteNumber: selectedRateLTLOption?.carrierQuoteNumber,
        TMSQuoteNumber: selectedRateLTLOption?.TMSQuoteNumber,
        rated: true,
        ratedWeight: weight,
        selectedRateLTLOption: null,
      })
    );

    dispatch(toggleFreightRaterPopupState(false));
  };

  const onCancel = () => {
    dispatch(toggleFreightRaterPopupState(false));
  };

  return (
    <>
      <Row>
        <Col md="12">
          {rateLTLOptions.length === 0 && (
            <p className="mt-5 p-3 bg-warning text-center">
              No rated results. It can take multiple tries to get options.
            </p>
          )}
          {weight + appended_weight === 0 && (
            <p className="mt-5 p-3 bg-danger text-white text-center">
              Order needs to have weight to be rated
            </p>
          )}
          {!destinationZip && (
            <p className="mt-5 p-3 bg-danger text-white text-center">
              Set a customer and/or drop ship address to be rated
            </p>
          )}
          <div className="drop-ship-popup__table mt-5">
            <Table
              selector="radio"
              headerAttributes={[
                {
                  id: "id",
                  label: "Select",
                },
                {
                  id: "name",
                  label: "Name",
                },
                {
                  id: "net",
                  label: "Net",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "days",
                  label: "Days",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "carrier",
                  label: "Carrier",
                },
                {
                  id: "carrierQuoteNumber",
                  label: "CarrierQuoteNumber",
                },
                {
                  id: "TMSQuoteNumber",
                  label: "TMSQuoteNumber",
                },
              ]}
              dataList={[...rateLTLOptions].sort(
                (a, b) => parseInt(a.net) - parseInt(b.net)
              )}
              hideId={true}
              onSelectRow={setFreightRaterInfo}
              selectedItem={selectedRateLTLOption?.id}
              getFocus={focusOnDataTable}
              nextFocusRef={applyButtonRef}
              prevFocusRef={cZarZipInputRef}
              setFocusOnDataTable={setFocusOnDataTable}
            />
          </div>
        </Col>
      </Row>

      {selectedRateLTLOption && (
        <Row className="mt-2">
          <Col md={15} className="mt-1">
            <Alert
              style={{ color: "darkred", display: showTrackWarning }}
              severity="error"
            >
              There is a track present, please check freight amount.
            </Alert>
          </Col>
          <Row>
            <Col md="10">
              <FormControl>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  className="mt-3"
                  value={prepaidOption}
                  name="prepaidOption"
                  onChange={(e) => handlePrepaidOptionChange(e.target.value)}
                >
                  <Row>
                    <Col md="4">
                      <FormControlLabel
                        value={PREPAID_OPTIONS.PREPAID}
                        control={<Radio />}
                        label="Prepaid"
                      />
                    </Col>
                    <Col>Prepaid Weight: {prepaidWeight}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        value={PREPAID_OPTIONS.PREPAID_AND_ADD}
                        control={<Radio />}
                        label="Prepaid and Add"
                      />
                    </Col>
                    <Col>
                      <InputNumber
                        className="mt-3"
                        label="Freight Amount"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        value={freightAmount}
                        onChange={(e) => setFreightAmount(+e.target.value)}
                        onBlur={(e) =>
                          handleFreightAmountProccess(+e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  <FormControlLabel
                    value={PREPAID_OPTIONS.COLLECT}
                    control={<Radio />}
                    label="Collect"
                  />
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>
          <Row className="mt5">
            <Col md="12" className="d-flex justify-content-end">
              <Button
                variant="primary"
                className="mt-3 mx-3"
                style={{ maxHeight: 48 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                className="mt-3"
                style={{ maxHeight: 48 }}
                ref={applyButtonRef}
                onClick={(e) => {
                  if (!e.shiftKey) {
                    var fa = freightAmount;

                    if (
                      prepaidOption === PREPAID_OPTIONS.COLLECT ||
                      prepaidOption === PREPAID_OPTIONS.PREPAID
                    ) {
                      fa = 0;
                    }
                    applyData(fa);
                  }
                }}
                disabled={
                  czarZip !== testCzarZip ||
                  destinationZip !== testDestinationZip
                }
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
}

import { Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import SubOrder from "../views/SubOrder/SubOrder";
import Orders from "../views/Orders/Orders";
import Customers from "../views/Customers/Customers";
import Customer from "../views/Customer/Customer";
import Bol from "../views/Bol/Bol";
import Quotes from "../views/Quotes/Quotes";
import AutoSaveWrapper from "../wrapper/AutoSaveWrapper";
import { Alert } from "@mui/material";
import UpdateLog from "../components/atoms/UpdateLog";
import { useState } from "react";

//This is temporary, I will likely create a db for this
const update_arr = [
  {
    date: "3/31/2025",
    entries: [
      "Stock Transfer Added",
      "Added ask to send confirmation",
      "Bug Fixes",
    ],
  },
  {
    date: "3/18/2025",
    entries: [
      "Added Protire QTY",
      "Added invoice# to customer tab",
      "default sort freight rater",
      "Bug Fixes",
    ],
  },
  // {
  //   date: "2/26/2025",
  //   entries: [
  //     "Added last price per product code",
  //     "Freight Rater Options Tracking",
  //     "Added credits to customer tab",
  //     "added customer po to customer tab",
  //     "Bug Fixes",
  //   ],
  // },
  // {
  //   date: "2/25/2025",
  //   entries: [
  //     "Updated BOL consistency",
  //     "Updated Margin Report",
  //     "Improved Initial Loading Speed",
  //     "MAX banner time of 2.5s",
  //     "Collapsable cards",
  //   ],
  // },
  // {
  //   date: "2/20/2025",
  //   entries: [
  //     "Condensed Buttons into 'Tool' button",
  //     "Changed Freight Rater to have checkboxes",
  //     "Added printable confirmation that includes pricing",
  //     "Added business card of information incl: status, order total",
  //   ],
  // },
];

function CustomerOrders(props) {
  const groups = props.groups;
  const tag = "v1.6.7";
  const [showUpdate, setShowUpdate] = useState(false);

  return (
    <>
      {groups.includes(props.orderGroup) ? (
        <Container className="">
          <Routes>
            <Route path={""} element={<SubOrder />} />

            <Route
              path={"/customer/:customerId/new-order/"}
              element={<SubOrder />}
            />
            <Route path={"/quote/:id"} element={<SubOrder savedAsQuote />} />
            <Route path={"/order/new"} element={<SubOrder />} />
            <Route
              path={"/order/:id"}
              element={
                <AutoSaveWrapper>
                  <SubOrder savedAsOrder />
                </AutoSaveWrapper>
              }
            />
            <Route path={"/order-search/"} element={<Orders />} />
            <Route path={"/customers"} element={<Customers />} />
            <Route path={"/customers/:id"} element={<Customer />} />
            <Route path={"/bol"} element={<Bol />} />

            <Route path={"/quotes-search/"} element={<Quotes />} />
          </Routes>
        </Container>
      ) : (
        <Container>
          {props.groups.length > 0 && (
            <span>
              <Alert severity="error" variant="outlined" className="mt-5">
                You are not allowed to view this page. If you believe you are
                seeing this in error, please contact a system administrator
              </Alert>
              <a href="/">Back to home</a>
            </span>
          )}
        </Container>
      )}
      <div className="update-log-link" onClick={() => setShowUpdate(true)}>
        {tag}
      </div>
      <UpdateLog
        show={showUpdate}
        tag={tag}
        body={update_arr}
        setShowUpdate={setShowUpdate}
      />
    </>
  );
}

export default CustomerOrders;

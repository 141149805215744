import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Checkbox,
  debounce,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Col, Row, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Table from "../../atoms/Table/Table";
import Paginator from "../../atoms/Paginator/Paginator";
import { endpoints } from "../../../api/endpoints";
import {
  appendOrdersToBol,
  getBols,
  setBol,
} from "../../../store/slices/bol/bolSlice";
import { formStyle } from "../../../theming/formStyle";
import {
  APPROVED_ORDER_STATUS,
  IN_PROGRESS_ORDER_STATUS,
  OPEN_ORDER_STATUS,
  PENDING_ORDER_STATUS,
} from "../../../helpers/const";
import { getSubOrder } from "../../../store/slices/subOrderSlice";
import { Link } from "react-router-dom";

const headerAttributes = [
  {
    id: "select",
    label: "Select",
  },
  {
    id: "id",
    label: "BoL",
  },
  {
    id: "total",
    label: "Total",
  },
  {
    id: "weight",
    label: "Weight",
  },
  {
    id: "cost",
    label: "Cost",
  },
  {
    id: "customerId",
    label: "Customer ID",
  },
  {
    id: "customer",
    label: "Customer",
  },
];

export const BolAppendDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [datalist, setDatalist] = useState([]);
  const [selectedBol, setSelectedBol] = useState(0);
  const [page, setPage] = useState(0);
  const { control, getValues, reset } = useForm();

  const { bol, bols, total, loading } = useSelector((state) => state.bol);
  const {
    subOrderId,
    subOrder: { warehouse_id, warehouse_name },
  } = useSelector((state) => state.subOrder);
  const { customerId, customerName } = useSelector((state) => state.customer);

  useEffect(() => {
    if (open) {
      if (customerId > 0 && warehouse_id >= 0) {
        dispatch(
          getBols({
            bol: subOrderId,
            warehouse_id: warehouse_id,
            customer_num: customerId,
            status: [
              OPEN_ORDER_STATUS.id,
              PENDING_ORDER_STATUS.id,
              APPROVED_ORDER_STATUS.id,
              IN_PROGRESS_ORDER_STATUS.id,
            ],
          })
        );
      }
    } else {
      reset();
      setSelectedBol(0);
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, warehouse_id, open]);

  useEffect(() => {
    if (bols) {
      const filteredBols = bols.filter(
        (bol) => Number(bol.bill_of_lading) !== subOrderId
      );

      setDatalist(
        filteredBols.map((bol) => ({
          id: bol.bill_of_lading,
          view_suborder: (
            <Link to={endpoints.ORDER_ENTRY + `/order/${bol.bill_of_lading}`}>
              {bol.bill_of_lading}
            </Link>
          ),
          total: bol.total_sum,
          weight: bol.weight_sum,
          cost: bol.cost_sum,
          customerId: bol.customer_id,
          customer: bol.customer_name,
        }))
      );
    }
  }, [bols, subOrderId]);

  const handleFilterInput = debounce(() => {
    const { bol_id, bol_appended, bol_status } = getValues();

    let allStatus = [
      OPEN_ORDER_STATUS.id,
      PENDING_ORDER_STATUS.id,
      APPROVED_ORDER_STATUS.id,
      IN_PROGRESS_ORDER_STATUS.id,
    ];

    dispatch(
      getBols({
        bill_of_lading: bol_id,
        warehouse_id: warehouse_id,
        customer_num: customerId,
        status:
          bol_status?.id >= OPEN_ORDER_STATUS.id ? [bol_status?.id] : allStatus,
        appended: bol_appended,
      })
    );

    setPage(0);
  }, 500);

  const handlePageChange = (selectedPage) => {
    const { bol_id } = getValues();

    dispatch(
      getBols({
        bill_of_lading: bol_id,
        warehouse_id: warehouse_id,
        customer_num: customerId,
        status: [
          OPEN_ORDER_STATUS.id,
          PENDING_ORDER_STATUS.id,
          APPROVED_ORDER_STATUS.id,
          IN_PROGRESS_ORDER_STATUS.id,
        ],
        page: selectedPage + 1,
      })
    );

    setPage(selectedPage);
  };

  const handleSelectBol = useCallback(
    (id) => {
      setSelectedBol(id);

      const bol = bols.filter((bol) => bol.bill_of_lading === id)[0];
      dispatch(setBol(bol));
    },
    [bols, dispatch]
  );

  const handleApply = () => {
    const id = Number(bol.bill_of_lading);
    dispatch(
      appendOrdersToBol({ customerOrderNum: id, subOrderIds: [subOrderId] })
    ).then(() => {
      dispatch(getSubOrder(subOrderId));
    });
    onClose();
  };

  return (
    <Modal size="xl" show={open} onHide={onClose}>
      <Modal.Header closeButton className="p-5">
        <Modal.Title>
          <h3 className="h5">Append Bill of Lading</h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="m-4">
        <Row>
          <Col md={4}>
            <Controller
              name="bol_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoFocus
                  name="bol_id"
                  label="Bill of Lading"
                  type="search"
                  variant="outlined"
                  value={value}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleFilterInput();
                  }}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              name="bol_status"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  label="Status"
                  name="bol_status"
                  options={[
                    OPEN_ORDER_STATUS,
                    PENDING_ORDER_STATUS,
                    APPROVED_ORDER_STATUS,
                    IN_PROGRESS_ORDER_STATUS,
                  ]}
                  value={value}
                  onChange={(e, value) => {
                    onChange(value);
                    handleFilterInput();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      style={formStyle.inputStyle}
                      InputLabelProps={formStyle.inputLabelProps}
                    />
                  )}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              name="bol_appended"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bol_appended"
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        handleFilterInput();
                      }}
                      sx={formStyle.checkboxStyle}
                    />
                  }
                  label="Only show BOLs with multiple orders"
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <div className="bol-append-popup__table">
            <Typography variant="body1" className="mt-4">
              BOLs for "{customerName}({customerId})" in warehouse "
              {warehouse_name}"
            </Typography>
            <Table
              selector="radio"
              hideId
              headerAttributes={headerAttributes}
              dataList={datalist}
              onSelectRow={handleSelectBol}
              selectedItem={selectedBol}
              isLoading={loading}
            />
          </div>
          <Paginator
            rowsPerPage={50}
            totalOrders={total}
            page={page}
            setPage={(p) => handlePageChange(p)}
          />
        </Row>
      </Modal.Body>

      <div className="d-flex justify-content-end align-items-center mx-4 mb-4 gap-4">
        <Button disabled={selectedBol === 0} onClick={handleApply}>
          Apply
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </div>
    </Modal>
  );
};
